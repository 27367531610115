export const getTotalHTFromTTCPrice = (ttcPrice: number) => {
    return (ttcPrice / 1.1).toFixed(2)
}

export const getTvaFromTTCPrice = (ttcPrice: number) => {
    return ((ttcPrice / 1.1) * 0.1).toFixed(2)
}

export const addOpacity = (color: any, opacity: number) => {
    'worklet' // so it can be called in the UI thread
    return color.replace(/(rgb)([^)]*)([)])/g, (_, p1, p2, p3) => {
        return `${p1}a${p2},${opacity}${p3}` //returns rgba format
    })
}

export function debounce<T extends Function>(cb: T, wait = 20) {
    let h: NodeJS.Timeout
    const callable = (...args: any) => {
        clearTimeout(h)
        h = setTimeout(() => cb(...args), wait)
    }
    return <T>(<any>callable)
}
const f = debounce((e: number) => 1)
