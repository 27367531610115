import { createSlice } from '@reduxjs/toolkit'
import { Item, Option, CartState, CartItem, CartStatusEnum, PaymentMethod } from '../../types'
import { Float } from 'react-native/Libraries/Types/CodegenTypes'

const initialState: CartState = {
    invoiceNumber: '',
    items: [],
    cartTotalQuantity: 0,
    cartTotalPrice: 0.0,
    tipTotalPrice: 0.0,
    cartStatus: CartStatusEnum.PENDING,
    cartComment: '',
    orderDate: '',
    selectedMethod: PaymentMethod.Cache,
    tableId: '',
    restoId: '',
    takeAway: false
}

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        // On screen reload
        resetCart: (state, action) => {
            return action.payload
        },
        // On clicking an item in the homeScreen
        addNewItemToCart: (state, action) => {
            const newItem: Item = action.payload

            // setting cart total price and quantity
            state.cartTotalPrice += +parseFloat(newItem.price).toFixed(2)
            state.cartTotalQuantity++

            // adding a new item to cart
            state.items.push({
                id: newItem.id,
                cartId: Math.random().toString(36).substring(2, 9),
                price: parseFloat(newItem.price),
                quantity: 1,
                editEnabled: true,
                itemTotalPrice: parseFloat(newItem.price),
                name: newItem.name,
                modifiers: []
            })
        },
        // add comment to redux state
        addCommentToCart: (state, action) => {
            const { newComment }: { newComment: string } = action.payload
            state.cartComment = newComment
        },

        //clicking on the plus button on the ItemScreen we call the addItemToCart action
        incrementLastItemInCart: (state, action) => {
            const existingItem: Item = action.payload
            // we need the fetch the last one (added via the itemScreen's item click to the list)
            // const itemExistInStore: CartItem | undefined = state.items.find((item) => item.id === newItem.id + (state.items.length - 1))

            const filteredItemsWSameId = state.items.filter((item) => item.id === existingItem.id)

            if (filteredItemsWSameId.length > 0) {
                const itemExistInStore: CartItem = filteredItemsWSameId[filteredItemsWSameId.length - 1] // get tbe last one with the sameid

                if (itemExistInStore) {
                    // incrementing existing item and its price
                    itemExistInStore.quantity++

                    // setting cart total price and quantity
                    state.cartTotalPrice += +parseFloat(existingItem.price).toFixed(2)
                    state.cartTotalPrice.toFixed(2)
                    state.cartTotalQuantity++
                }
            }
        },

        //clicking on the picker at CartScreen to update item quantity
        incrementCartItem: (state, action) => {
            const { itemCartId, newQuantity }: { itemCartId: string; newQuantity: number } = action.payload

            const filteredItemToUpdate = state.items.filter((item) => item.cartId === itemCartId)[0]

            if (filteredItemToUpdate) {
                // incrementing existing item and its price
                const quantityDifference = newQuantity - filteredItemToUpdate.quantity
                filteredItemToUpdate.quantity = newQuantity

                // setting cart total price and quantity
                state.cartTotalPrice += +(filteredItemToUpdate.itemTotalPrice * quantityDifference).toFixed(2)
                state.cartTotalQuantity += quantityDifference
            }
        },

        removeItemFromCart: (state, action) => {
            const { itemCartId }: { itemCartId: string } = action.payload

            const filteredItemToUpdate = state.items.filter((item) => item.cartId === itemCartId)[0]

            if (filteredItemToUpdate) {
                // removing existing item
                const removedItem = filteredItemToUpdate
                state.items = state.items.filter((item) => item !== filteredItemToUpdate)

                // setting cart total price and quantity
                state.cartTotalPrice -= +(removedItem.itemTotalPrice * removedItem.quantity).toFixed(2)
                state.cartTotalQuantity -= removedItem.quantity
            }
        },

        //clicking on the minus button or the close on the ItemScreen
        removeLastItemFromCart: (state, action) => {
            const existingItem: Item = action.payload
            // we need the fetch the last one (added via the itemScreen's item click to the list)
            // const itemExistInStore: CartItem | undefined = state.items.find((item) => item.id === newItem.id + (state.items.length - 1))

            const filteredItemsWSameId = state.items.filter((item) => item.id === existingItem.id)
            const itemExistInStore: CartItem = filteredItemsWSameId[filteredItemsWSameId.length - 1] // get tbe last one with the sameid

            if (itemExistInStore.quantity === 1) {
                state.items.pop()
            } else {
                // incrementing last existing item and its price
                itemExistInStore.quantity--
            }
            // setting cart total price and quantity
            state.cartTotalPrice -= +parseFloat(existingItem.price).toFixed(2)
            state.cartTotalQuantity--
        },

        //clicking on close on the ItemScreen
        removeLastItemAllOccurrences: (state, action) => {
            const existingItem: Item = action.payload
            // we need the fetch the last one (added via the itemScreen's item click to the list)
            // const itemExistInStore: CartItem | undefined = state.items.find((item) => item.id === newItem.id + (state.items.length - 1))

            const filteredItemsWSameId = state.items.filter((item) => item.id === existingItem.id)
            const itemExistInStore: CartItem = filteredItemsWSameId[filteredItemsWSameId.length - 1] // get tbe last one with the sameid

            // setting cart total price and quantity
            state.cartTotalPrice -= +(parseFloat(existingItem.price) * itemExistInStore.quantity).toFixed(2)
            state.cartTotalQuantity -= itemExistInStore.quantity

            // removing the last item and all its occurrences
            state.items.pop()
        },

        addOptionToItemAtLastIndex: (state, action) => {
            const { itemId, modifierId, option }: { itemId: string; modifierId: string; option: Option } = action.payload
            const filteredItemsWSameId = state.items.filter((item) => item.id === itemId)

            if (filteredItemsWSameId) {
                const itemExistInStore: CartItem | undefined = filteredItemsWSameId[filteredItemsWSameId.length - 1] // getting item at index

                if (itemExistInStore) {
                    // itemExistInStore.price += option.numericPrice // TODO check if this sufficient instead of the 3 bellow lines
                    // const filteredOption = state.items.filter((item) => item.id === itemId)

                    const optionExistInItemStore = itemExistInStore.modifiers
                        ?.filter((modifier) => modifierId === modifier.id)
                        .flatMap((cartModifier) => cartModifier.options)
                        .find((cartOption) => option.id === cartOption.id)

                    if (optionExistInItemStore) {
                        // option is already added to list of option of our item in the store
                        optionExistInItemStore.quantity++
                    } else {
                        // option is not selected yet
                        const modifierExistInItemStore = itemExistInStore.modifiers?.find((modifier) => modifierId === modifier.id)
                        if (modifierExistInItemStore) {
                            modifierExistInItemStore.options.push({ id: option.id, name: option.name, price: option.numericPrice, quantity: 1 } as CartOption)
                        } else {
                            itemExistInStore.modifiers?.push({ id: modifierId, options: [{ id: option.id, name: option.name, price: option.numericPrice, quantity: 1 } as CartOption] } as CartModifier)
                        }
                    }
                    itemExistInStore.itemTotalPrice = +(itemExistInStore.itemTotalPrice + option.numericPrice).toFixed(2)
                    state.cartTotalPrice = +(state.cartTotalPrice + option.numericPrice).toFixed(2)
                }
            }
        },

        removeOptionFromItemAtLastIndex: (state, action) => {
            const { itemId, modifierId, option }: { itemId: string; modifierId: string; option: Option } = action.payload
            const filteredItemsWSameId = state.items.filter((item) => item.id === itemId)

            if (filteredItemsWSameId) {
                const itemExistInStore: CartItem | undefined = filteredItemsWSameId[filteredItemsWSameId.length - 1] // getting item at last index

                if (itemExistInStore) {
                    const optionExistInItemStore = itemExistInStore.modifiers
                        ?.filter((modifier) => modifierId === modifier.id)
                        .flatMap((cartModifier) => cartModifier.options)
                        .find((cartOption) => option.id === cartOption.id)

                    const modifierToEdit = itemExistInStore.modifiers?.find((modifier) => modifierId === modifier.id)

                    if (optionExistInItemStore) {
                        if (optionExistInItemStore?.quantity === 1) {
                            const optionExistInItemStoreIndex = modifierToEdit?.options.findIndex((cartOption) => optionExistInItemStore.id === cartOption.id)
                            optionExistInItemStoreIndex === 0 ? modifierToEdit?.options.shift() : optionExistInItemStoreIndex && modifierToEdit?.options.splice(optionExistInItemStoreIndex, 1)
                        } else {
                            optionExistInItemStore.quantity--
                        }
                        itemExistInStore.itemTotalPrice = +(itemExistInStore.itemTotalPrice - option.numericPrice).toFixed(2)
                        state.cartTotalPrice = +(state.cartTotalPrice - option.numericPrice).toFixed(2)
                    }
                }
            }
        },

        addOptionToItemAtIndex: (state, action) => {
            const { itemId, option, index }: { itemId: string; option: Option; index: number } = action.payload
            const filteredItemsWSameId = state.items.filter((item) => item.id === itemId)

            if (filteredItemsWSameId) {
                const itemExistInStore: CartItem | undefined = filteredItemsWSameId[index] // getting item at index

                if (itemExistInStore) {
                    itemExistInStore.price += option.numericPrice // TODO check if this sufficient instead of the 3 bellow lines

                    const optionExistInItemStore = itemExistInStore.modifiers?.find((optionInStore) => optionInStore.id === option.id)

                    if (optionExistInItemStore) {
                        // option is already added to list of option of our item in the store
                        optionExistInItemStore.quantity++
                        optionExistInItemStore.price += option.numericPrice // todo: no need for this
                        console.log('📭 ADD OPTION TO ITEM >>>>>>>>> option already selected, adding price to option', option)
                    } else {
                        // option is not selected yet
                        itemExistInStore.modifiers?.push({ id: option.id, name: option.name, price: option.numericPrice, quantity: 1 })
                        console.log('📭 ADD OPTION TO ITEM >>>>>>>>> adding option as new  one to item in cart', option)
                    }
                }
            }
        },

        removeOptionFromItemAtIndex: (state, action) => {
            const { itemId, option, index }: { itemId: string; option: Option; index: number } = action.payload
            const filteredItemsWSameId = state.items.filter((item) => item.id === itemId)

            if (filteredItemsWSameId) {
                const itemExistInStore: CartItem | undefined = filteredItemsWSameId[index] // getting item at index

                if (itemExistInStore) {
                    itemExistInStore.price -= option.numericPrice // TODO check if this sufficient instead of the 3 bellow lines
                    const optionExistInItemStore = itemExistInStore.modifiers?.find((optionInStore) => optionInStore.id === option.id)

                    if (optionExistInItemStore) {
                        if (optionExistInItemStore?.quantity == 1) {
                            itemExistInStore.modifiers = [] // TODO check this
                            console.log('📭 ADD OPTION TO ITEM >>>>>>>>> adding option as new  one to item in cart', option)
                        } else {
                            // option is already added to list of option of our item in the store
                            optionExistInItemStore.quantity--
                            optionExistInItemStore.price -= option.numericPrice // todo: no need for this
                            console.log('📭 ADD OPTION TO ITEM >>>>>>>>> option already selected, adding price to option', option)
                        }
                    }
                }
            }
        },

        incerementCartTip: (state) => {
            state.cartTotalPrice += 1
            state.tipTotalPrice += 1
        },

        decerementCartTip: (state) => {
            state.cartTotalPrice -= 1
            state.tipTotalPrice -= 1
        },
        updateCartIsToTakeAway: (state, action) => {
            const { isTakeAway }: { isTakeAway: boolean } = action.payload
            state.takeAway = isTakeAway
        },
        updateCartSelectedMethode: (state, action) => {
            const { selectedMethod }: { selectedMethod: PaymentMethod } = action.payload
            state.selectedMethod = selectedMethod
        },
        updateCartOrderInfo: (state, action) => {
            const { tableId, restoId, orderDate, invoiceNumber }: { tableId: string; restoId: string; orderDate: string; invoiceNumber: string } = action.payload
            state.invoiceNumber = invoiceNumber
            state.tableId = tableId
            state.restoId = restoId
            state.orderDate = orderDate
        }
    }
})

export const cartActions = cartSlice.actions

export default cartSlice.reducer
