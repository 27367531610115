import { View, Text, TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

type CustomItemHeaderType = {
    itemTitle: string
    closeItemScreen: any
}
const CustomItemHeader = ({ itemTitle, closeItemScreen }: CustomItemHeaderType) => {
    return (
        <View
            style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 20,
                paddingHorizontal: 15,
                borderBottomWidth: 1,
                borderBottomColor: '#ccc'
            }}>
            <TouchableOpacity onPress={closeItemScreen}>
                <Ionicons name="arrow-back" size={22} color="#333" />
            </TouchableOpacity>

            <Text
                style={{
                    textAlign: 'left',
                    marginLeft: 20,
                    fontSize: 18,
                    fontWeight: '600'
                }}>
                {itemTitle}
            </Text>
        </View>
    )
}

export default CustomItemHeader
