import Svg, { Circle, Path } from 'react-native-svg'

export const SoyabeanSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="39.708" cy="61.672" r="12.664" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M93.818,10.246c-0.593-2.432-2.656-2.942-3.623-2.884c-0.726,0.026-7.157,0.286-9.412,1.179
			c-4.673,1.842-10.185,8.88-11.241,10.271c-1.229,1.618-2.192,3.359-3.213,5.202c-1.135,2.051-2.309,4.163-3.98,6.393
			c-0.423,0.08-0.848,0.179-1.271,0.313c-2.771,0.876-5.236,2.81-6.941,5.445c-2.477,3.827-2.805,8.341-1.22,11.764
			c-3.751-0.817-8.159,0.563-11.343,3.946c-2.15,2.284-3.438,5.14-3.627,8.041c-0.08,1.227,0.049,2.396,0.354,3.485
			c-3.393-1.274-7.666-0.778-11.269,1.678c-2.592,1.768-4.466,4.277-5.276,7.067c-0.124,0.427-0.213,0.852-0.283,1.274
			C12.513,81.499,7.048,83.627,6.997,83.646c-0.666,0.247-1.066,0.927-0.961,1.629s0.688,1.234,1.397,1.274
			c6.589,0.377,10.043,2.021,10.126,2.061c5.886,2.628,11.019,4.031,15.931,4.031c10.416-0.001,19.834-6.314,33.304-20.646
			c20.082-21.32,24.079-34.35,15.545-51.219c0.932-1.98,2.15-4.124,2.708-4.537c0.818-0.605,2.343-0.806,3.816-0.998
			c1.007-0.132,2.047-0.268,2.958-0.548C93.563,14.158,94.347,12.412,93.818,10.246z M68.476,26.332
			c-0.32,1.349-0.706,2.826-1.172,4.407c-0.414-0.148-0.842-0.254-1.276-0.339C66.974,28.979,67.759,27.615,68.476,26.332z
			 M56.654,37.795c1.327-2.051,3.219-3.548,5.326-4.214c0.476-0.15,0.948-0.25,1.413-0.307l0.316,0.261
			c0.084-0.101,0.158-0.2,0.238-0.301c0.861-0.035,1.689,0.091,2.453,0.379c-1.642,4.935-4.017,10.565-7.441,16.129
			c-0.416-0.148-0.818-0.327-1.186-0.564C54.478,47.043,53.975,41.937,56.654,37.795z M40.939,60.111
			c0.144-2.207,1.145-4.401,2.818-6.18c3.379-3.591,8.457-4.324,11.319-1.632c0.463,0.434,0.844,0.952,1.147,1.531
			c-1.507,2.086-3.168,4.136-5.018,6.102c-2.21,2.348-4.554,4.461-6.954,6.373c-0.525-0.258-1.014-0.579-1.438-0.979
			C41.47,64.063,40.804,62.21,40.939,60.111z M24.636,72.984c0.617-2.123,2.067-4.05,4.086-5.426
			c4.076-2.775,9.192-2.396,11.403,0.849c0.148,0.218,0.276,0.447,0.393,0.683c-5.39,3.76-10.869,6.554-15.632,8.571
			C24.247,76.283,24.149,74.658,24.636,72.984z M22.104,78.787c-1.494,0.577-2.885,1.071-4.149,1.492
			c1.128-0.871,2.339-1.854,3.635-2.966C21.714,77.821,21.893,78.311,22.104,78.787z M90.938,11.827
			c-0.668,0.205-1.541,0.319-2.465,0.44c-1.766,0.231-3.768,0.493-5.212,1.562c-1.552,1.15-3.423,5.171-3.96,6.376
			c-0.186,0.418-0.171,0.898,0.041,1.305c8.336,15.972,4.756,27.74-14.732,48.431C45.694,90.063,35.844,93.487,18.835,85.896
			c-0.096-0.049-1.586-0.759-4.379-1.398c7.149-1.916,25.219-7.934,38.936-22.509c15.484-16.462,18.843-37.774,19.411-42.474
			c2.825-3.512,6.503-7.166,9.082-8.184c1.342-0.53,5.877-0.877,8.417-0.971c0.457,0.004,0.563,0.435,0.602,0.597
			C91.04,11.514,90.963,11.801,90.938,11.827z"></Path>
        <Path
            fill="#052049"
            d="M41.945 80.157c-.806-.193-1.616.302-1.81 1.107-.1.417-.85 1.306-2.2 1.977-1.349.67-2.511.73-2.903.558-.76-.33-1.645.016-1.975.775-.332.76.016 1.644.775 1.975.502.219 1.1.326 1.752.326 1.132 0 2.43-.323 3.685-.947 1.981-.983 3.432-2.501 3.783-3.962C43.247 81.161 42.751 80.351 41.945 80.157zM62.142 61.776c-.826.057-1.449.773-1.393 1.6.063.904-.742 2.654-2.405 4.251-1.659 1.598-3.44 2.33-4.347 2.235-.823-.087-1.564.506-1.654 1.328-.09.824.506 1.564 1.329 1.654.156.017.316.025.48.025 1.907 0 4.262-1.146 6.271-3.081 2.186-2.098 3.457-4.635 3.32-6.62C63.685 62.343 62.972 61.706 62.142 61.776zM76.099 41.855c-.768.309-1.141 1.182-.832 1.95.164.41.121 1.626-.693 3.144-.814 1.52-1.803 2.228-2.234 2.317-.811.168-1.332.962-1.164 1.773.146.708.771 1.195 1.467 1.195.102 0 .204-.01.307-.031 1.535-.318 3.171-1.789 4.269-3.836 1.099-2.047 1.419-4.224.833-5.68C77.741 41.919 76.868 41.544 76.099 41.855z"></Path>
    </Svg>
)
