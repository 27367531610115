import { View } from 'react-native'
import { Skeleton } from 'moti/skeleton'
import tw from 'twrnc'
import { baseColors } from 'moti/build/skeleton/shared'

export default function CardSkeletonItem({ colorMode }: { colorMode: keyof typeof baseColors }) {
    return (
        <View style={tw`min-h-22 flex-row justify-between items-center pb-3`}>
            <View style={tw` flex-1 pr-3 flex-row items-center`}>
                <View style={tw`flex-1 pl-4 pr-5`}>
                    <Skeleton colorMode={colorMode} height={25} width={'50%'} />
                    <Spacer />
                    <Skeleton colorMode={colorMode} height={25} width={'100%'} />
                    <Spacer />
                    <Skeleton colorMode={colorMode} height={25} width={'100%'} />
                </View>
            </View>
            <View style={tw`pr-4`}>
                <Skeleton colorMode={colorMode} radius={8} height={80} width={80} />
            </View>
        </View>
    )
}
const Spacer = ({ height = 16 }) => <View style={{ height }} />
