import { DefaultTheme } from '@react-navigation/native'

export type CustomColors = {
    dark: boolean
    colors: {
        primary: string
        secondary: string
        background: string
        text: string
        secondaryText: string
        inputBg: string
        unavailableColor: string
    }
}

const LightTheme: CustomColors = {
    ...DefaultTheme,
    dark: false,
    colors: {
        ...DefaultTheme.colors,
        primary: '#8F5FF6',
        secondary: '#acbbe2',
        background: '#FFFFFF',
        text: 'rgb(17, 24, 39)',
        secondaryText: '#868686',
        inputBg: '#EEEEEE',
        unavailableColor: '#D5D6D6'
    }
}

const DarkTheme: CustomColors = {
    ...DefaultTheme,
    dark: true,
    colors: {
        ...DefaultTheme.colors,
        primary: '#8F5FF6',
        secondary: '#acbbe2',
        background: '#212134',
        text: '#FFFFFF',
        secondaryText: '#868686',
        inputBg: '#3a3a4b',
        unavailableColor: '#D5D6D6'
    }
}

export { DarkTheme, LightTheme }
