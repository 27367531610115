import { NavigationContainer, useTheme } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { DarkTheme, LightTheme } from '../core/theme'

import ItemScreen from '../screens/ItemScreen'
import ClosedRestoScreen from '../screens/ClosedRestoScreen'
import HomeScreen from '../screens/HomeScreen'
import CartScreen from '../screens/CartScreen'
import CheckoutScreen from '../screens/CheckoutScreen'
import BillsScreen from '../components/stripe/BillsScreen'
import OrderScreen from '../screens/OrderScreen'
import { UrlParams } from 'types'
import { useEffect, useState } from 'react'
import PassedOrderScreen from '../screens/PassedOrderScreen'
import { TouchableOpacity } from 'react-native'
import { Ionicons } from '@expo/vector-icons'

const { Navigator, Screen } = createNativeStackNavigator<any>()

const Stack = createNativeStackNavigator()
type NavigationProps = {
    colorScheme: string
    urlParams: UrlParams | null | string
}

function RootNavigator({ urlParams: urlParams }: { urlParams: UrlParams }) {
    const restoOpened = true
    const { colors } = useTheme()
    const [localStorageOrderId, setLocalStorageOrderId] = useState<string | null>(null)

    useEffect(() => {
        const localStorageOrderId = localStorage.getItem('createdOrderId')
        setLocalStorageOrderId(localStorageOrderId)
    }, [])

    const renderScreen = () => {
        if (localStorageOrderId) {
            return <Stack.Screen initialParams={urlParams} name="OrderScreen" component={OrderScreen} />
        } else if (restoOpened) {
            return (
                <Stack.Group>
                    {/* <Screen name="Completion" component={Completion} /> */}

                    <Screen name="HomeScreen" initialParams={urlParams} component={HomeScreen} />
                    <Screen name="OrderScreen" component={OrderScreen} />
                    <Screen name="PassedOrder" component={PassedOrderScreen} />
                    <Screen name="ItemScreen" component={ItemScreen} options={{ cardStyle: { backgroundColor: 'white' } }} />
                    <Screen
                        name="CartScreen"
                        component={CartScreen}
                        options={({ navigation }) => ({
                            headerShown: true,
                            headerTitleStyle: { color: 'black' },
                            headerTintColor: 'black',
                            title: 'Votre commande',
                            headerRight: () => (
                                <TouchableOpacity style={{ marginRight: 20 }} onPress={() => navigation.goBack()}>
                                    <Ionicons name="close" size={24} color="black" />
                                </TouchableOpacity>
                            )
                        })}
                    />
                    <Screen name="CheckoutScreen" initialParams={urlParams} component={CheckoutScreen} options={{ headerShown: true, headerTitleStyle: { color: 'black' }, headerTintColor: 'black', title: 'Confirmation de commande' }} />
                    <Screen name="BillsScreen" component={BillsScreen} options={{ headerShown: false }} />
                </Stack.Group>
            )
        } else {
            return <Stack.Screen name="ClosedRestoScreen" component={ClosedRestoScreen} />
        }
    }

    return (
        <Navigator screenOptions={{ headerShown: false, animation: 'slide_from_bottom' }} initialRouteName="MenuHomeScreen">
            {renderScreen()}
        </Navigator>
    )
}

export default function AppNavigator(navigationProps: NavigationProps) {
    return (
        <NavigationContainer theme={navigationProps.colorScheme === 'dark' ? DarkTheme : LightTheme}>
            <RootNavigator urlParams={navigationProps.urlParams} />
        </NavigationContainer>
    )
}
