import Svg, { Circle, Path } from 'react-native-svg'

export const SoyaSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="49.144" cy="48.301" r="18.033" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M28.938,47.62c0.898,0,1.812-0.035,2.715-0.104c6.087-0.465,11.722-2.339,15.867-5.276
			c4.374-3.1,6.613-7.031,6.305-11.069c-0.57-7.477-9.61-12.902-21.495-12.902c-0.958,0-1.934,0.037-2.9,0.11
			c-5.953,0.455-11.588,2.44-15.866,5.593c-4.288,3.159-6.586,7.079-6.306,10.754C7.808,41.956,17.33,47.62,28.938,47.62z
			 M15.342,26.387c3.775-2.781,8.993-4.61,14.315-5.016c0.891-0.068,1.79-0.103,2.672-0.103c9.942,0,18.07,4.449,18.504,10.131
			c0.226,2.946-1.567,5.927-5.048,8.394c-3.708,2.628-8.809,4.309-14.361,4.731c-0.827,0.063-1.663,0.096-2.486,0.096
			c-9.713,0-18.271-4.636-18.689-10.123C10.051,31.914,11.955,28.882,15.342,26.387z"></Path>
        <Path
            fill="#052049"
            d="M30.362 29.619c7.216-.184 11.685.899 12.987 1.678-.889.585-3.464 1.539-8.306 2.058-.823.088-1.42.827-1.332 1.65.083.77.733 1.341 1.49 1.341.053 0 .107-.003.161-.009 5.175-.554 11.327-1.891 11.246-5.12-.125-4.777-13.621-4.669-16.325-4.596-.828.021-1.481.71-1.46 1.538C28.846 28.988 29.534 29.684 30.362 29.619zM34.253 50.186c-3.614 0-7.438.667-11.06 1.93C10.829 56.427 3.257 65.864 5.953 73.601c1.75 5.016 7.663 8.131 15.433 8.131 3.678 0 7.63-.699 11.429-2.024 5.638-1.966 10.575-5.33 13.901-9.474 3.335-4.152 4.552-8.53 3.338-12.011C48.351 53.34 42.148 50.186 34.253 50.186zM44.377 68.355c-2.937 3.656-7.511 6.762-12.55 8.52-3.485 1.215-7.097 1.856-10.441 1.856-6.455 0-11.283-2.345-12.6-6.119C6.715 66.67 13.765 58.58 24.181 54.947c3.308-1.152 6.79-1.762 10.072-1.762 6.602 0 11.691 2.365 12.968 6.025C48.074 61.656 47.011 65.075 44.377 68.355z"></Path>
        <Path
            fill="#052049"
            d="M29.029 69.083c-6.919 2.028-11.514 2.124-12.977 1.71.473-.525 1.684-1.498 4.285-2.747.746-.359 1.062-1.255.702-2.002-.358-.747-1.255-1.062-2.002-.703-4.711 2.263-6.713 4.345-6.122 6.363.449 1.531 2.167 2.281 5.244 2.281.35 0 .718-.01 1.103-.029 2.966-.149 6.733-.857 10.609-1.994.796-.233 1.251-1.066 1.019-1.861C30.657 69.305 29.824 68.853 29.029 69.083zM77.262 39.819c-3.999-1.494-8.155-2.283-12.021-2.283-7.548 0-13.143 2.914-14.968 7.797-2.866 7.675 4.497 17.276 16.763 21.859 3.697 1.381 7.644 2.111 11.413 2.111 7.731 0 13.846-2.993 15.576-7.626C96.795 54.26 89.119 44.25 77.262 39.819zM91.214 60.628c-1.269 3.395-6.398 5.676-12.766 5.676-3.415 0-6.998-.664-10.363-1.922-10.333-3.86-17.204-12.104-15.003-17.999 1.349-3.606 6.007-5.847 12.158-5.847 3.511 0 7.305.724 10.972 2.094C87.122 46.706 93.132 55.494 91.214 60.628z"></Path>
        <Path
            fill="#052049"
            d="M69.196,48.403c-4.931-1.672-11.076-3.044-12.355-0.08c-0.807,1.866,0.783,4.085,4.861,6.782
			c0.254,0.169,0.542,0.249,0.826,0.249c0.486,0,0.964-0.236,1.252-0.672c0.457-0.691,0.268-1.622-0.423-2.079
			c-2.145-1.419-3.127-2.438-3.511-2.984c1.062-0.152,3.795,0.068,8.386,1.626c0.784,0.262,1.636-0.154,1.902-0.939
			C70.4,49.521,69.98,48.67,69.196,48.403z"></Path>
    </Svg>
)
