import { StyleSheet, View, Dimensions } from 'react-native'
import { MotiView } from 'moti'
import CardSkeletonNoImg from './CardSkeletonNoImg'
import CardSkeletonItem from './CardSkeletonItem'
const { height: wHeight, width: wWidth } = Dimensions.get('window')

export const HEADER_IMAGE_HEIGHT = wHeight / 3 + wWidth / 4

export default function HomeScreenSkeleton({ withImg }: { withImg: boolean }) {
    return (
        <MotiView
            transition={{
                type: 'timing'
            }}
            style={[styles.container]}
            animate={{ backgroundColor: '#ffffff' }}>
            {[...Array(8)].map((value, i) => (
                <View key={i}>{withImg === true ? <CardSkeletonItem colorMode={'light'} /> : <CardSkeletonNoImg colorMode={'light'} />}</View>
            ))}
        </MotiView>
    )
}

const styles = StyleSheet.create({
    shape: {
        justifyContent: 'center',
        height: 250,
        width: 250,
        borderRadius: 25,
        marginRight: 10,
        backgroundColor: 'white'
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        paddingTop: HEADER_IMAGE_HEIGHT
    }
})
