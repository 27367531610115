import { View } from 'react-native'
import { Skeleton } from 'moti/skeleton'
import tw from 'twrnc'
import { baseColors } from 'moti/build/skeleton/shared'

export default function CardSkeletonNoImg({ colorMode }: { colorMode: keyof typeof baseColors }) {
    return (
        <View style={tw`min-h-22 flex-row justify-between items-center pb-3`}>
            <View style={tw` flex-1 pr-3 flex-row items-center`}>
                <View style={tw`flex-1 pl-4 pr-5`}>
                    <Skeleton colorMode={colorMode} height={25} width={'50%'} />
                    <Skeleton colorMode={colorMode} height={25} width={'100%'} />
                    <Skeleton colorMode={colorMode} height={25} width={'100%'} />
                </View>
            </View>
        </View>
    )
}
