import { Label } from '../types'
import { collection, getDocs, query } from 'firebase/firestore/lite'
import { db } from '../firebase'

class LabelService {
    fetchAllLabels = async (restoId: string | null) => {
        console.log('📡 fetching all labels with resto id', restoId)

        const labelsMap: Map<string, Label> = new Map()

        const q = query(this.getLabelReef(restoId))
        try {
            const querySnapshot = await getDocs(q)
            querySnapshot.docs.forEach((labelDoc) => {
                const label = { id: labelDoc.id, ...labelDoc.data() } as Label
                if (label && label.name && label.name.trim() !== '') {
                    //TODO  move this to firestore
                    labelsMap.set(label.id, label)
                }
            })
            return labelsMap
        } catch (e) {
            console.error('Error fetching labels', e)
            throw e
        }
    }

    private getLabelReef(restoId: string | null) {
        return collection(db, 'users/' + restoId + '/labels')
    }
}

export default new LabelService()
