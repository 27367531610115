import Svg, { Circle, Path } from 'react-native-svg'

export const EggSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="63.133" cy="50.565" r="13.033" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M55.633,12.099c-12.352,0-23.435,6.777-29.076,17.73c-8.934,3.139-14.892,11.514-14.892,21.038
			c0,12.296,10.003,22.3,22.3,22.3c1.072,0,2.146-0.077,3.208-0.23c3.092,8.876,11.564,14.965,21.059,14.965
			c12.166,0,22.088-9.793,22.297-21.91c5.037-5.914,7.804-13.417,7.804-21.191C88.334,26.769,73.665,12.099,55.633,12.099z
			 M77.906,64.439c-0.242,0.274-0.375,0.627-0.375,0.992l0.003,0.169c0,10.643-8.659,19.301-19.301,19.301
			c-8.612,0-16.252-5.787-18.579-14.073c-0.185-0.658-0.783-1.095-1.443-1.095c-0.1,0-0.203,0.01-0.304,0.031
			c-1.293,0.267-2.619,0.402-3.941,0.402c-10.642,0-19.3-8.658-19.3-19.3c0-8.429,5.39-15.816,13.411-18.384
			c0.389-0.124,0.709-0.401,0.889-0.767c5.039-10.251,15.257-16.618,26.667-16.618c16.377,0,29.701,13.324,29.701,29.701
			C85.334,52.034,82.696,59.009,77.906,64.439z"></Path>
        <Path
            fill="#052049"
            d="M56.179,27.021c-9.429,0-17.1,7.671-17.1,17.101s7.67,17.101,17.1,17.101
			c9.43,0,17.102-7.671,17.102-17.101S65.609,27.021,56.179,27.021z M56.179,58.222c-7.774,0-14.1-6.325-14.1-14.101
			s6.325-14.101,14.1-14.101c7.775,0,14.102,6.325,14.102,14.101S63.955,58.222,56.179,58.222z"></Path>
        <Path
            fill="#052049"
            d="M56.18,33.7c-5.746,0-10.421,4.675-10.421,10.421c0,0.828,0.671,1.5,1.5,1.5c0.828,0,1.5-0.672,1.5-1.5
			c0-4.092,3.329-7.421,7.421-7.421c0.829,0,1.5-0.672,1.5-1.5S57.009,33.7,56.18,33.7z"></Path>
        <Circle cx="35.544" cy="59.968" r="1.5" fill="#052049"></Circle>
        <Circle cx="28.423" cy="44.635" r="1.5" fill="#052049"></Circle>
        <Circle cx="24.213" cy="54.979" r="1.5" fill="#052049"></Circle>
    </Svg>
)
