import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

interface LabelsProps {
    title: string
    svgIcon?: React.ReactNode
    colors?: string
}

const Labels: React.FC<LabelsProps> = ({ title, colors = '#F3F4F6', svgIcon }) => {
    const styles = StyleSheet.create({
        container: {
            borderWidth: 1,
            flexDirection: 'row',
            alignItems: 'center',
            borderColor: 'transparent',
            borderRadius: 8,
            paddingHorizontal: 1,
            paddingVertical: 2,
            alignSelf: 'flex-start',
            backgroundColor: '#F3F4F6'
        },
        text: {
            fontSize: 12,
            color: '#4B5563',
            fontWeight: '500',
            marginHorizontal: 5
        },
        outerContainer: {
            paddingLeft: 5
        }
    })

    return (
        <View style={styles.outerContainer}>
            <View style={styles.container}>
                <Text style={styles.text}>{title}</Text>
                {svgIcon && <View>{svgIcon}</View>}
            </View>
        </View>
    )
}

export default Labels
