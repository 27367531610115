import { User } from '../types'
import { doc, getDoc } from 'firebase/firestore/lite'
import { db } from '../firebase'

class UserService {
    fetchUserInfo = async (restoId: string) => {
        console.log('📡 fetching all user data ')

        const ref = doc(db, 'users/' + restoId)
        const docSnap = await getDoc(ref)
        if (docSnap.exists()) {
            const user = docSnap.data() as User
            console.log('fetched user', { ...user })
            return user
        } else {
            console.log('No such document!')
        }
    }
}

export default new UserService()
