import { useEffect } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'

import stripeFunctions from '../../functions/stripeFunctions'

type CheckoutFormType = {
    callPayment: boolean
    setCardPaymentErrorMsg: any
    setIsProcessingCbPayment: any
    stripeAccountId: string | undefined
    stripeAmount: number
}

export function CheckoutForm({ callPayment, setCardPaymentErrorMsg, setIsProcessingCbPayment, stripeAccountId, stripeAmount }: CheckoutFormType) {
    const stripe = useStripe()
    const elements = useElements()

    const handleError = (error: any) => {
        setIsProcessingCbPayment(false)
        setCardPaymentErrorMsg(error.message)
    }

    const handleServerResponse = async (response: any) => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }
        console.log('handleServerResponse', response)
        if (response.error) {
            // Show error from server on payment form
        } else if (response.status === 'requires_action') {
            // Use Stripe.js to handle the required next action
            const { error, paymentIntent } = await stripe.handleNextAction({
                clientSecret: response.clientSecret
            })

            if (error) {
                // Show error from Stripe.js in payment form
                console.log('error happpened')
                handleError(error)
            } else {
                // Actions handled, show success message
                console.log('show success message')
                setCardPaymentErrorMsg('')
            }
        } else {
            // No actions needed, show success message
            console.log('No actions needed')
        }
    }

    useEffect(() => {
        handleSubmit()
    }, [callPayment])

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsProcessingCbPayment(true)

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit()
        if (submitError) {
            console.log('calling elements.submit')
            handleError(submitError)
            return
        }
        // const options = {
        //     paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'klarna']
        // }

        // const expressCheckoutElement = elements.create('expressCheckout', options)
        // expressCheckoutElement.mount('#express-checkout-element')

        // Create the PaymentMethod using the details collected by the Payment Element
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
            params: {
                billing_details: {
                    name: 'userName' // todo fetch this from previous screen
                }
            }
        })

        if (error) {
            // This point is only reached if there's an immediate error when
            // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
            handleError(error)
            return
        }

        // Create the PaymentIntent
        const data = stripeAccountId && (await stripeFunctions.fetchConfirmIntent(stripeAmount, 'EUR', stripeAccountId, paymentMethod.id))
        console.log('datatatas', data)
        await handleServerResponse(data)

        console.log('payment finished succesfully ', paymentMethod.id)

        setIsProcessingCbPayment(false)
    }

    return (
        <form>
            <PaymentElement />
        </form>
    )
}
