import ObligatoryLabel from '../ObligatoryLabel'
import React, { useEffect, useState } from 'react'
import { FlatList, View, StyleSheet, Text, Pressable } from 'react-native'
import { Checkbox, Divider } from 'react-native-paper'
import { ChoiceModifierProps, Option, SubscriptionType } from '../../types'

const MultiChoiceModifier = ({ modifier, isWarning, modifierIndex, subscriptionType, addOptionToItem, removeOptionFromItem, updateAddToCartState }: ChoiceModifierProps) => {
    const [checkedOptions, setCheckedOptions] = useState<boolean[]>(Array(modifier.options.length).fill(false))

    const toggleCheckbox = (index: number, option: Option) => {
        checkedOptions[index] ? removeOptionFromItem(option, modifier.id) : addOptionToItem(option, modifier.id)

        setCheckedOptions((prevCheckedOptions) => {
            const updatedCheckedOptions = [...prevCheckedOptions]
            updatedCheckedOptions[index] = !updatedCheckedOptions[index]
            return updatedCheckedOptions
        })
    }

    useEffect(() => {
        if (modifier.required) {
            const numOfCheckedOptions = checkedOptions.filter((value) => value === true).length
            updateAddToCartState(modifierIndex, numOfCheckedOptions === modifier.maxSelect)
        }
    }, [checkedOptions])

    return (
        <>
            <View style={styles.obligatoryLabel}>
                <Text style={styles.modifierName}>{modifier.name}</Text>
                {modifier.required ? <ObligatoryLabel isWarning={isWarning} /> : null}
            </View>

            {modifier.options.length > 1 && <Text style={styles.modifierNumChoice}>Jusqu'à {modifier.maxSelect} choix</Text>}

            {modifier && (
                <FlatList
                    data={modifier.options.filter((option) => option.enabled)}
                    keyExtractor={(item) => item.name}
                    renderItem={({ item: option, index }) => {
                        const disableCheckbox = checkedOptions.filter((checked, idx) => checked && idx !== index).length >= modifier.maxSelect

                        return (
                            <Pressable key={index} onPress={() => subscriptionType !== SubscriptionType.BASIC && !disableCheckbox && toggleCheckbox(index, option)} style={disableCheckbox ? { opacity: 0.4 } : { opacity: 1 }}>
                                <View style={styles.itemContainer}>
                                    <View style={styles.priceAndOption}>
                                        <Text style={styles.optionName}>{option.name}</Text>
                                    </View>
                                    <View style={styles.itemDetails}>
                                        {option.numericPrice > 0 && <Text style={styles.priceOption}>{option.price} €</Text>}
                                        {subscriptionType !== SubscriptionType.BASIC && <Checkbox status={checkedOptions[index] ? 'checked' : 'unchecked'} onPress={() => toggleCheckbox(index, option)} color="black" disabled={disableCheckbox} />}
                                    </View>
                                </View>
                                {index !== modifier.options.length - 1 && <Divider style={{ marginHorizontal: 15 }} />}
                            </Pressable>
                        )
                    }}
                />
            )}
        </>
    )
}

export default MultiChoiceModifier

const styles = StyleSheet.create({
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 15
    },
    priceAndOption: {
        paddingBottom: 15
    },
    checkbox: {
        paddingBottom: 1 // Adjust the margin right value to control the spacing between the checkbox item and the item price
    },
    priceAndName: {
        paddingBottom: 15
    },
    priceOption: {
        fontSize: 14,
        paddingHorizontal: 22,
        color: 'black',
        fontWeight: '400'
    },
    itemDetails: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 9
    },
    obligatoryLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    optionName: {
        fontFamily: 'circular-std',
        fontSize: 16,
        lineHeight: 20,
        paddingHorizontal: 15,
        paddingTop: 15,
        fontWeight: '400',
        maxWidth: 190,
        textAlign: 'left'
    },
    itemModifier: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700'
    },
    modifierName: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700',
        textAlign: 'left'
    },

    modifierNumChoice: {
        fontSize: 14,
        lineHeight: 28,
        paddingLeft: 15,
        fontWeight: '400',
        textAlign: 'left'
    }
})
