import { Float } from 'react-native/Libraries/Types/CodegenTypes'
import { getFunctions, httpsCallable } from 'firebase/functions'

class StripeFunctions {
    fetchPaymentIntent = async (amount: Float, currency: string, stripeAccountId: string) => {
        console.log('Calling createPaymentIntent with :', amount, currency, stripeAccountId)
        const functions = getFunctions()
        const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent')

        try {
            const result = await createPaymentIntent({ amount, currency, stripeAccountId })
            const clientSecret = result.data.clientSecret
            return clientSecret
        } catch (error) {
            // Handle any errors that occurred during the function call
            console.error('Error calling createPaymentIntent:', error)
        }
    }

    fetchPublishableKey = async () => {
        const functions = getFunctions()
        const getPublishableKey = httpsCallable(functions, 'getPublishableKey')

        try {
            const result = await getPublishableKey()
            const publishableKey = result.data.publishableKey
            return publishableKey
        } catch (error) {
            // Handle any errors that occurred during the function call
            console.error('Error calling getPublishableKey:', error)
        }
    }

    fetchConfirmIntent = async (amount: Float, currency: string, stripeAccountId: string, paymentMethodId: string) => {
        console.log('Calling fetchConfirmIntent with :', amount, currency, stripeAccountId)
        const functions = getFunctions()
        const createConfirmIntent = httpsCallable(functions, 'createConfirmIntent')

        try {
            const result = await createConfirmIntent({ amount, currency, stripeAccountId, paymentMethodId })
            const paymentMethodIdResult = result.data
            return paymentMethodIdResult
        } catch (error) {
            // Handle any errors that occurred during the function call
            console.error('Error calling createPaymentIntent:', error)
        }
    }
}

export default new StripeFunctions()
