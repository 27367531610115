import Svg, { Circle, Path } from 'react-native-svg'

export const MustardSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="54.451" cy="49.084" r="12.032" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M76.331,47.556c0.496-6.742-3.204-16.425-8.424-22.043c-2.332-2.511-4.748-3.937-7.033-4.183v-4.134
			c0-1.93-1.57-3.5-3.5-3.5H56.72l-0.604-4.709c-0.238-1.846-1.883-3.292-3.743-3.292h-4.999c-1.861,0-3.505,1.446-3.742,3.292
			l-0.604,4.709h-0.653c-1.931,0-3.5,1.57-3.5,3.5v4.153c-2.211,0.323-4.535,1.745-6.781,4.163c-5.22,5.617-8.92,15.3-8.424,22.043
			c0.162,2.212,0.782,4.499,1.438,6.921c0.75,2.769,1.526,5.633,1.559,8.346c0.051,4.254-0.588,8.806-1.151,12.822
			c-0.355,2.531-0.691,4.923-0.839,7.056c-0.197,2.868,0.82,5.656,2.867,7.849c2.227,2.387,5.373,3.755,8.63,3.755h27.656
			c3.257,0,6.402-1.368,8.63-3.755c2.046-2.192,3.064-4.979,2.867-7.849c-0.148-2.133-0.483-4.522-0.839-7.054
			c-0.563-4.017-1.202-8.569-1.152-12.823c0.032-2.714,0.809-5.577,1.559-8.347C75.548,52.055,76.168,49.768,76.331,47.556z
			 M46.606,9.371c0.045-0.341,0.424-0.675,0.768-0.675h4.999c0.343,0,0.724,0.334,0.767,0.675l0.556,4.326h-7.644L46.606,9.371z
			 M42.374,16.697h15c0.271,0,0.5,0.229,0.5,0.5v4.082h-16v-4.082C41.874,16.925,42.103,16.697,42.374,16.697z M73.339,47.335
			c-0.142,1.926-0.725,4.078-1.342,6.357c-0.799,2.95-1.625,6-1.663,9.095c-0.053,4.481,0.603,9.154,1.182,13.276
			c0.348,2.477,0.677,4.816,0.815,6.844c0.141,2.029-0.594,4.017-2.066,5.595c-1.662,1.78-4.008,2.802-6.437,2.802H36.172
			c-2.429,0-4.774-1.021-6.437-2.802c-1.473-1.578-2.207-3.565-2.066-5.595c0.139-2.027,0.468-4.368,0.814-6.846
			c0.58-4.122,1.235-8.793,1.182-13.274c-0.037-3.095-0.863-6.146-1.662-9.096c-0.617-2.278-1.201-4.431-1.342-6.356
			c-0.436-5.911,2.986-14.784,7.629-19.78c1.963-2.113,3.932-3.276,5.541-3.276h20.337c1.61,0,3.579,1.164,5.542,3.276
			C70.352,32.551,73.774,41.425,73.339,47.335z"></Path>
        <Path
            fill="#052049"
            d="M49.87,30.289c-9.563,0.003-12.51,8.47-12.734,13.02l-0.014,8.436c0,0.397,0.158,0.78,0.439,1.062
			c0.281,0.282,0.663,0.44,1.061,0.44h22.496c0.398,0,0.781-0.158,1.063-0.44c0.28-0.281,0.438-0.664,0.438-1.062l-0.016-8.508
			C62.38,38.758,59.433,30.292,49.87,30.289z M40.124,50.246l0.01-6.861c0.021-0.412,0.607-10.093,9.736-10.096
			c9.081,0.003,9.715,9.688,9.734,10.023l0.012,6.934H40.124z"></Path>
    </Svg>
)
