import Svg, { Circle, Path } from 'react-native-svg'

export const MollusksSvg = (
    <Svg viewBox="0 0 100 100" width="30" height="30">
        <Path
            fill="#052049"
            d="M82.361 33.147a19.535 19.535 0 0 0-7.96-1.69 19.71 19.71 0 0 0-18.009 11.718 19.457 19.457 0 0 0-.736 1.947c-2.916 8.153-7.673 16.085-13.758 22.938a73.969 73.969 0 0 1-9.247 8.774 6.019 6.019 0 0 0-1.983 2.435c-1.361 3.075.032 6.687 3.104 8.049a5.7 5.7 0 0 0 1.055.349c.084.034.171.061.262.078 2.741.556 5.633.837 8.594.837 8.637 0 17.587-2.345 25.883-6.78 9.389-5.023 17.146-12.402 21.822-20.743.061-.103.86-1.629.999-1.939 4.393-9.927-.104-21.578-10.026-25.973zm7.283 24.751a50.145 50.145 0 0 1-.856 1.66c-4.419 7.88-11.749 14.84-20.639 19.597-7.862 4.204-16.323 6.426-24.468 6.426-2.675 0-5.283-.245-7.757-.729a1.41 1.41 0 0 0-.267-.076 2.948 2.948 0 0 1-.666-.199 3.108 3.108 0 0 1-1.581-4.094c.21-.478.553-.891 1.061-1.265a77.11 77.11 0 0 0 9.669-9.167c6.338-7.137 11.297-15.408 14.357-23.971.19-.599.399-1.153.639-1.693a16.705 16.705 0 0 1 15.265-9.931c2.328 0 4.597.482 6.744 1.433 8.41 3.727 12.222 13.602 8.499 22.009z"></Path>
        <Path
            fill="#052049"
            d="M76.908 48.542c-3.424-2.414-7.848-3.043-11.836-1.683a1.501 1.501 0 0 0 .968 2.84c3.082-1.051 6.498-.567 9.139 1.295a10.184 10.184 0 0 1 4.294 8.171 1.5 1.5 0 0 0 1.499 1.479h.021a1.5 1.5 0 0 0 1.479-1.521 13.196 13.196 0 0 0-5.564-10.581zm-9.642 12.302a9.633 9.633 0 0 0-8.501-1.93 1.5 1.5 0 0 0 .732 2.91 6.611 6.611 0 0 1 5.846 1.323 6.611 6.611 0 0 1 2.344 5.512 1.499 1.499 0 1 0 2.993.205 9.619 9.619 0 0 0-3.414-8.02zM51.417 69.24l-.047-.001a1.5 1.5 0 0 0-.044 2.999 3.25 3.25 0 0 1 2.621 1.435c.589.865.727 1.973.368 2.962a1.5 1.5 0 1 0 2.82 1.022 6.292 6.292 0 0 0-.707-5.67 6.297 6.297 0 0 0-5.011-2.747z"></Path>
        <Circle cx="29.174" cy="50.115" r="10.032" fill="#ff5e71"></Circle>
        <g fill="#052049">
            <Path
                d="M66.225 12.68a5.691 5.691 0 0 0-1.045-.345 1.5 1.5 0 0 0-.267-.081 43.49 43.49 0 0 0-8.593-.835c-8.64 0-17.59 2.345-25.886 6.78-9.389 5.024-17.145 12.402-21.821 20.744a61.684 61.684 0 0 0-.999 1.938c-4.394 9.926.103 21.577 10.026 25.971a19.542 19.542 0 0 0 7.96 1.691h.001a19.708 19.708 0 0 0 18.007-11.717c.28-.633.526-1.286.735-1.948 2.916-8.152 7.674-16.084 13.759-22.938a73.976 73.976 0 0 1 9.247-8.774 6.027 6.027 0 0 0 1.984-2.435c1.362-3.074-.033-6.687-3.108-8.051zm.365 6.836c-.212.479-.554.893-1.062 1.266a77.194 77.194 0 0 0-9.67 9.167c-6.338 7.138-11.297 15.409-14.357 23.97a16.386 16.386 0 0 1-.637 1.694 16.708 16.708 0 0 1-15.265 9.931 16.551 16.551 0 0 1-6.745-1.434c-8.41-3.726-12.223-13.6-8.5-22.006.111-.248.775-1.517.857-1.662 4.418-7.88 11.748-14.839 20.638-19.597 7.861-4.204 16.323-6.426 24.471-6.426 2.672 0 5.278.244 7.751.727.087.034.178.061.271.078.257.049.475.113.665.198a3.107 3.107 0 0 1 1.583 4.094z"
                fill="#000000"></Path>
            <Path
                d="M40.851 37.231c-2.076.544-4.21.082-5.856-1.269a6.608 6.608 0 0 1-2.394-5.492 1.5 1.5 0 0 0-2.995-.178 9.617 9.617 0 0 0 3.487 7.99 9.603 9.603 0 0 0 6.082 2.164c.813 0 1.633-.104 2.437-.313a1.5 1.5 0 0 0-.761-2.902zm8.076-10.488a3.252 3.252 0 0 1-2.635-1.41 3.29 3.29 0 0 1-.395-2.959 1.5 1.5 0 0 0-2.831-.994 6.294 6.294 0 0 0 .761 5.663 6.304 6.304 0 0 0 5.036 2.7h.032a1.5 1.5 0 0 0 .032-3zM34.419 49.415c-3.072 1.079-6.492.627-9.15-1.212a10.183 10.183 0 0 1-4.367-8.131c-.02-.828-.703-1.453-1.534-1.466a1.502 1.502 0 0 0-1.466 1.534 13.19 13.19 0 0 0 5.662 10.53 13.134 13.134 0 0 0 7.472 2.32c1.474 0 2.955-.246 4.378-.746a1.5 1.5 0 1 0-.995-2.829z"
                fill="#000000"></Path>
        </g>
    </Svg>
)
