import { View } from 'react-native'
import { Skeleton } from 'moti/skeleton'
import tw from 'twrnc'

export default function StripeSkeleton() {
    return (
        <View style={tw`min-h-22 flex-row justify-between items-center pb-3`}>
            <View style={tw` flex-1  flex-row items-center`}>
                <View style={tw`flex-1 pl-4 pr-5`}>
                    <Spacer />
                    <Skeleton colorMode="light" height={25} width={'100%'} />
                    <Spacer />
                    <Skeleton colorMode="light" height={25} width={'100%'} />
                    <Spacer />
                    <Skeleton colorMode="light" height={25} width={'100%'} />
                    <Spacer />
                    <Skeleton colorMode="light" height={25} width={'100%'} />
                    <Spacer />
                    <Skeleton colorMode="light" height={25} width={'100%'} />
                </View>
            </View>
        </View>
    )
}
const Spacer = ({ height = 16 }) => <View style={{ height }} />
