const initialState = {
    count: 0
}

const counterReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'INCREMENT':
            return { ...state, count: state.count + 1 }
        case 'DECREMENT':
            return { ...state, count: state.count - 1 }
        case 'SET_INITIAL_COUNT': // Handle the action to set the initial state from localStorage
            return { ...state, count: action.payload }
        default:
            return state
    }
}

export default counterReducer
