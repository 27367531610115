import { firestoreApi } from '../../app/firestoreApi'
import { User } from '../../types'
import UserService from '../../services/UserService'

export const userApi = firestoreApi.injectEndpoints({
    endpoints: (build) => ({
        fetchUsers: build.query<User, string>({
            async queryFn(restoId: string) {
                try {
                    console.log('📭 FETCH USER >>>>>>>>> fetching all users from Redux Store', restoId)
                    const userInfo: User | undefined = await UserService.fetchUserInfo(restoId)

                    return { data: userInfo }
                } catch (error: any) {
                    console.error(error.message)
                    return { error: error.message }
                }
            },
            providesTags: ['UserInfo']
        })
    }),
    overrideExisting: false
})

export const { useFetchUsersQuery } = userApi
