import Constants from 'expo-constants'
import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore/lite'
import { getStorage } from 'firebase/storage'
import { getFirestore as getFS } from 'firebase/firestore'
// Initialize Firebase
const firebaseConfig = {
    apiKey: Constants?.expoConfig?.extra?.firebase?.apiKey,
    authDomain: Constants?.expoConfig?.extra?.firebase?.authDomain,
    databaseURL: Constants?.expoConfig?.extra?.firebase?.databaseURL,
    projectId: Constants?.expoConfig?.extra?.firebase?.projectId,
    storageBucket: Constants?.expoConfig?.extra?.firebase?.storageBucket,
    messagingSenderId: Constants?.expoConfig?.extra?.firebase?.messagingSenderId,
    appId: Constants?.expoConfig?.extra?.firebase?.appId,
    measurementId: Constants?.expoConfig?.extra?.firebase?.measurementId
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)
const firestore = getFS(app)

export { app, db, storage, firestore }
