import { useEffect, useRef, useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { LayoutChangeEvent, NativeScrollEvent, NativeSyntheticEvent, Pressable, StyleSheet, View, useWindowDimensions, Text, TouchableOpacity, Dimensions, ImageBackground, Image } from 'react-native'
import Animated, { Extrapolate, interpolate, interpolateColor, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import ImgService from '../services/ImgService'
import { Category, ImgUrl, Item, Label, Modifier, DisplayStatusEnum, User, UrlParams, CartState } from '../types'
import { addOpacity, debounce } from '../utils/Utils'
import { useFetchCategoriesQuery } from '../features/catWitems/catWItemSlice'
import Labels from '../components/Labels'
import LabelService from '../services/LabelService'
import ModifierService from '../services/ModifierService'
import { Button, Divider } from 'react-native-paper'
import UnavailableLabel from '../components/UnavailableLabel'
import { useAppDispatch } from '../app/hooks'
import { cartActions } from '../features/cart/cartSlice'
import { setUrlParams } from '../features/resto/restoSlice'
import { Avatar } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useTheme } from '@react-navigation/native'
import { useFetchUsersQuery } from '../features/users/userSlice'
import imageBlanche from '../assets/blanche.png'
import { Ionicons } from '@expo/vector-icons'
import PassedOrders from '../components/PassedOrders'
import tw from 'twrnc'

const padding = 20

type Layout = Record<number | string, { x: number; headerItemWidth: number }>
type BodyLayout = Record<number | string, { y: number; bodyItemHeight: number }>

export default function HomeScreen({ route, navigation }: any) {
    const dispatch = useAppDispatch()
    const urlParams: UrlParams = route.params

    useEffect(() => {
        urlParams && dispatch(setUrlParams(urlParams))
    }, [])

    const { colors } = useTheme()
    const { data: categoriesFromStore, isFetching: categoriesStoreFetching } = useFetchCategoriesQuery(urlParams.restoId)
    const { data: usersFromStore, isFetching: usersStoreFetching } = useFetchUsersQuery(urlParams.restoId)
    const cartState = useSelector((state: RootState) => state.cart)

    const [isInitialRender, setIsInitialRender] = useState(true)

    // STATES FOR IMAGE
    const [imgUrlsMap, setImgUrlsMap] = useState<Map<string, ImgUrl>>(new Map())
    const [loadedImg, setLoadedImg] = useState(false)

    const [quantityUpdated, setQuantityUpdated] = useState(false)
    const [modifiersList, setModifiersList] = useState<Modifier[]>([])
    const [itemsWQuantity, setItemsWQuantity] = useState<any>(null)

    // STATES FOR CATEGORIES WITH ITEMS
    const [categoriesWItems, setCategoriesWItems] = useState<Category[]>([])

    // STATES FOR USERS
    const [user, setUser] = useState<User | null>(null)

    //STATES FOR LABELS
    const [labelUrlsMap, setlabelUrlsMap] = useState<Map<string, Label>>(new Map())
    const [labels, setLabels] = useState<Map<string, Label>>(new Map())

    //SCROLL HANDELING STATES
    const { top } = useSafeAreaInsets()
    const { height: _h, width } = useWindowDimensions()
    const cardHeight = 200
    const HEADER_IMAGE_HEIGHT = _h / 3

    const contentScrollRef = useRef<Animated.ScrollView>(null)
    const headingScrollRef = useRef<Animated.ScrollView>(null)

    const contentScrollOffset = useSharedValue(0)
    const activeIndex = useSharedValue(0)

    const cachedLayoutRestoName = useSharedValue<Layout>({})
    const _cachedLayoutRestoName = useRef<Layout>({}) // used to copy objects to a shared value

    const cachedLayout = useSharedValue<Layout>({})
    const _cachedLayout = useRef<Layout>({}) // used to copy objects to a shared value

    const bodyCachedLayout = useSharedValue<BodyLayout>({})
    const _bodyCachedLayout = useRef<BodyLayout>({}) // used to copy objects to a shared value
    const [isExtended, setIsExtended] = useState(true)

    // Parallel calls to fetch at the sale time the category list with their Items & The Modifiers list with their Options
    useEffect(() => {
        categoriesFromStore && setCategoriesWItems(categoriesFromStore)
    }, [categoriesStoreFetching])
    //Usage de userinfo
    useEffect(() => {
        usersFromStore && setUser(usersFromStore)
    }, [usersStoreFetching])

    useEffect(() => {
        const itemMap = cartState.items.reduce((acc: any, item) => {
            const { id, quantity } = item
            if (acc[id]) {
                acc[id].quantity += quantity
            } else {
                acc[id] = { id, quantity }
            }
            return acc
        }, {})
        itemMap && setItemsWQuantity(itemMap)
    }, [cartState])

    useEffect(() => {
        // todo block fetching at startup
        const fetchLabels = async () => {
            const fetchedLabels = await LabelService.fetchAllLabels(urlParams.restoId)
            setLabels(fetchedLabels)
        }
        fetchLabels()
        updateCartFromStorage()
    }, [])

    useEffect((): any => {
        const fetchImgUrl = async () => {
            const newImgUrlsMap = new Map(imgUrlsMap)
            categoriesWItems.forEach(async (cat) => {
                const urlWithIds = await ImgService.getItemImgUrlsFromId(cat.id, urlParams.restoId)
                for (const urlWithId of urlWithIds) {
                    newImgUrlsMap.set(urlWithId[0], urlWithId[1])
                    setLoadedImg((prev) => !prev)
                }
            })
            setImgUrlsMap(newImgUrlsMap)
        }
        // todo : check if this fix the image loading bug
        console.log('>>>>>>>>>>>>fetching uimage again ')
        if (!isInitialRender && !quantityUpdated) fetchImgUrl()
        else setIsInitialRender(false)
    }, [categoriesWItems])

    // Todo check if this will lower the starting if in parallel with the categories fetch
    // if yes block the startup fetching and start it just after categories are set
    useEffect((): any => {
        let isSubscribed = true
        const fetchModifiers = async () => {
            const modifiers = await ModifierService.fetchAllModifiersWithOptions(urlParams.restoId)
            console.log('fetched modifiers', modifiers)
            setModifiersList(modifiers)
        }

        const timeoutId = setTimeout(() => {
            scrollContentOnHeaderPress(0)()
        }, 500)
        isSubscribed && fetchModifiers()
        return () => {
            isSubscribed = false
            clearTimeout(timeoutId)
        }
    }, [])

    const updateCartFromStorage = () => {
        const cartStateJSON = localStorage.getItem('cart')

        if (cartStateJSON) {
            const cartState: CartState = JSON.parse(cartStateJSON)
            console.log('reseting cart state with ', cartState)
            dispatch(cartActions.resetCart(cartState))
        } else {
            console.error('No cart data found in localStorage.')
        }
    }
    function handleItemClick(item: Item) {
        dispatch(cartActions.addNewItemToCart(item))
        const itemModifiers = item.modifiersIds ? modifiersList.filter((modifier) => item.modifiersIds.includes(modifier.id)) : []
        navigation.navigate('ItemScreen', { item: item, uri: imgUrlsMap.get(item.id)?.imgUrl, modifiers: itemModifiers, closedRestaurant: user.closedRestaurant, subscriptionType: user.subscruptionType })
    }

    function goToCartScreen() {
        navigation.navigate('CartScreen')
    }

    function gotToPassedOrderScreen() {
        navigation.navigate('PassedOrder', { restoId: urlParams.restoId })
    }

    const cacheLayout = (i: number) => (e: LayoutChangeEvent) => {
        //a ref is used to copy object values because reanimated worklets do not support the spread operation
        _cachedLayout.current = {
            ..._cachedLayout.current,
            [i]: {
                x: e?.nativeEvent?.layout?.x || 0,
                headerItemWidth: e?.nativeEvent?.layout?.width || 0
            }
        }
        cachedLayout.value = _cachedLayout.current
    }

    const cacheLayoutRestoName = (i: string) => (e: LayoutChangeEvent) => {
        //a ref is used to copy object values because reanimated worklets do not support the spread operation
        _cachedLayoutRestoName.current = {
            ..._cachedLayoutRestoName.current,
            [i]: {
                x: e?.nativeEvent?.layout?.x || 0,
                headerItemWidth: e?.nativeEvent?.layout?.width || 0
            }
        }
        cachedLayoutRestoName.value = _cachedLayoutRestoName.current
    }

    const cacheBodyLayout = (i: number | string) => (e: LayoutChangeEvent) => {
        //a ref is used to copy object values because reanimated worklets do not support the spread operation
        _bodyCachedLayout.current = {
            ..._bodyCachedLayout.current,
            [i]: {
                y: e?.nativeEvent?.layout?.y || 0,
                bodyItemHeight: e?.nativeEvent?.layout?.width || 0
            }
        }
        bodyCachedLayout.value = _bodyCachedLayout.current
    }

    const animatedBackgroundStyle = useAnimatedStyle(() => ({
        backgroundColor: interpolateColor(contentScrollOffset.value, [0, 100], [addOpacity('#F8E1F3', 0.005), addOpacity('#e5e7fe', 0.15), addOpacity('#C0E5FD', 0.25), addOpacity('#FAFBFF', 0.35)])
    }))

    const animatedIndicatorStyle = useAnimatedStyle(() => ({
        width: withTiming(cachedLayout.value?.[activeIndex.value]?.headerItemWidth || 0),
        left: withTiming(cachedLayout.value?.[activeIndex.value]?.x || 0)
    }))

    const animatedTitleStyle = useAnimatedStyle(() => {
        const _interpolate = (range: [number, number]) => {
            return interpolate(contentScrollOffset.value, [0, 100], range, Extrapolate.CLAMP)
        }
        const textCenter = (width - padding * 2 - (cachedLayoutRestoName.value?.['text']?.headerItemWidth || 0)) / 2

        return {
            fontSize: _interpolate([25, 22]),
            left: _interpolate([0, textCenter])
        }
    })

    const findIndexByY = (y: number): number => {
        const indices = Object.keys(bodyCachedLayout.value)
            .filter((index) => bodyCachedLayout.value[index].y < y)
            .map((index, i) => i)

        return indices.pop() || 0
    }

    const scrollHeading = (headingIndex: number) => {
        // console.log('scrollHeading', bodyCachedLayout.value)
        headingScrollRef.current?.scrollTo({
            x: cachedLayout.value?.[headingIndex]?.x || 0
        })
    }

    // Handling the scrolling
    const moveIndicator = debounce((contentScrollOffsets: number) => {
        const index = findIndexByY(contentScrollOffsets)
        scrollHeading(index)
        activeIndex.value = findIndexByY(contentScrollOffsets)
    }, 50)

    const onContentScroll = (e: NativeSyntheticEvent<NativeScrollEvent>) => {
        contentScrollOffset.value = e?.nativeEvent?.contentOffset?.y || 0
        moveIndicator(contentScrollOffset.value)

        //Fab button handling
        const currentScrollPosition = Math.floor(contentScrollOffset.value)
        setIsExtended(currentScrollPosition <= 0)
    }

    const scrollContentOnHeaderPress = (headingIndex: number) => () => {
        activeIndex.value = headingIndex
        contentScrollRef.current?.scrollTo({
            y: bodyCachedLayout.value[headingIndex]?.y + 2 || 0,
            animated: true
        })
    }

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <ImageBackground source={require('../assets/images/20.jpg')}>
                <Animated.View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
                    <View style={{ backgroundColor: 'transparent' }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Image
                                    style={{ width: 50, height: 50, marginBottom: 15 }}
                                    source={{ uri: 'https://firebasestorage.googleapis.com/v0/b/myqrmenu-c35b7.appspot.com/o/download-20.png?alt=media&token=318c7e08-b47b-49d2-972b-56d70f0332c3' }} //to do change this
                                />
                                <Animated.Text onLayout={cacheLayoutRestoName('text')} style={[styles.animatedText, animatedTitleStyle]}>
                                    {user && <>{user.restaurantName && <Text>{user.restaurantName}</Text>}</>}
                                </Animated.Text>
                            </View>
                            <Button onPress={() => window.confirm('Souhaiteriez-vous que le serveur se rende à votre table ?')} textColor={colors.primary}>
                                {/* <Animated.View style={{ height: 25, aspectRatio: 1, marginRight: 5 }}>
                                    <LottieView autoPlay loop source={require('../assets/animation/ordersPassed.json')} />
                                </Animated.View> */}
                                <Text style={{ fontFamily: 'Aeonik-bold', fontWeight: '500', fontSize: 12, color: '#111827' }}>Serveur</Text>
                                <Ionicons style={{ WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', marginLeft: 8, backgroundColor: 'black' }} name="hand-right-outline" size={20} />
                            </Button>
                        </View>

                        <Animated.ScrollView
                            ref={headingScrollRef}
                            style={{ position: 'relative', backgroundColor: 'transparent' }}
                            contentContainerStyle={{
                                paddingRight: 50,
                                backgroundColor: 'transparent'
                            }}
                            horizontal
                            scrollEventThrottle={16}
                            showsHorizontalScrollIndicator={false}>
                            <Animated.View style={[styles.indicator, animatedIndicatorStyle]} />

                            {categoriesWItems.map((cat: Category, index) => (
                                <Pressable onPress={scrollContentOnHeaderPress(index)} key={cat.name} onLayout={cacheLayout(index)}>
                                    <Text style={styles.headingText}>{cat.name}</Text>
                                </Pressable>
                            ))}
                        </Animated.ScrollView>
                    </View>
                </Animated.View>
            </ImageBackground>

            <Animated.ScrollView
                ref={contentScrollRef}
                showsVerticalScrollIndicator={false}
                onScroll={onContentScroll}
                scrollEventThrottle={16}
                snapToInterval={cardHeight}
                snapToAlignment="center"
                decelerationRate={'fast'}
                style={styles.divider}
                contentContainerStyle={{ paddingBottom: cardHeight * 0.25 }} // extra padding to ensure the last indicator is highted
            >
                <PassedOrders restoId={urlParams.restoId} navigation={navigation} />

                {categoriesWItems &&
                    categoriesWItems.map(({ name, items }: Category, index: number) => (
                        <View key={index} onLayout={cacheBodyLayout(index)}>
                            <View style={{ backgroundColor: 'white' }}>
                                <Text style={styles.title1}>{name}</Text>
                            </View>
                            {items.map((item: Item, i: number) => (
                                <Pressable key={item.id} onPress={() => handleItemClick(item)} disabled={item.displayStatus === DisplayStatusEnum.INDISPONIBLE}>
                                    <View style={[styles.container, item.displayStatus === DisplayStatusEnum.INDISPONIBLE ? { opacity: 0.6 } : { opacity: 1 }]}>
                                        <LazyLoadImage alt={imgUrlsMap.get(item.id)?.imgUrl} style={styles.image} src={imgUrlsMap.get(item.id)?.imgUrl ? imgUrlsMap.get(item.id)?.imgUrl : imageBlanche} />
                                        {itemsWQuantity[item.id] && <Avatar.Text style={styles.avatar} size={24} label={itemsWQuantity[item.id].quantity.toString()} />}

                                        <View style={styles.placeInfoContainer}>
                                            <View style={styles.placeInfo}>
                                                <View style={[styles.titleContainer]}>
                                                    <Text style={styles.placeTitle}>{item.name}</Text>
                                                </View>
                                                <Text style={styles.placeSubTitle}>{item.abbreviatedDescription ? item.abbreviatedDescription : item.description}</Text>
                                                <View style={styles.priceLabelContainer}>
                                                    <Text style={styles.placePrice}>{item.price} €</Text>
                                                    {item.displayStatus === DisplayStatusEnum.INDISPONIBLE ? (
                                                        <UnavailableLabel title="Indisponible" />
                                                    ) : (
                                                        item.labelsIds && item.labelsIds.map((labelId, idx) => (labels.get(labelId)?.name ? <Labels key={idx} title={labels.get(labelId)!.name} /> : null))
                                                    )}
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                    {i !== items.length - 1 && <Divider style={{ marginHorizontal: 12 }} />}
                                </Pressable>
                            ))}
                        </View>
                    ))}
                {/*<Footer /> */}
            </Animated.ScrollView>

            {cartState.items && cartState.items.length > 0 && (
                <TouchableOpacity style={styles.fabContainer} onPress={goToCartScreen}>
                    <Text style={styles.fabLabel}>Afficher le panier</Text>
                    {cartState.items.length && <Avatar.Text color="black" style={styles.cartAvatar} size={24} label={cartState.cartTotalQuantity.toString()} />}
                    {cartState.items.length && <Text style={styles.cartTotalPrice}>{cartState.cartTotalPrice.toFixed(2).toString()} € </Text>}
                </TouchableOpacity>
            )}
        </View>
    )
}
const width = Dimensions.get('window').width // get screen width
const height = Dimensions.get('window').height

const styles = StyleSheet.create({
    fabContainer: {
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 18,
        bottom: 16,
        elevation: 5,
        position: 'absolute',
        backgroundColor: '#111',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '93%',
        borderRadius: 7
    },
    fabLabel: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },
    cartTotalPrice: {
        position: 'absolute',
        right: 20,
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },

    cartAvatar: {
        position: 'absolute',
        left: 20,
        fontSize: 18,
        fontWeight: 'bold',
        backgroundColor: 'white'
    },
    backgroundGradient: {
        ...StyleSheet.absoluteFillObject
    },
    absoluteContainer: {
        position: 'absolute',
        left: 25,
        top: '100%',
        marginTop: -1.9,
        height: 8,
        overflow: 'hidden'
    },
    gradientContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: -1,
        height: 2,
        width: 336
    },
    gradientBar1: {
        flex: 1,
        flexBasis: 0,
        backgroundColor: 'rgba(56,189,248,0)',
        backgroundImage: 'linear-gradient(90deg, rgba(56,189,248,0) 0%, #0EA5E9 32.29%, rgba(236,72,153,0.3) 67.19%, rgba(236,72,153,0) 100%)',
        overflow: 'hidden'
    },
    gradientBar2: {
        flex: 1,
        flexBasis: 0,
        marginLeft: '-100%',
        backgroundColor: 'rgba(56,189,248,0)',
        backgroundImage: 'linear-gradient(90deg, rgba(56,189,248,0) 0%, #0EA5E9 32.29%, rgba(236,72,153,0.3) 67.19%, rgba(236,72,153,0) 100%)',
        overflow: 'hidden'
    },
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 0,
        paddingTop: 20,
        paddingBottom: 10,
        backgroundColor: 'white'
    },
    bgImage: {},
    priceLabelContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    fabStyle: {
        bottom: 16,
        elevation: 5,
        position: 'absolute',
        backgroundColor: '#7437F3',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '95%',
        borderRadius: 7
    },
    avatar: {
        position: 'absolute',
        left: 90,
        top: 20,
        backgroundColor: '#7437F3'
    },

    containerImageLeft: {
        flexDirection: 'row',
        padding: 10,
        paddingTop: 15,
        paddingBottom: 15,
        backgroundColor: 'white'
    },
    top: {
        padding: padding,
        backgroundColor: 'transparent'
    },
    headingText: {
        color: '#000',
        fontFamily: 'Aeonik-regular',
        fontWeight: '700',
        padding: padding / 2
    },
    indicator: {
        position: 'absolute',
        backgroundColor: '#FFF',
        opacity: 1,
        height: '100%',
        borderRadius: 10
    },
    animatedText: {
        fontFamily: 'nothing-you-could-do',
        color: '#000',
        paddingVertical: padding,
        alignSelf: 'flex-start'
    },
    placeholder: {
        height: 20,
        marginBottom: 20
    },
    text: {
        color: '#1a1a1a',
        fontFamily: 'Inter-italic',
        fontSize: 1
    },
    title1: {
        color: '#111827',
        fontFamily: 'Aeonik-bold',
        fontSize: 20,
        fontWeight: '100',
        paddingLeft: 10,
        paddingTop: 10
    },

    divider: {
        height: 2,
        backgroundColor: '#FFFFFF'
    },
    info: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8
    },
    ratings: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        marginRight: 8
    },
    link: {
        color: '#247A00'
    },
    item: {
        borderBottomColor: '#FFF',
        borderBottomWidth: 1,
        marginTop: 16
    },
    title: {
        fontFamily: 'Inter-roman',
        fontSize: 16,
        marginBottom: 8
    },
    description: {
        marginBottom: 8
    },
    price: {
        fontFamily: 'Inter-roman',
        marginBottom: 16
    },
    // container: {
    //     paddingTop: 50
    // },
    tinyLogo: {
        width: 50,
        height: 50
    },
    logo: {
        width: 66,
        height: 58,
        borderRadius: 5
    },
    image: {
        width: '20%',
        minHeight: 100,
        minWidth: 100,
        aspectRatio: 1,
        borderRadius: 10,
        margin: 10
    },
    placeInfoContainer: {
        flex: 1,
        paddingLeft: 10
    },
    placeInfo: {
        marginTop: 0,
        paddingRight: 10
    },
    placeTitle: {
        color: '#111827',
        fontFamily: 'Aeonik-regular',
        fontSize: 19,
        textAlign: 'left'
    },
    titleContainer: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    placeSubTitle: {
        fontFamily: 'Aeonik-regular',
        fontSize: 13,
        marginTop: 2,
        color: '#6b7280',
        textAlign: 'left',
        marginRight: 50,
        marginBottom: 10
    },
    placePrice: {
        fontFamily: 'Aeonik-bold',
        color: '#1a1a1a',
        fontWeight: '500',
        fontSize: 16,
        textAlign: 'left'
    },

    circlesContainer: {
        height: 30,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    halfCircle: {
        width: 50,
        height: 50,
        borderBottomLeftRadius: 50,
        margin: -20,
        borderBottomRightRadius: 50,
        transform: [{ scaleX: width / 50 }]
    },
    halfCircleClosed: {
        width: 220,
        height: 90,
        borderBottomLeftRadius: 50,
        margin: 105,
        borderBottomRightRadius: 50,
        transform: [{ scaleX: width / 50 }]
    },
    thirdCircle: {
        width: 100,
        height: 220,
        borderBottomLeftRadius: 90,
        margin: -35,
        borderBottomRightRadius: 50,
        transform: [{ scaleX: width / 50 }]
    },
    forthCircle: {
        width: 100,
        height: 220,
        borderBottomLeftRadius: 90,
        margin: -75,
        borderBottomRightRadius: 50,
        transform: [{ scaleX: width / 50 }]
    },
    background: {
        position: 'absolute',
        left: -(width + height) / 1.5,
        right: -(width + height) / 2,
        top: -(width + height) / 2,
        bottom: -(width + height) / 2,
        width: width + height,
        height: width + height,
        transform: [{ rotate: '60deg' }]
    }
})
