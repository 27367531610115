import Svg, { Circle, Path } from 'react-native-svg'

export const SesameSvg = (
    <Svg viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="48.892" cy="48.339" r="16.033" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M38.721 15.973a6.81 6.81 0 0 0-2.368-.443c-3.579 0-5.823 2.995-7.164 4.784l-.239.318c-.305.401-.583.783-.865 1.17-.95 1.304-1.934 2.652-4.165 5.014a35.75 35.75 0 0 1-1.217 1.219c-2.72 2.873-5.391 7.307-6.999 11.641l-.339.913a15.587 15.587 0 0 0-.595 2.061c-1.552 6.936 1.905 13.885 8.041 16.163 1.381.513 2.829.772 4.303.772h.001c5.047 0 9.775-2.977 12.339-7.768.373-.696.666-1.335.89-1.94l.37-.992c1.584-4.269 2.454-9.372 2.264-13.396a33.27 33.27 0 0 1-.121-1.637c-.149-3.25-.014-4.915.117-6.525.039-.476.077-.945.108-1.446l.01-.152c.148-2.379.498-7.949-4.371-9.756zm1.376 9.566-.01.155c-.03.48-.067.932-.104 1.388-.139 1.704-.282 3.466-.125 6.911.03.599.076 1.195.128 1.712.167 3.569-.632 8.22-2.083 12.131l-.368.985a12.71 12.71 0 0 1-.726 1.579c-2.01 3.757-5.815 6.185-9.695 6.184a9.31 9.31 0 0 1-3.258-.585c-4.736-1.759-7.385-7.217-6.158-12.696.145-.646.297-1.178.479-1.668l.365-.985c1.451-3.91 3.881-7.954 6.285-10.497.433-.413.856-.837 1.271-1.275 2.364-2.501 3.405-3.928 4.411-5.309.271-.371.537-.737.83-1.123l.251-.333c1.068-1.427 2.684-3.583 4.763-3.583.421 0 .866.086 1.323.256 2.566.951 2.602 3.859 2.421 6.753z"></Path>
        <Path
            fill="#052049"
            d="M35.339 26.104a1.502 1.502 0 0 0-2.015.663c-1.379 2.734-2.986 6.521-4.525 10.664-1.556 4.189-2.817 8.138-3.553 11.119a1.499 1.499 0 1 0 2.912.718c.699-2.833 1.957-6.767 3.452-10.793 1.503-4.042 3.063-7.72 4.393-10.357a1.5 1.5 0 0 0-.664-2.014zm48.855-6.263c-1.324-1.665-3.071-2.509-5.192-2.509-2.004 0-3.934.781-5.211 1.298l-.371.149c-.467.186-.902.368-1.342.554-1.488.625-3.027 1.271-6.157 2.14a35.05 35.05 0 0 1-1.674.416c-3.865 1.068-8.333 3.425-11.954 6.306l-.814.649c-.515.41-1.029.886-1.575 1.456-4.911 5.137-5.542 12.873-1.468 17.994 2.406 3.024 6.116 4.758 10.179 4.758 2.666 0 5.323-.742 7.685-2.146.68-.404 1.261-.8 1.772-1.208l.821-.652c3.563-2.834 6.944-6.754 8.862-10.302.234-.492.481-.98.741-1.461 1.552-2.86 2.527-4.216 3.472-5.526.278-.388.555-.771.839-1.183l.084-.121c1.359-1.959 4.541-6.546 1.303-10.612zm-3.768 8.902-.086.125c-.274.396-.539.764-.807 1.136-.999 1.387-2.031 2.82-3.676 5.852a30.06 30.06 0 0 0-.776 1.532c-1.703 3.144-4.79 6.712-8.054 9.309l-.824.654c-.411.328-.867.638-1.438.977-1.897 1.129-4.025 1.725-6.151 1.725-3.144 0-5.997-1.321-7.831-3.625-3.146-3.954-2.592-9.995 1.289-14.054a12.57 12.57 0 0 1 1.279-1.186l.813-.648c3.268-2.6 7.438-4.807 10.805-5.74a34.603 34.603 0 0 0 1.749-.435c3.318-.92 4.947-1.604 6.521-2.266.423-.178.841-.354 1.289-.532l.387-.155c1.125-.456 2.666-1.079 4.086-1.079 1.193 0 2.098.438 2.845 1.377 1.707 2.141.234 4.648-1.42 7.033z"></Path>
        <Path fill="#052049" d="M73.996 26.294c-2.596 1.629-5.93 4.04-9.387 6.79-3.502 2.789-6.623 5.518-8.787 7.686a1.5 1.5 0 0 0 2.124 2.119c2.057-2.059 5.166-4.777 8.532-7.457 3.324-2.644 6.646-5.048 9.112-6.597a1.5 1.5 0 1 0-1.594-2.541z"></Path>
        <g fill="#052049">
            <Path
                d="M76.789 60.245c-1.098-2.984-3.648-4.497-7.585-4.497-.693 0-1.35.042-1.917.079l-.392.025c-.503.029-.976.065-1.454.103-1.068.083-2.174.17-3.738.17-.849 0-1.757-.024-2.774-.075a34.927 34.927 0 0 1-1.719-.133c-3.995-.205-9.069.66-13.328 2.227l-.332.122c-.276.102-.599.22-.646.239-.618.226-1.259.517-1.955.888-6.278 3.33-9.31 10.474-7.052 16.617 1.889 5.14 6.918 8.461 12.813 8.461h.001c1.108 0 2.227-.122 3.321-.363.785-.173 1.441-.36 2.064-.59l.982-.36c4.271-1.568 8.715-4.226 11.654-6.992a32.33 32.33 0 0 1 1.161-1.152c2.369-2.225 3.721-3.203 5.027-4.149.39-.281.773-.56 1.176-.863l.125-.095c1.904-1.433 6.36-4.785 4.568-9.662zm-6.371 7.263-.128.097a69.21 69.21 0 0 1-1.128.829c-1.383 1.001-2.813 2.037-5.324 4.395-.438.412-.863.833-1.219 1.209-2.605 2.45-6.658 4.865-10.576 6.305l-.983.36c-.493.182-1.024.332-1.673.476-.884.194-1.784.293-2.677.293-4.624 0-8.548-2.55-9.997-6.496-1.744-4.742.682-10.302 5.643-12.933.584-.311 1.086-.54 1.607-.731l.619-.229.338-.123c3.526-1.297 7.675-2.071 11.097-2.071.354 0 .703.009.965.02.594.062 1.193.108 1.797.139 1.069.053 2.026.079 2.925.079 1.681 0 2.846-.091 3.973-.179.458-.036.911-.071 1.396-.1l.41-.025c.51-.034 1.099-.073 1.722-.073 2.69 0 4.116.757 4.769 2.532.943 2.568-1.24 4.484-3.556 6.226z"
                fill="#000000"></Path>
            <Path
                d="M65.079 63.165c-2.968.727-6.89 1.968-11.046 3.494-4.193 1.542-8.014 3.151-10.758 4.531a1.5 1.5 0 1 0 1.349 2.68c2.606-1.311 6.414-2.913 10.444-4.395 4.054-1.489 7.862-2.695 10.724-3.396a1.5 1.5 0 1 0-.713-2.914z"
                fill="#000000"></Path>
        </g>
    </Svg>
)
