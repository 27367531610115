import Svg, { Circle, Path } from 'react-native-svg'

export const NutSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="54.587" cy="41.581" r="13.101" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M33.566,38.693c-0.648-0.517-1.592-0.41-2.109,0.237c-0.852,1.067-1.992,2.511-2.6,3.324
			c-1.078,1.447-4.027,5.752-4.145,5.922c-3.848,5.501-4.375,12.216-1.525,19.417c0.234,0.59,0.799,0.948,1.395,0.948
			c0.186,0,0.371-0.033,0.553-0.105c0.771-0.305,1.148-1.177,0.844-1.946c-1.719-4.338-2.904-10.736,1.201-16.605
			c0.031-0.044,3.041-4.438,4.084-5.837c0.461-0.618,1.338-1.741,2.539-3.246C34.32,40.153,34.213,39.21,33.566,38.693z"></Path>
        <Path
            fill="#052049"
            d="M75.438,29.422c-0.01-0.009-0.02-0.018-0.029-0.025c-1.764-1.607-2.527-4.033-2.023-6.365
			c1.213-1.739,3.146-4.512,3.969-5.687c1.463-2.093-1.258-4-2.568-4.917c-1.313-0.917-4.043-2.827-5.508-0.734l-3.992,5.705
			c-1.061,0.66-2.275,1.019-3.533,1.019c-1.027,0-2.059-0.241-2.908-0.66c-12.822-7.268-21.99-7.455-26.643-0.806
			c-0.373,0.533-3.252,4.965-3.688,6.728c-0.164,0.67,0.148,1.365,0.758,1.687c0.258,0.136,0.529,0.283,0.799,0.428
			c-2.121,2.64-4.957,6.194-6.205,7.866c-1.584,2.126-5.93,8.47-6.105,8.726c-7.105,10.155-6.227,23.551,2.412,36.753
			c0.26,0.396,0.439,1.169,0.266,1.417l-0.168,0.238c-1.697,2.43-1.104,5.788,1.324,7.487c0.936,0.655,2.01,0.97,3.074,0.97
			c1.697,0,3.369-0.801,4.416-2.296l0.164-0.234c0.172-0.243,0.943-0.354,1.371-0.261c9.098,2.051,25.793,3.435,35.396-10.29
			c0.189-0.266,4.66-6.522,6.115-8.738c1.135-1.731,3.506-5.62,5.264-8.524c0.229,0.203,0.459,0.408,0.676,0.604
			c0.283,0.257,0.645,0.389,1.008,0.389c0.291,0,0.582-0.084,0.836-0.255c1.52-1.021,4.686-5.238,5.057-5.771
			C88.205,49.253,89.17,41.261,75.438,29.422z M71.412,13.876c0.289,0.13,0.795,0.41,1.654,1.01c0.85,0.596,1.285,0.974,1.506,1.2
			c-0.766,1.096-2.01,2.879-3.025,4.336l-3.166-2.215L71.412,13.876z M69.623,65.785c-1.422,2.166-6.002,8.575-6.057,8.651
			c-8.609,12.303-23.926,10.979-32.291,9.096c-1.201-0.27-3.387-0.102-4.482,1.463l-0.17,0.243c-0.75,1.074-2.236,1.334-3.309,0.585
			c-1.074-0.752-1.336-2.237-0.592-3.304l0.174-0.245c1.191-1.704,0.381-3.872-0.213-4.78c-7.934-12.121-8.832-24.291-2.457-33.401
			c0.045-0.065,4.496-6.563,6.045-8.641c1.307-1.752,4.525-5.776,6.459-8.179c5.969,3.416,13.793,8.451,21.744,14.015
			c7.953,5.565,15.363,11.191,20.617,15.627C73.328,59.832,70.789,64.004,69.623,65.785z M82.514,52.154
			c-0.684,0.979-2.348,3.055-3.447,4.225c-5.352-4.716-13.773-11.182-22.871-17.548C47.1,32.466,38.139,26.769,31.873,23.355
			c0.723-1.431,2.102-3.705,2.787-4.686c1.68-2.4,4.086-3.566,7.357-3.566c4.021,0,9.186,1.771,15.42,5.302
			c2.732,1.355,6.086,1.317,8.76-0.065l4.105,2.874c-0.395,3.087,0.715,6.201,3.021,8.342c0.031,0.03,0.063,0.06,0.096,0.089
			C83.131,39.997,86.191,46.897,82.514,52.154z"></Path>
        <Path
            fill="#052049"
            d="M60.477 33.284c-.355-.259-.891-.616-1.424-.852-.758-.333-1.643.008-1.979.766-.334.757.008 1.644.766 1.979.221.097.545.297.865.528.475.35.707.602.766.67.297.342.715.517 1.135.517.348 0 .697-.121.982-.367.625-.543.691-1.491.148-2.116C61.428 34.052 60.994 33.665 60.477 33.284zM73.793 44.901c-.334.759.012 1.644.77 1.977.082.035.385.179.859.526.48.353.709.602.77.67.295.342.713.517 1.133.517.348 0 .699-.121.982-.367.625-.543.691-1.491.148-2.116-.389-.447-.896-.855-1.258-1.122-.357-.261-.895-.62-1.43-.855C75.01 43.798 74.125 44.143 73.793 44.901zM43.758 21.587c-.355-.26-.893-.619-1.432-.854-.758-.334-1.643.014-1.975.773-.332.759.014 1.644.773 1.975.082.036.387.18.859.526.477.349.707.6.766.668.297.342.715.518 1.135.518.348 0 .697-.12.98-.366.627-.543.695-1.49.152-2.116C44.707 22.353 44.273 21.965 43.758 21.587zM73.371 35.576c-.311-.358-.744-.745-1.258-1.121-.355-.261-.895-.62-1.43-.855-.758-.333-1.643.013-1.977.771-.332.759.014 1.644.771 1.977.082.035.387.18.863.528.473.347.705.599.766.667.295.342.713.517 1.133.517.348 0 .699-.121.982-.367C73.848 37.149 73.914 36.201 73.371 35.576zM56.656 23.883c-.391-.452-.9-.862-1.266-1.129-.361-.264-.904-.626-1.428-.854-.76-.33-1.645.016-1.975.775-.332.76.016 1.644.775 1.975.078.034.371.174.857.527.477.349.703.596.762.663.297.345.715.521 1.137.521.348 0 .695-.119.979-.363C57.125 25.457 57.195 24.51 56.656 23.883z"></Path>
    </Svg>
)
