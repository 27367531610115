import { useEffect, useState } from 'react'
import { View, Text, StyleSheet, ScrollView, Dimensions, Pressable } from 'react-native'
import { Card } from 'react-native-paper'
import { CartState } from '../types'
import PassedOrderService from '../services/PassedOrderService'
import tw from 'twrnc'
import { isMobile } from 'react-device-detect'

const PassedOrders = ({ restoId, navigation }: { restoId: string; navigation: any }) => {
    const width = Dimensions.get('window').width // get screen width
    const passedOrders = localStorage.getItem('passedOrders')
    const [orders, setOrders] = useState<CartState[]>([])
    const [passedOrderToRemove, setPassedOrderToRemove] = useState<string[]>([])

    useEffect(() => {
        const fetchPassedOrder = async () => {
            if (passedOrders) {
                const passedOrdersFound: string[] = JSON.parse(passedOrders)
                passedOrdersFound.forEach(async (orderid) => {
                    const cart: CartState | undefined = await PassedOrderService.fetchPendingOrderForLastest4Hours(restoId, orderid)
                    if (cart) {
                        setOrders((prev) => [...prev, cart])
                    } else {
                        setPassedOrderToRemove((prev) => [...prev, orderid])
                    }
                })
            }
        }
        fetchPassedOrder()
    }, [])

    useEffect(() => {
        console.log('The orderid should be removed from the LocalStorage / More than 4 hours ', passedOrderToRemove)
        if (passedOrders) {
            const passedOrdersFound: string[] = JSON.parse(passedOrders)

            const filteredArray = passedOrdersFound.filter((item) => !passedOrderToRemove.includes(item))

            if (passedOrders != passedOrders) {
                const passedOrdersJSON = JSON.stringify(filteredArray)
                localStorage.setItem('passedOrders', passedOrdersJSON)
                console.log('The filtred array is  ', filteredArray)
            }
        }
    }, [passedOrderToRemove])

    const goToCartScreen = (cart: CartState) => {
        navigation.navigate('OrderScreen', { passedCart: cart, restoId })
    }

    const labels = ['Envoyée', 'En cuisine', 'Terminée']
    const cartStatusMapping = {
        PENDING: 0,
        IN_PROGRESS: 1,
        COMPLETED: 2,
        CANCELED: 3
    }

    const sortPassedOrdersByDate = (): ((a: CartState, b: CartState) => number) | undefined => {
        return (a, b) => {
            const timestampA = a.createdAt.seconds
            const timestampB = b.createdAt.seconds
            return timestampB - timestampA
        }
    }

    return (
        <>
            {orders.length > 0 && <Text style={{ paddingLeft: 15, paddingTop: 10, textAlign: 'left', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Historique des commandes : </Text>}
            <ScrollView centerContent horizontal showsHorizontalScrollIndicator={false}>
                {orders.length > 0 &&
                    orders.sort(sortPassedOrdersByDate()).map((cart: CartState, i: number) => (
                        <Pressable key={i} style={[tw`relative text-center`]} onPress={() => goToCartScreen(cart)}>
                            <Card.Cover
                                key={i}
                                style={[{ margin: 15, minWidth: (isMobile ? width : 375) - 100, height: 100, boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px' }]}
                                source={require('../assets/images/29.jpg')}
                            />
                            <View style={{ flexDirection: 'row', position: 'absolute', padding: 30 }}>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontWeight: '500', fontFamily: 'Aeonik-regular' }}> Commande N° : </Text>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontFamily: 'Aeonik-regular' }}>{cart.invoiceNumber}</Text>
                            </View>

                            <View style={{ flexDirection: 'row', position: 'absolute', padding: 30, paddingTop: 50 }}>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontWeight: '500', fontFamily: 'Aeonik-regular' }}> Commande passé à : </Text>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontFamily: 'Aeonik-regular' }}>{cart.orderDate.split('à')[1].substring(0, 6).trim()}</Text>
                            </View>

                            <View style={{ flexDirection: 'row', position: 'absolute', padding: 30, paddingTop: 70 }}>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontWeight: '500', fontFamily: 'Aeonik-regular' }}> État : </Text>
                                <Text style={{ textAlign: 'left', fontSize: 14, color: 'black', fontFamily: 'Aeonik-regular' }}>{labels[cartStatusMapping[cart.cartStatus]]}</Text>
                            </View>
                        </Pressable>
                    ))}
            </ScrollView>
        </>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        paddingTop: 30,
        backgroundColor: '#000',
        padding: 8
    }
})
export default PassedOrders
