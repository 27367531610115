import Svg, { Circle, Path } from 'react-native-svg'

export const SulfateSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="56.276" cy="70.336" r="13.033" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M75.366,25.401c-0.001-0.021-0.004-0.046-0.006-0.067h0.737c0.828,0,1.5-0.672,1.5-1.5s-0.672-1.5-1.5-1.5
			h-1.3c-0.135-0.467-0.282-0.906-0.437-1.27c-0.007-0.018-0.714-1.755-0.714-4.363c0-1.07,0.092-2.204,0.093-2.216
			c0.077-0.939-0.237-1.867-0.863-2.547c-0.625-0.68-1.525-1.069-2.468-1.069H29.594c-0.942,0-1.842,0.39-2.468,1.069
			s-0.94,1.607-0.863,2.547c0.001,0.012,0.093,1.141,0.093,2.216c0,2.611-0.709,4.353-0.714,4.363
			c-0.155,0.364-0.303,0.804-0.438,1.27h-0.46c-0.829,0-1.5,0.672-1.5,1.5c0,0.793,0.617,1.436,1.396,1.489
			c-0.002,0.027-0.005,0.059-0.007,0.085l-3.267,60.145c-0.051,0.946,0.276,1.843,0.92,2.523c0.645,0.68,1.522,1.055,2.47,1.055
			h50.487c0.948,0,1.825-0.375,2.47-1.055c0.645-0.681,0.971-1.577,0.92-2.523L75.366,25.401z M72.37,25.563l1.485,27.333
			c-2.884-0.192-4.516-0.977-6.227-1.804c-2.085-1.008-4.448-2.149-8.931-2.149s-6.846,1.142-8.931,2.149
			c-1.97,0.952-3.83,1.851-7.625,1.851s-5.655-0.898-7.624-1.851c-1.958-0.946-4.186-1.999-8.16-2.124l1.271-23.398
			c0.004-0.073,0.013-0.156,0.021-0.236h44.701C72.358,25.412,72.366,25.492,72.37,25.563z M28.403,22.24
			c0.039-0.092,0.954-2.278,0.954-5.539c0-1.197-0.099-2.411-0.103-2.462c-0.012-0.138,0.037-0.222,0.08-0.269
			c0.042-0.046,0.123-0.102,0.261-0.102h40.815c0.138,0,0.218,0.056,0.261,0.102c0.042,0.047,0.091,0.131,0.08,0.269
			c-0.004,0.051-0.104,1.265-0.104,2.462c0,3.256,0.911,5.438,0.95,5.53c0.013,0.031,0.026,0.069,0.04,0.103H28.366
			C28.378,22.304,28.39,22.269,28.403,22.24z M75.535,86.013c-0.051,0.054-0.143,0.118-0.292,0.118H24.756
			c-0.148,0-0.241-0.064-0.292-0.118c-0.051-0.054-0.11-0.149-0.102-0.298l1.833-33.753c3.378,0.094,5.159,0.935,7.017,1.832
			c2.085,1.008,4.447,2.149,8.93,2.149s6.845-1.142,8.93-2.149c1.97-0.951,3.83-1.851,7.626-1.851s5.656,0.899,7.626,1.851
			c1.882,0.909,4.006,1.92,7.694,2.109l1.619,29.812C75.646,85.863,75.586,85.959,75.535,86.013z"></Path>
        <Circle cx="63.254" cy="77.674" r="1.5" fill="#052049"></Circle>
        <Circle cx="54.134" cy="62.341" r="1.5" fill="#052049"></Circle>
        <Circle cx="49.924" cy="72.686" r="1.5" fill="#052049"></Circle>
        <Circle cx="37.587" cy="77.174" r="1.5" fill="#052049"></Circle>
    </Svg>
)
