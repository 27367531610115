import { ScrollView, Text, StyleSheet, TouchableOpacity, View, ActivityIndicator, Dimensions } from 'react-native'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useEffect, useState } from 'react'
import OrderService from '../services/OrderService'
import { HelperText, RadioButton } from 'react-native-paper'
import { useTheme } from '@react-navigation/native'
import { CartState, PaymentMethod, UrlParams } from '../types'
import { useFetchUsersQuery } from '../features/users/userSlice'
import StripeFunctions from '../functions/stripeFunctions'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { CheckoutForm } from '../components/stripe/CheckoutForm'
import StripeSkeleton from '../components/skeleton/StripeSkeleton'
import { useSharedValue, withTiming } from 'react-native-reanimated'
import SwitchSelector from 'react-native-switch-selector'
import { useAppDispatch } from '../app/hooks'
import { cartActions } from '../features/cart/cartSlice'
import { LinearGradient } from 'expo-linear-gradient'

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const CheckoutScreen = ({ route, navigation }: any) => {
    const urlParams: UrlParams = route.params
    const progress = useSharedValue(0)
    const dispatch = useAppDispatch()

    const cart = useSelector((state: RootState) => state.cart)
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod>(PaymentMethod.Cache)
    const { data: usersFromStore, isFetching: usersStoreFetching } = useFetchUsersQuery(urlParams.restoId)
    const restoId = useSelector((state: RootState) => state.resto).restoId
    const tableId = useSelector((state: RootState) => state.resto).tableId
    const [stripePromise, setStripePromise] = useState<any>(null)
    const [loading, setLoading] = useState(true)
    const [initRender, setInitRender] = useState(true)

    const [cardPaymentErrorMsg, setCardPaymentErrorMsg] = useState<string>('')
    const [isProcessingCbPayment, setIsProcessingCbPayment] = useState<boolean>(false)
    const [isProcessingNoPayment, setIsProcessingNoPayment] = useState<boolean>(false)

    const [callPayment, setCallPayment] = useState<boolean>(false)
    const [stripeAmount, setStripeAmount] = useState<number>(0)

    const { colors } = useTheme()

    useEffect(() => {
        console.log('urlParams.restoId', urlParams.restoId)
        const stripeAmountToPay = Math.round(cart.cartTotalPrice * 100)
        setStripeAmount(stripeAmountToPay)
        const setStripe = async () => {
            //const clientSecret = await StripeFunctions.fetchPaymentIntent(stripeAmount, 'EUR', usersFromStore?.stripeAccountId)
            //  setClientSecret(clientSecret)

            const publishableKey = await StripeFunctions.fetchPublishableKey()
            const stripePromise = loadStripe(publishableKey, {
                stripeAccount: usersFromStore?.stripeAccountId
            })
            setStripePromise(stripePromise)
            console.log('stripeAmount', stripeAmountToPay)
            setLoading(false)
        }

        usersFromStore && usersFromStore?.stripeAccountId && setStripe()
    }, [usersStoreFetching])

    useEffect(() => {
        if (initRender) {
            setInitRender(false)
        }

        if (!initRender && isProcessingCbPayment === false && cardPaymentErrorMsg === '') {
            console.log('payment finished with success')
            createOrderAndGoToNextScreen()
        } else if (isProcessingCbPayment === false && cardPaymentErrorMsg != '') {
            localStorage.removeItem('createdOrderId')
            localStorage.removeItem('cart')
            console.error(cardPaymentErrorMsg)
        }
    }, [isProcessingCbPayment])

    useEffect(() => {
        dispatch(cartActions.updateCartSelectedMethode({ selectedMethod: selectedMethod }))
    }, [selectedMethod])

    const createNewOrder = async () => {
        if (selectedMethod === PaymentMethod.CreditCard) {
            setCallPayment((prev) => !prev)
        } else {
            createOrderAndGoToNextScreen()
            setIsProcessingNoPayment(true)
        }
    }

    const createOrderAndGoToNextScreen = async () => {
        const invoiceNumber = await OrderService.generateInvoiceNumberWithFormat(restoId)

        const currentTime = Date.now()

        // Create a French locale formatter
        const formatter = new Intl.DateTimeFormat('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: false // Use 24-hour format
        })

        const orderDate = formatter.format(currentTime)

        dispatch(cartActions.updateCartOrderInfo({ tableId, restoId, orderDate, invoiceNumber }))
        const submitedCart = { ...cart, tableId, restoId, orderDate, invoiceNumber } as CartState
        try {
            const createdOrderId = await OrderService.createNewOrder(submitedCart)
            createdOrderId && localStorage.setItem('createdOrderId', createdOrderId)
            console.log('cart with no payment', submitedCart)
            const cartStateJSON = JSON.stringify(submitedCart)
            localStorage.setItem('cart', cartStateJSON)
            navigation.navigate('OrderScreen', { restoId })
        } catch (error) {
            window.alert('erreur lors du passage de votre commande !')
            navigation.navigate('HomeScreen', { restoId })
        }
    }

    const options = {
        mode: 'payment',
        amount: stripeAmount,
        paymentMethodCreation: 'manual',
        currency: 'eur'
    } as any

    return (
        <LinearGradient colors={['#4f46e523', '#FFFFFF']} style={styles.container} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 80 }}>
                <View style={{ padding: 15, marginTop: 20 }}>
                    <SwitchSelector
                        options={[
                            { label: 'Sur place', value: false },
                            { label: 'À emporter', value: true }
                        ]}
                        textColor={'black'}
                        selectedColor={colors.primary}
                        borderRadius={5}
                        height={50}
                        buttonColor={`${colors.primary}10`}
                        borderColor={`${colors.primary}10`}
                        hasPadding
                        bold
                        initial={0}
                        onPress={(value: boolean) => dispatch(cartActions.updateCartIsToTakeAway({ isTakeAway: value }))}
                    />
                </View>
                <Text style={styles.itemNames} onPress={() => (progress.value = withTiming(1 - progress.value, { duration: 1000 }))}>
                    Choisissez un mode de paiement :
                </Text>
                {/* Paiement par especes*/}
                <TouchableOpacity onPress={() => setSelectedMethod(PaymentMethod.Cache)} style={[styles.optionContainer, { backgroundColor: selectedMethod === PaymentMethod.Cache ? `${colors.primary}10` : 'transparent' }]}>
                    <RadioButton
                        color={selectedMethod === PaymentMethod.Cache ? colors.primary : 'transparent'}
                        value={'paiement en especes'}
                        status={selectedMethod === PaymentMethod.Cache ? 'checked' : 'unchecked'}
                        onPress={() => setSelectedMethod(PaymentMethod.Cache)}
                    />
                    <Text style={{ marginLeft: 15, fontSize: 16, fontWeight: '600', color: selectedMethod === PaymentMethod.Cache ? colors.primary : 'black' }}>Paiement en espèces</Text>
                </TouchableOpacity>
                {/* Paiement par carte*/}
                {usersFromStore?.stripeAccountId && (
                    <TouchableOpacity onPress={() => setSelectedMethod(PaymentMethod.CreditCard)} style={[styles.optionContainer, { backgroundColor: selectedMethod === PaymentMethod.CreditCard ? `${colors.primary}10` : 'transparent' }]}>
                        <RadioButton
                            color={selectedMethod === PaymentMethod.CreditCard ? colors.primary : 'transparent'}
                            value={'paiement par carte'}
                            status={selectedMethod === PaymentMethod.CreditCard ? 'checked' : 'unchecked'}
                            onPress={() => setSelectedMethod(PaymentMethod.CreditCard)}
                        />
                        <Text style={{ marginLeft: 15, fontSize: 16, fontWeight: '600', color: selectedMethod === PaymentMethod.CreditCard ? colors.primary : 'black' }}>Paiement en carte bancaire</Text>
                    </TouchableOpacity>
                )}

                <View style={{ padding: 15, marginTop: 30, display: selectedMethod === PaymentMethod.CreditCard ? 'flex' : 'none' }}>
                    {loading ? (
                        <>
                            <ActivityIndicator size="small" color="#FFFFFF" /> <StripeSkeleton />
                        </>
                    ) : (
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm callPayment={callPayment} setCardPaymentErrorMsg={setCardPaymentErrorMsg} setIsProcessingCbPayment={setIsProcessingCbPayment} stripeAccountId={usersFromStore?.stripeAccountId} stripeAmount={stripeAmount} />
                        </Elements>
                    )}

                    <HelperText type="error" visible={cardPaymentErrorMsg}>
                        {cardPaymentErrorMsg}
                    </HelperText>
                </View>
            </ScrollView>
            <View style={styles.cardContainer}>
                {isProcessingCbPayment ? (
                    <View style={[styles.addToCartButton, { backgroundColor: '#111' }]}>
                        <Text style={styles.addToCartButtonText}>
                            {'Payment en cours...   '} <ActivityIndicator size="small" color="#FFFFFF" />
                        </Text>
                    </View>
                ) : isProcessingNoPayment ? (
                    <View style={[styles.addToCartButton, { backgroundColor: '#111' }]}>
                        <Text style={styles.addToCartButtonText}>
                            {'Payment en cours...   '} <ActivityIndicator size="small" color="#FFFFFF" />
                        </Text>
                    </View>
                ) : (
                    <TouchableOpacity disabled={isProcessingNoPayment} style={[styles.addToCartButton, { backgroundColor: '#111' }]} onPress={() => createNewOrder()}>
                        <Text style={styles.addToCartButtonText}>Payez et passez la commande</Text>
                    </TouchableOpacity>
                )}
            </View>
        </LinearGradient>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    optionContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 10,
        marginHorizontal: 10,
        borderRadius: 12
    },
    picker: {
        padding: 15,
        width: 200
    },
    addToCartButton: {
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 18,
        bottom: 16,
        elevation: 5,
        position: 'absolute',
        backgroundColor: '#111',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '93%',
        borderRadius: 7
    },
    addToCartButtonText: {
        color: 'white',
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    closeIcon: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 20,
        right: 20,
        zIndex: 1,
        borderRadius: 20,
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
        backgroundColor: 'white'
    },
    checkoutTitle: {
        fontSize: 18,
        lineHeight: 32,
        paddingHorizontal: 15,
        paddingVertical: 20,
        textAlignVertical: 'auto',
        fontWeight: '700'
    },
    cardContainer: {
        paddingVertical: 15,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: -1
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 2
    },
    itemNames: {
        fontSize: 16,
        lineHeight: 32,
        paddingHorizontal: 20,
        paddingTop: 25,
        paddingBottom: 15,
        fontWeight: '500'
    },
    deliveryContainer: {
        paddingHorizontal: 20,
        paddingVertical: 10,
        marginTop: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: '#EEEEEE',
        borderRadius: 8,
        marginHorizontal: 20,
        alignItems: 'center'
    },
    deliveryOption: {
        flex: 1,
        alignItems: 'center',
        paddingVertical: 8
    },
    optionText: {
        fontSize: 16,
        fontWeight: '600',
        color: 'black'
    },
    paymentOption: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 20
    },
    background: {
        position: 'absolute',
        left: -(windowWidth + windowHeight) / 1.5,
        right: -(windowWidth + windowHeight) / 2,
        top: -(windowWidth + windowHeight) / 2,
        bottom: -(windowWidth + windowHeight) / 2,
        width: windowWidth + windowHeight,
        height: windowWidth + windowHeight,
        transform: [{ rotate: '60deg' }]
    }
})

export default CheckoutScreen
