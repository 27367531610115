import { FieldValue } from 'firebase/firestore/lite'
import { Float } from 'react-native/Libraries/Types/CodegenTypes'

export type Category = {
    id: string
    name: string
    description: string
    items: Item[]
    displayStatus: DisplayStatusEnum
}

export type Item = {
    id: string
    name: string
    description: string
    imgUrl: string
    price: string
    quantity: number
    modifiersIds: string[]
    labelsIds: string[]
    allergens: string[]
    abbreviatedDescription: string
    displayStatus: DisplayStatusEnum
}

export type ImgUrl = {
    imgUrl: string
    key: number
}

export type OptionOld = {
    modifierId: string
    enabled: boolean
    checked: boolean
    name: string
    price?: string
}

export type Option = {
    id: string
    enabled: boolean
    name: string
    price?: string
    numericPrice: number
}

export type Modifier = {
    id: string
    name: string
    description: string
    options: Option[]
    free: boolean
    abbreviatedDescription: string
    required: boolean
    multipleSelection: boolean
    maxSelect: number
}

export type Label = {
    id: string
    name: string
    description: string
    icon: string
}

export type MultiSelectId = {
    id: string
    label: string
}
export type SocialAccount = {
    facebook: string
    twitter: string
    instagram: string
    linkedIn: string
}

export type User = {
    uid: string
    firstName: string
    lastName: string
    facebook: string
    instagram: string
    email: string
    subscruptionType: SubscriptionType
    twitter: string
    closedRestaurant: boolean
    restaurantName: string
    phone: string
    stripeAccountId: string
    address: string
    tvaNumber: string
    codeAPE: string
    googleId: string
}

export type Table = {
    id: string
    name: string
}

export type CatNameWithAnchor = {
    name: string
    anchor: number
}

export type ChoiceModifierProps = {
    modifier: Modifier
    modifierIndex: number
    isWarning: boolean
    subscriptionType: SubscriptionType
    addOptionToItem: (option: Option, modifierId: string) => void
    removeOptionFromItem: (option: Option, modifierId: string) => void
    updateAddToCartState: (modifierIndex: number, modifierIsValid: boolean) => void
}

export type CartState = {
    invoiceNumber: string
    items: CartItem[] // a map with itemId and  a list of item. => we can have multiple items with the same id added to the cart. every one different from the other
    cartTotalQuantity: number
    cartTotalPrice: number // TODO => move this out of redux store
    cartStatus: CartStatusEnum
    tipTotalPrice: number
    cartComment: string
    selectedMethod: PaymentMethod
    takeAway: boolean
    orderDate: string
    tableId: string
    restoId: string
    createdAt: { seconds: any; nanoseconds: any }
}

export type CartItem = {
    id: string
    cartId: string
    name: string
    price: number // this price will get updated with the added options's price
    quantity: number
    itemTotalPrice: number // TODO => move this to screen OUT of redux store
    editEnabled: boolean // this may get removed
    modifiers?: CartModifier[]
}

export type CartModifier = {
    id: string
    options: CartOption[]
    price: number
    quantity: number
}

export type CartOption = {
    id: string
    name: string
    price: number
    quantity: number
}

export type UrlParams = {
    restoId: string
    tableId: string
    payment_intent_client_secret: string
    redirect_status: string
}

export enum CartStatusEnum {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    CANCELED = 'CANCELED'
}

export enum PaymentMethod {
    Cache = 'Espèces',
    CreditCard = 'Carte Bancaire'
}

export enum DisplayStatusEnum {
    AFFICHE = 'AFFICHE',
    INDISPONIBLE = 'INDISPONIBLE',
    NON_AFFICHE = 'NON_AFFICHE'
}

export enum SubscriptionType {
    BASIC = 'BASIC',
    STANDARD = 'STANDARD',
    PREMIUM = 'PREMIUM'
}
