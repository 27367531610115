import { CartState, CartStatusEnum } from '../types'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore/lite'
import { db, firestore } from '../firebase'
import { onSnapshot, doc } from 'firebase/firestore'
import axios from 'axios'
import bcrypt from 'bcryptjs-react'

class OrderService {
    saltRounds = 10

    createNewOrder = async (cart: CartState) => {
        console.log('adding a new order with the following cart : ', cart)
        let response = null
        try {
            response = await axios.get('https://api.ipify.org?format=json')
            console.log('response.data.ip', response.data.ip)
        } catch (e) {
            console.error('Error calling ipfy for client ip address: ', e)
            return null
        }
        const createdItem = await addDoc(collection(db, 'restaurants/' + cart.restoId + '/orders'), {
            ...cart,
            clientIp: response ? response.data.ip : 'null',
            createdAt: serverTimestamp()
        })
        return createdItem.id
    }

    generateInvoiceNumberWithFormat = async (restoId: string | null): Promise<string> => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 // Months are 0-indexed
        const day = currentDate.getDate()
        const salt = bcrypt.genSaltSync(this.saltRounds)

        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

        // Calculate the hash of today's date
        // const hashBuffer = crypto.createHash('sha256').update(formattedDate).digest()
        const digest: string = bcrypt.hashSync(formattedDate, salt) // hash created previously created upon sign up

        const hash = digest.replace(salt, '').substring(0, 5)
        // Construct the invoice number
        const invoiceNumber = `P-${hash}-${restoId?.toString().substring(0, 5)}`

        // Create a new invoice document with the generated number, date, and other details
        console.log(`Generated Invoice Number: ${invoiceNumber}`)
        return invoiceNumber
    }

    subscribeToCurrentOrder = (restoId: string | null, orederId: string | null, callback: (cartNewStatus: CartStatusEnum) => void): (() => void) => {
        console.log('subscibing to order with id', restoId, orederId)

        if (!restoId || !orederId) {
            const errorMsg = `resto id : ${restoId} or order id : ${orederId} is null`
            console.error(errorMsg)
            throw new Error(errorMsg)
        }
        const ordersCollectionRef = doc(firestore, 'restaurants/' + restoId + '/orders', orederId)

        let cartNewStatus = null
        const unsubscribe = onSnapshot(ordersCollectionRef, (doc: any) => {
            const source = doc.metadata.hasPendingWrites ? 'Local' : 'Server'

            console.log(source, 'Current data: ', doc.data())
            const updatedOrder = doc.data() as CartState
            cartNewStatus = updatedOrder.cartStatus as CartStatusEnum
            callback(cartNewStatus)
        })
        return unsubscribe
    }
}

export default new OrderService()
