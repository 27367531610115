import Navigation from './navigation/index'
import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { Platform, View, Text, StyleSheet } from 'react-native'
import useColorScheme from './hooks/useColorScheme'
import * as Font from 'expo-font'
import HomeScreenSkeleton from './components/skeleton/HomeScreenSkeleton'
import * as Linking from 'expo-linking'
import { UrlParams } from './types'
import { Provider } from 'react-redux'
import { store } from './app/store'
import { isMobile } from 'react-device-detect'
import './App.css' // Replace with the path to your CSS file

export default function App() {
    const colorScheme = useColorScheme()
    const url = Linking.useURL()
    const urlParams = url && (Linking.parse(url).queryParams as UrlParams)

    if (Platform.OS === 'web') {
        global._frameTimestamp = null
    }
    const [fontsLoaded] = Font.useFonts({
        'Poppins-Light': require('./assets/fonts/Poppins-Light.ttf'),
        'Aeonik-bold': require('./assets/fonts/Aeonik-bold.otf'),
        'Aeonik-regular': require('./assets/fonts/Aeonik-Regular.otf'),

        'circular-std': require('./assets/fonts/circular-std-medium-500.ttf'),
        'nothing-you-could-do': require('./assets/fonts/NothingYouCouldDo-Regular.ttf'),
        playfair: require('./assets/fonts/playfair.ttf'),
        'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf'),
        'Inter-italic': require('./assets/fonts/Inter-italic.var.woff2'),
        'Inter-roman': require('./assets/fonts/Inter-roman.var.woff2')
    })
    if (!fontsLoaded) {
        return <HomeScreenSkeleton withImg={true} />
    }

    return (
        <Provider store={store}>
            {isMobile ? (
                <GestureHandlerRootView style={{ flex: 1 }}>
                    <Navigation colorScheme={colorScheme} urlParams={urlParams} />
                </GestureHandlerRootView>
            ) : (
                <View style={styles.container}>
                    <View style={styles.iphone}>
                        <View style={styles.iphone14}>
                            <Text style={styles.operatorName}>Piato</Text>
                            <View style={styles.dynamicIsland}></View>
                            <View style={styles.dynamicIslandCamera}></View>
                            <View style={styles.operatorIcons}>
                                <Text style={styles.operatorIconText}>5G</Text>
                                <Text style={styles.battery}>100</Text>
                            </View>

                            <View style={styles.piato}>
                                <GestureHandlerRootView style={{ flex: 1 }}>
                                    <Navigation colorScheme={colorScheme} urlParams={urlParams} />
                                </GestureHandlerRootView>
                            </View>
                            <View style={styles.line}></View>
                        </View>
                    </View>
                    <View style={styles.start}></View>

                    <View style={styles.silent}></View>
                    <View style={styles.volumUp}></View>
                    <View style={styles.volumDown}></View>
                </View>
            )}
        </Provider>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0)'
    },
    piato: {
        width: 364,
        paddingTop: 40,
        height: 805,
        paddingBottom: 39,
        fontSize: 60
    },

    iphone: {
        borderWidth: 4,
        borderRadius: 50
    },
    iphone14: {
        width: 375,
        height: 812,
        borderWidth: 5,
        backgroundColor: 'white',
        borderRadius: 47,
        position: 'relative',
        backgroundSize: '376px 812px'
    },
    volumUp: {
        position: 'absolute',
        marginTop: -320,
        marginLeft: -385,
        width: 5, // Adjust the width as needed
        height: 60, // Adjust the height as needed
        backgroundColor: 'black' // Example background color
    },
    volumDown: {
        position: 'absolute',
        marginTop: -190,
        marginLeft: -385,
        width: 5, // Adjust the width as needed
        height: 60, // Adjust the height as needed
        backgroundColor: 'black' // Example background color
    },
    silent: {
        position: 'absolute',
        marginTop: -500,
        marginLeft: -385,
        width: 5, // Adjust the width as needed
        height: 40, // Adjust the height as needed
        backgroundColor: 'black' // Example background color
    },
    start: {
        position: 'absolute',
        marginTop: -280,
        marginLeft: 385,
        width: 5, // Adjust the width as needed
        height: 90, // Adjust the height as needed
        backgroundColor: 'black' // Example background color
    },
    operatorName: {
        position: 'absolute',
        top: 16,
        fontWeight: 'bold',
        left: 30,
        fontSize: 14,
        color: 'black'
    },
    dynamicIsland: {
        width: 120,
        height: 30,
        backgroundColor: 'black',
        borderRadius: 50,
        margin: 'auto',
        marginTop: 10,
        position: 'absolute',
        left: 125,
        zIndex: 99999
    },
    dynamicIslandCamera: {
        color: 'black',
        position: 'absolute',
        top: 20,
        right: 135,
        backgroundColor: '#3A3A3A',
        width: 10,
        height: 10,
        borderRadius: 50,
        zIndex: 99999
    },
    operatorIcons: {
        fontWeight: 'bold',
        position: 'absolute',
        top: 16,
        right: 50,
        color: 'black',
        fontSize: 12
    },
    operatorIconText: {
        fontWeight: 'bold',
        color: 'black',
        flexDirection: 'row',
        fontSize: 12
    },
    iconImage: {
        width: 14,
        height: 14,
        marginRight: 4
    },
    battery: {
        width: 20,
        height: 13,
        borderWidth: 2,
        borderRadius: 4,
        fontSize: 8,
        textAlign: 'center',
        color: 'white',
        backgroundColor: 'black',
        fontWeight: 'bold',
        position: 'absolute',
        top: 0,
        right: -28
    },
    dateArea: {},
    line: { position: 'absolute', bottom: 10, width: 150, height: 5, borderRadius: 50, backgroundColor: 'black', left: 110 }
})
