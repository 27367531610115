import { Category, Item, DisplayStatusEnum } from '../types'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore/lite'
import { db } from '../firebase'

const MAX_DESCRIPTION_DISPLAY_LENGTH = 50

class CategoryService {
    CatSorterId = 'catSorterId'

    fetchAllCategories = async (restoId: string) => {
        const categoriesRef = collection(db, '/users/' + restoId + '/categories')

        const q = query(categoriesRef, where('displayStatus', '==', DisplayStatusEnum.AFFICHE))
        const catQuerySnapshot = await getDocs(q)
        return catQuerySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as Category
        })
    }

    fetchCategoriesSorters = async (restoId: string) => {
        console.log('fetching all sorters from Services')
        const documentRef = doc(db, 'users/' + restoId + '/sorters', this.CatSorterId)
        return getDoc(documentRef)
            .then((sorters) => (sorters.exists() ? sorters.data().orderedIds : ([] as string[])))
            .catch((error) => {
                console.log('Error getting document for Categories sorters:', error)
                return [] as string[]
            })
    }

    fetchOrderedCategories = async (restoId: string) => {
        const idToIndexMap = await this.fetchCategoriesSorters(restoId)
        const categories = await this.fetchAllCategories(restoId)

        const orderedCategories = categories.sort((a, b) => {
            const orderA = idToIndexMap.indexOf(a.id) || 0
            const orderB = idToIndexMap.indexOf(b.id) || 0
            return orderA - orderB
        })
        return orderedCategories
    }

    // ITEMS

    fetchItemsOfCat = async (catId: string, restoId: string) => {
        const catPath = '/users/' + restoId + '/categories'

        const itemsRef = collection(db, catPath + '/' + catId + '/items')
        // filtering out the non-affiché items.
        const q = query(itemsRef, where('displayStatus', '!=', DisplayStatusEnum.NON_AFFICHE))
        const itemsSnapshot = await getDocs(q)
        return itemsSnapshot.docs.map((item) => {
            const a = item.data()
            return { ...a, id: item.id, abbreviatedDescription: this.truncateDescription(a.description) } as Item
        })
    }

    fetchItemSorters = async (clickedCatId: string, restoId: string) => {
        console.log('fetching all sorters from Services')
        const documentRef = doc(db, 'users/' + restoId + '/sorters', clickedCatId)

        return getDoc(documentRef)
            .then((sorters) => (sorters.exists() ? sorters.data().orderedIds : ([] as string[])))
            .catch((error) => {
                console.log('Error getting document for Item sorters:', error)
                return [] as string[]
            })
    }

    fetchOrderedItems = async (clickedCatId: string, restoId: string) => {
        return this.fetchItemSorters(clickedCatId, restoId).then((idToIndexMap) => {
            return this.fetchItemsOfCat(clickedCatId, restoId).then((items) => {
                return items.sort((a: Item, b: Item) => {
                    const orderA = idToIndexMap.indexOf(a.id) || 0
                    const orderB = idToIndexMap.indexOf(b.id) || 0
                    return orderA - orderB
                })
            })
        })
    }

    fetchAllCategoriesWithItems = async (restoId: string) => {
        console.log('📡 fetching all Categories')

        const categories = await this.fetchOrderedCategories(restoId)
        const itemFetchPromises = categories.map(async (cat) => {
            const items = await this.fetchOrderedItems(cat.id, restoId)
            return { ...cat, items }
        })

        const fetchedCategoriesWithItems = await Promise.all(itemFetchPromises)

        // fetching all categories's items filtered on undisplayed and empty category item list
        return fetchedCategoriesWithItems.filter((cat) => cat.items.length > 0 && cat.displayStatus == DisplayStatusEnum.AFFICHE)
    }

    truncateDescription = (description: string) => {
        if (!description) {
            return ''
        }
        return description.length > MAX_DESCRIPTION_DISPLAY_LENGTH ? description.slice(0, MAX_DESCRIPTION_DISPLAY_LENGTH) + '...' : description
    }
}

export default new CategoryService()
