import { View, Text, StyleSheet } from 'react-native'

const ObligatoryLabel = ({ isWarning }: { isWarning: boolean }) => {
    const styles = StyleSheet.create({
        container: {
            borderWidth: 1,
            borderRadius: 8,
            paddingHorizontal: 1,
            paddingVertical: 2,
            alignSelf: 'flex-start',
            backgroundColor: isWarning ? '#FEF2F2' : '#F3F4F6',
            borderColor: isWarning ? '#DC2638' : 'transparent'
        },
        text: {
            fontSize: 12,
            color: isWarning ? '#B91C1C' : '#4B5563',
            fontWeight: '500',
            marginHorizontal: 5
        },
        outerContainer: {
            paddingTop: 15,
            paddingRight: 15
        }
    })

    return (
        <View style={styles.outerContainer}>
            <View style={styles.container}>
                <Text style={styles.text}>Obligatoire</Text>
            </View>
        </View>
    )
}

export default ObligatoryLabel
{
    /* <span class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Badge</span> */
}
