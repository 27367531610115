import { ScrollView, Text, StyleSheet, TouchableOpacity, View, Dimensions } from 'react-native'
import { Button, Divider, IconButton, TextInput } from 'react-native-paper'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { useEffect, useState } from 'react'
import { useTheme } from '@react-navigation/native'
import { Picker } from '@react-native-picker/picker'
import { CartItem } from 'types'
import { useAppDispatch } from '../app/hooks'
import { cartActions } from '../features/cart/cartSlice'
import { LinearGradient } from 'expo-linear-gradient'

const CartScreen = ({ navigation: { goBack, navigate } }: any) => {
    const cart = useSelector((state: RootState) => state.cart)

    const [showTextInput, setShowTextInput] = useState(false)

    const [init, setInit] = useState(true)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (init) {
            setInit(false)
        } else {
            console.log(' 🚀 update cart to LS ', cart)
            const cartStateJSON = JSON.stringify(cart)
            localStorage.setItem('cart', cartStateJSON)
        }
    }, [cart])

    const cartItems = useSelector((state: RootState) => state.cart.items)
    const numbers = ['supprimer', 1, 2, 3, 4, 5, 6, 7, 8]

    const { colors } = useTheme()

    const onQuantitychange = (newQuantity: undefined | string, cartId: string): void => {
        newQuantity && dispatch(cartActions.incrementCartItem({ itemCartId: cartId, newQuantity: newQuantity }))
    }

    const onItemDelete = (cartId: string): void => {
        dispatch(cartActions.removeItemFromCart({ itemCartId: cartId }))
    }

    const handleCommentChange = (newComment: string): void => {
        console.log(newComment)
        dispatch(cartActions.addCommentToCart({ newComment }))
    }

    return (
        <LinearGradient colors={['#4f46e523', '#FFFFFF']} style={styles.container} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
            <ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }}>
                <View style={{ paddingTop: 40 }}>
                    {cartItems.map((item: CartItem, index: number) => (
                        <View key={index}>
                            <View style={[styles.cartItem]}>
                                <Text style={styles.itemNames}>{item.name}</Text>
                                <View style={{ flexDirection: 'row', alignItems: 'left' }}>
                                    <View style={styles.picker}>
                                        <Picker
                                            style={{ width: 46, height: 32, fontWeight: '500', paddingLeft: 3, fontSize: 15, backgroundColor: '#FFF', borderRadius: 12, borderColor: '#FFF' }}
                                            selectedValue={item.quantity}
                                            onValueChange={(newItemQuantity) => {
                                                newItemQuantity.toString() == 'supprimer' ? onItemDelete(item.cartId) : onQuantitychange(newItemQuantity, item.cartId)
                                            }}>
                                            {numbers.map((number, indexNum) => (
                                                <Picker.Item key={indexNum} style={{ fontWeight: '500', fontSize: 14 }} label={number.toString()} value={number} />
                                            ))}
                                        </Picker>
                                    </View>
                                    <Text style={styles.itemsPrice}>{(item.itemTotalPrice * item.quantity).toFixed(2)} €</Text>
                                </View>
                            </View>
                            {item.modifiers?.map((modifier, modIndex) => (
                                <View key={modIndex}>
                                    {modifier.options.map((option, optionIndex) => (
                                        <View style={{ flexDirection: 'row' }} key={optionIndex}>
                                            <Text style={{ paddingLeft: 25, paddingBottom: 10, fontWeight: '600', color: colors.primary }}> {option.quantity ? `${option.quantity}x ` : ''}</Text>

                                            <Text>{option.name}</Text>
                                            <Text style={{ fontWeight: '600' }}> {option.price ? `  ${option.price.toFixed(2)} €` : ''}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                            <Divider style={{ marginHorizontal: 12 }} />
                        </View>
                    ))}
                </View>

                {true && (
                    <TextInput
                        mode="flat"
                        hidden={showTextInput}
                        underlineColor={colors.secondary}
                        activeUnderlineColor={colors.primary}
                        style={{ marginTop: 30, marginHorizontal: 10, padding: 15, backgroundColor: '#FFF' }}
                        placeholder="Un message à ajouter ?"
                        multiline
                        maxLength={200}
                        onChangeText={handleCommentChange}
                    />
                )}
            </ScrollView>
            <View style={styles.cardContainer}>
                {/* Pourboire */}

                <View style={styles.cartItem}>
                    <Text style={{ paddingLeft: 16, fontWeight: '500', paddingBottom: 30, fontSize: 16 }}>Ajouter un pourboire</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', paddingBottom: 20 }}>
                        <IconButton icon="minus-circle-outline" iconColor={colors.primary} size={20} rippleColor={'black'} onPress={() => dispatch(cartActions.decerementCartTip())} disabled={cart.tipTotalPrice == 0} />
                        <Text style={styles.count}>{cart.tipTotalPrice.toFixed(2)} €</Text>
                        <IconButton icon="plus-circle-outline" iconColor={colors.primary} size={20} rippleColor={'black'} onPress={() => dispatch(cartActions.incerementCartTip())} />
                    </View>
                </View>

                {/* Total */}
                <View style={styles.cartItem}>
                    <Text style={styles.total}>Total </Text>
                    <Text style={{ paddingRight: 16, paddingBottom: 20, fontWeight: 'bold', fontSize: 18 }}>{cart.cartTotalPrice.toFixed(2)} €</Text>
                </View>
            </View>
            <View>
                <TouchableOpacity
                    disabled={cart.cartTotalPrice.toFixed(2) == '0.00'}
                    style={[styles.addToCartButton, cart.cartTotalPrice.toFixed(2) == '0.00' ? { backgroundColor: '#D5D6D6' } : { backgroundColor: '#111' }]}
                    onPress={() => navigate('CheckoutScreen')}>
                    <Text style={styles.addToCartButtonText}>Finaliser la commande</Text>
                </TouchableOpacity>
            </View>
        </LinearGradient>
    )
}
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    quantityIcons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        paddingHorizontal: 2,
        paddingVertical: 2,
        borderWidth: 1,
        marginHorizontal: 5,
        borderColor: 'transparent',
        borderRadius: 15,
        backgroundColor: '#EEEEEE'
    },
    count: {
        fontWeight: '600',
        fontSize: 16,
        minWidth: 15,
        textAlign: 'center'
    },
    itemsPrice: {
        paddingRight: 16,
        fontSize: 16,
        paddingTop: 17,
        fontWeight: '600',
        minWidth: 78
    },
    total: {
        paddingLeft: 16,
        paddingBottom: 20,
        fontSize: 18,
        fontWeight: '600'
    },
    picker: {
        padding: 10,
        width: 80
    },
    addToCartButton: {
        alignItems: 'center',
        paddingTop: 15,
        paddingBottom: 18,
        bottom: 16,
        elevation: 5,
        position: 'relative',
        backgroundColor: '#111',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '93%',
        borderRadius: 7
    },
    addToCartButtonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    closeIcon: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 20,
        right: 20,
        zIndex: 1,
        borderRadius: 20,
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
        backgroundColor: 'white',
        boxShadow: '0 1px 4px #00000014'
    },
    commandeTitle: {
        fontSize: 32,
        lineHeight: 32,
        paddingHorizontal: 15,
        paddingTop: 30,
        textAlignVertical: 'auto',
        fontWeight: '700',
        textAlign: 'left'
    },
    cardContainer: {
        paddingVertical: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: -1
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 2
    },
    itemNames: {
        fontSize: 17,
        lineHeight: 22,
        paddingLeft: 15,
        textAlign: 'left',
        paddingVertical: 10,
        textAlignVertical: 'auto',
        fontWeight: '600'
    },
    cartItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    dotcontainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 15
    },
    dottedLine: {
        width: '100%',
        height: 0.1,
        borderWidth: 0.1,
        borderStyle: 'solid',
        fontWeight: 'light'
    },

    background: {
        position: 'absolute',
        left: -(windowWidth + windowHeight) / 1.5,
        right: -(windowWidth + windowHeight) / 2,
        top: -(windowWidth + windowHeight) / 2,
        bottom: -(windowWidth + windowHeight) / 2,
        width: windowWidth + windowHeight,
        height: windowWidth + windowHeight,
        transform: [{ rotate: '60deg' }]
    }
})

export default CartScreen
