import React from 'react'
import { View, Text, StyleSheet } from 'react-native'

const UnavailableLabel = ({ title }: { title: string }) => {
    const styles = StyleSheet.create({
        container: {
            borderWidth: 1,
            borderColor: 'transparent',
            borderRadius: 5,
            paddingHorizontal: 2,
            paddingVertical: 2,
            alignSelf: 'flex-start',
            backgroundColor: '#F3F4F6',
            marginHorizontal: 5
        },
        text: {
            fontSize: 12,
            color: 'red',
            fontWeight: '500',
            marginHorizontal: 5
        },
        outerContainer: {
            paddingLeft: 5
        }
    })

    return (
        <View style={styles.outerContainer}>
            <View style={styles.container}>
                <Text style={styles.text}>{title}</Text>
            </View>
        </View>
    )
}

export default UnavailableLabel
