import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { UrlParams } from '../../types'

// Define a type for the slice state
interface CounterState {
    value: boolean
    restoId: string | null
    tableId: string | null
}

// Define the initial state using that type
const initialState: CounterState = {
    value: true,
    restoId: null,
    tableId: null
}

export const restoSlice = createSlice({
    name: 'resto',
    initialState,
    reducers: {
        open: (state) => {
            state.value = true
        },
        close: (state) => {
            state.value = false
        },
        setUrlParams: (state, action: PayloadAction<UrlParams>) => {
            const { restoId, tableId } = action.payload
            state.restoId = restoId
            state.tableId = tableId
        }
    }
})

export const { open, close, setUrlParams } = restoSlice.actions

export const restoIsOpened = (state: RootState) => state?.resto.value
export const selectRestoId = (state: RootState) => state?.resto.restoId
export const selectTableId = (state: RootState) => state?.resto.tableId

export default restoSlice.reducer
