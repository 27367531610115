import { View, StyleSheet, Text, Dimensions, Animated, TouchableOpacity, Linking } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { getTvaFromTTCPrice, getTotalHTFromTTCPrice } from '../../utils/Utils'
import { useState, useEffect } from 'react'
import { User, CartItem } from '../../types'
import { useFetchUsersQuery } from '../../features/users/userSlice'
import { useTheme } from '@react-navigation/native'
import { Divider } from 'react-native-paper'
import LottieView from 'react-native-web-lottie'
import GoogleSVG from '../../assets/icons/google.svg' // Import the SVG file
import { isMobile } from 'react-device-detect'

const BillsScreen = ({ cart, restoId, inputRef, stepPosition }: any) => {
    const { data: usersFromStore } = useFetchUsersQuery(restoId)
    const [user, setUser] = useState<User | null>(null)
    const [reviewLink, setReviewLink] = useState<string | null>()

    const { colors } = useTheme()

    useEffect(() => {
        usersFromStore && setUser(usersFromStore)
        usersFromStore && setReviewLink('https://search.google.com/local/writereview?placeid=' + usersFromStore.googleId)
    }, [usersFromStore])

    const getAnimationPath = (stepPosition: number) => {
        switch (stepPosition) {
            case 0:
                return require('../../assets/animation/success.json')
            case 1:
                return require('../../assets/animation/cooking.json')
            case 2:
                return require('../../assets/animation/ready.json')
            default:
                return require('../../assets/animation/success.json')
        }
    }
    const getOrderStatus = (stepPosition: number) => {
        switch (stepPosition) {
            case 0:
                return 'envoyée'
            case 1:
                return 'en préparation'
            case 2:
                return 'prête'
            default:
                return 'en cours'
        }
    }

    return (
        <View style={styles.container}>
            {cart && (
                <View style={styles.backgroundContainer}>
                    <LinearGradient colors={['#FFFFFF', '#4f46e523']} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
                        <View style={{ flex: 1, alignItems: 'center', marginTop: 50 }}>
                            <Text style={{ fontSize: 20, fontFamily: 'Aeonik-regular', fontWeight: '400', color: '#111' }}>Commande {getOrderStatus(stepPosition)}</Text>
                            <Animated.View style={{ paddingHorizontal: 150 }}>
                                <LottieView autoPlay loop={stepPosition === 1} source={getAnimationPath(stepPosition)} />
                            </Animated.View>
                            <View style={styles.dotcontainer}>
                                <View style={[styles.dottedLine, { borderColor: stepPosition == 0 ? colors.primary : colors.secondary }]} />
                                <View style={[styles.dottedLine, { borderColor: stepPosition == 1 ? colors.primary : colors.secondary }]} />
                                <View style={[styles.dottedLine, { borderColor: stepPosition == 2 ? colors.primary : colors.secondary }]} />
                            </View>
                            <TouchableOpacity style={[styles.buttonGoogle, { borderColor: '#8F5FF6' }]} onPress={() => Linking.openURL(reviewLink)}>
                                <Text style={[styles.addToCartButtonText, { paddingRight: 5, paddingTop: 5, color: '#111', fontFamily: 'Aeonik-regular' }]}> Laissez-nous un avis sur</Text>
                                <img src={GoogleSVG} width="30" height="30" alt="Icon" />
                            </TouchableOpacity>
                        </View>

                        <View ref={inputRef} style={styles.cardContainer}>
                            {user && (
                                <View style={{ backgroundColor: '#181818', marginBottom: 30, paddingHorizontal: 30, paddingVertical: 20 }}>
                                    {user.restaurantName && <Text style={[{ fontWeight: 'bold', color: 'white', marginBottom: 10, fontSize: 25 }]}>{user.restaurantName}</Text>}
                                    {cart.invoiceNumber && <Text style={{ textAlign: 'left', fontSize: 14, color: colors.primary, marginBottom: 10 }}>№ {cart.invoiceNumber}</Text>}
                                    {cart.orderDate && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Passée le : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {cart.orderDate}</Text>
                                        </View>
                                    )}
                                    {user.address && isMobile && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Addresse : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {user.address}</Text>
                                        </View>
                                    )}
                                    {user.phone && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Tél : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {user.phone}</Text>
                                        </View>
                                    )}

                                    {cart.tableId && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Table : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {cart.tableId}</Text>
                                        </View>
                                    )}

                                    {user.codeAPE && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Code : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {user.codeAPE}</Text>
                                        </View>
                                    )}

                                    {user.tvaNumber && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Tva : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white' }}> {user.tvaNumber}</Text>
                                        </View>
                                    )}

                                    {cart.takeAway && (
                                        <View style={{ flexDirection: 'row' }}>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontWeight: '600', fontFamily: 'Aeonik-regular' }}> Type de commande : </Text>
                                            <Text style={{ textAlign: 'left', fontSize: 12, color: 'white', fontFamily: 'Aeonik-regular' }}> {cart.takeAway ? 'à emporter' : 'sur place'}</Text>
                                        </View>
                                    )}
                                </View>
                            )}

                            <View>
                                {cart.items.map((item: CartItem, index: number) => (
                                    <View key={index}>
                                        <View style={[styles.billsInfo, index % 2 == 0 ? { backgroundColor: '#F8F8FA' } : { backgroundColor: '#FFF' }]}>
                                            <Text style={styles.itemNames}>
                                                {item.quantity ? ` ${item.quantity}x ` : ''} {item.name}
                                            </Text>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <Text style={styles.itemsPrice}>{(item.price * item.quantity).toFixed(2)} €</Text>
                                            </View>
                                        </View>

                                        {item.modifiers?.map((modifier, modIndex) => (
                                            <View key={modIndex}>
                                                {modifier.options.map((option, optionIndex) => (
                                                    <View style={styles.billsInfo} key={optionIndex}>
                                                        <View style={styles.optionLine}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Text style={{ fontSize: 11, paddingLeft: 15, fontWeight: '300', color: 'black', fontFamily: 'Aeonik-regular' }}>{option.quantity ? `${option.quantity}x` : ''}</Text>
                                                                <Text style={{ fontSize: 11, paddingLeft: 15, fontWeight: '300', fontFamily: 'Aeonik-regular' }}>{option.name}</Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                            <Text style={styles.itemsPrice}> {option.price ? `  ${option.price.toFixed(2)} €` : ''}</Text>
                                                        </View>
                                                    </View>
                                                ))}
                                            </View>
                                        ))}
                                    </View>
                                ))}
                            </View>
                            <Divider style={{ marginHorizontal: 30, marginTop: 20 }} />
                            <View style={{ marginTop: 20 }}>
                                <View style={styles.billsInfo}>
                                    <Text style={styles.itemNames}>Total HT </Text>
                                    <Text style={styles.itemsPrice}>{getTotalHTFromTTCPrice(cart.cartTotalPrice - cart.tipTotalPrice)}€</Text>
                                </View>
                                <View style={[styles.billsInfo, { backgroundColor: '#F8F8FA' }]}>
                                    <Text style={styles.itemNames}>TVA 10% </Text>
                                    <Text style={styles.itemsPrice}>{getTvaFromTTCPrice(cart.cartTotalPrice - cart.tipTotalPrice)}€</Text>
                                </View>

                                {cart.tipTotalPrice == 0.0 ? null : (
                                    <View style={[styles.billsInfo, { backgroundColor: '#F8F8FA' }]}>
                                        <Text style={styles.itemNames}>Pourboire</Text>
                                        <Text style={styles.itemsPrice}>{cart.tipTotalPrice.toFixed(2)}€</Text>
                                    </View>
                                )}
                            </View>
                            <View style={styles.dotcontainer}>
                                <View style={styles.dottedLine} />
                            </View>
                            <View style={styles.informationsContainer}>
                                <View style={styles.column1}>
                                    <Text style={[styles.label, styles.total]}>TOTAL TTC :</Text>
                                </View>
                                <View>
                                    <Text style={styles.totalPrice}>{cart.cartTotalPrice.toFixed(2)}€</Text>
                                </View>
                            </View>

                            {/* <View>
                            {user && (
                                <>
                                    <Text style={[styles.title, { fontWeight: 'normal', marginBottom: 20, marginTop: 0 }]}>Visitez nos dernieres publications en cliquant ici :</Text>
                                    <View style={[styles.billsInfo, { marginRight: 50, marginLeft: 50, marginBottom: 25 }]}>
                                        {user.facebook && <Entypo name="facebook" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.facebook}`, '_blank')} />}
                                        {user.instagram && <Entypo name="instagram-with-circle" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.instagram}`, '_blank')} />}
                                        {user.twitter && <Entypo name="twitter" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.twitter}`, '_blank')} />}
                                        {user.email && <MaterialCommunityIcons name="gmail" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.email}`, '_blank')} />}
                                    </View>
                                </>
                            )}
                        </View> */}
                            {/*onPress={() => Linking.openURL('https://www.google.com')}*/}
                            <View style={styles.cardContent}></View>
                            <View style={{ flexDirection: 'column' }}>
                                <Text style={styles.itemNames}>Merci pour votre visite</Text>
                            </View>
                        </View>
                    </LinearGradient>
                </View>
            )}
        </View>
    )
}
const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    backgroundContainer: {
        overflowX: 'hidden', // Prevent horizontal scrolling
        position: 'absolute',
        left: 0,
        backgroundColor: '#FFF',
        right: 0,
        top: 0,
        bottom: 0
    },
    background: {
        position: 'absolute',
        left: -(windowWidth + windowHeight) / 1.5,
        right: (windowWidth + windowHeight) / 2,
        top: 0,
        bottom: 0,
        width: windowWidth + windowHeight,
        height: windowHeight,
        transform: [{ rotate: '60deg' }]
    },
    cardContainer: {
        position: 'relative',
        margin: 30,
        minHeight: 600,
        backgroundColor: 'white',
        borderRadius: 8,
        overflow: 'hidden',
        elevation: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
        shadowOpacity: 1,
        shadowRadius: 4
    },

    cardBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    cardContent: {
        padding: 0
    },
    trianglesContainer: {
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    billsInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    triangle: {
        height: 10,
        width: 0,
        borderStyle: 'solid',
        borderLeftWidth: 15,
        borderRightWidth: 15,
        borderBottomWidth: 10,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'hsl(240,100%,10%)',
        transform: [{ rotate: '360deg' }],
        marginHorizontal: 2
    },
    triangleGradient: {
        flex: 1,
        marginHorizontal: -40,
        justifyContent: 'flex-end'
    },
    dotcontainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 15
    },
    buttonGoogle: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',

        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        borderWidth: 1.5,
        marginVertical: 15,
        borderRadius: 5
    },
    dottedLine: {
        width: '30%',
        height: 2.5,
        borderWidth: 2.5,
        borderStyle: 'solid',
        marginVertical: 10,
        borderRadius: 30,
        fontWeight: 'light'
    },
    addToCartButtonText: {
        fontWeight: '400',
        textAlign: 'center'
    },
    starText: {
        fontWeight: '600',
        fontSize: 25,
        textAlign: 'center',
        color: '#E6C42E'
    },
    informationsContainer: {
        flexDirection: 'row',
        paddingHorizontal: 13,
        marginBottom: 10,
        marginTop: 15
    },
    column1: {
        flex: 1,
        justifyContent: 'flex-start'
    },
    column2: {
        justifyContent: 'flex-end'
    },
    label: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 5
    },
    value: {
        fontSize: 16,
        marginBottom: 5
    },
    total: {
        justifyContent: 'flex-end',
        fontSize: 18,
        paddingLeft: 20,
        marginBottom: 15,
        fontWeight: '400',
        fontFamily: 'Aeonik-regular'
    },
    itemNames: {
        fontSize: 12,
        lineHeight: 22,
        color: '#333333',
        paddingVertical: 10,
        paddingHorizontal: 30,
        textAlignVertical: 'auto',
        fontWeight: '400',
        fontFamily: 'Aeonik-regular'
    },
    optionLine: {
        fontSize: 10,
        color: '#333333',
        paddingHorizontal: 30,
        fontWeight: '400'
    },
    itemsPrice: {
        paddingRight: 30,
        fontSize: 12,
        fontWeight: '600',
        fontFamily: 'Aeonik-regular'
    },
    totalPrice: {
        justifyContent: 'flex-end',
        paddingBottom: 20,
        fontSize: 20,
        fontWeight: '600'
    }
})

export default BillsScreen
