import Svg, { Circle, Path } from 'react-native-svg'

export const CrabSvg = (
    <Svg enableBackground="new 0 0 100 100" viewBox="0 0 100 100" width="30" height="30">
        <Circle cx="51.068" cy="58.794" r="11.927" fill="#ff5e71"></Circle>
        <Path
            fill="#052049"
            d="M93.426,61.481c-0.797-0.223-1.626,0.241-1.85,1.039c-0.281,1.002-0.748,1.92-1.389,2.73
			c-1.439,1.824-3.607,2.909-6.105,3.056c-2.2,0.139-4.42-0.48-6.363-1.729c0.558-1.119,1.012-2.254,1.357-3.393
			c0.567,0.093,1.136,0.156,1.703,0.156c2.197,0,4.372-0.688,6.307-2.019c2.606-1.795,4.463-4.552,5.225-7.766
			c0.191-0.806-0.308-1.614-1.113-1.806c-0.808-0.189-1.615,0.307-1.806,1.114c-0.59,2.488-2.013,4.613-4.006,5.985
			c-1.736,1.193-3.721,1.681-5.663,1.409c0.093-0.709,0.144-1.415,0.144-2.113c0-0.747-0.117-1.511-0.34-2.279
			c5.411-3.064,8.721-8.447,8.721-14.338c0-0.313-0.02-0.621-0.038-0.93c0.577-0.471,1.143-1.001,1.686-1.598
			c3.336-3.659,5.076-8.562,4.654-13.115c-0.801-8.634-7.406-15.315-14.75-14.61c-0.825,0.076-1.432,0.807-1.355,1.632l1.198,12.93
			l-5.974-8.189c-0.488-0.669-1.427-0.816-2.096-0.328c-5.467,3.987-6.053,12.526-1.306,19.035
			c3.014,4.131,7.622,6.598,12.329,6.598c0.843,0,1.727-0.192,2.618-0.546c-0.306,4.361-2.872,8.311-6.923,10.704
			c-2.503-4.168-7.812-8.198-14.546-10.668c0.402-0.743,0.652-1.581,0.652-2.483c0-2.9-2.36-5.26-5.261-5.26
			c-2.902,0-5.262,2.359-5.262,5.26c0,0.078,0.019,0.151,0.022,0.229c-1.28-0.127-2.58-0.2-3.897-0.2s-2.617,0.073-3.897,0.2
			c0.004-0.077,0.022-0.15,0.022-0.229c0-2.9-2.36-5.26-5.262-5.26s-5.261,2.359-5.261,5.26c0,0.902,0.249,1.74,0.651,2.483
			c-6.733,2.47-12.042,6.5-14.546,10.668c-4.05-2.394-6.617-6.343-6.922-10.704c0.892,0.354,1.775,0.546,2.618,0.546
			c4.708,0,9.316-2.467,12.327-6.598c4.748-6.508,4.162-15.048-1.305-19.035c-0.321-0.235-0.723-0.33-1.115-0.271
			c-0.394,0.062-0.746,0.277-0.98,0.599l-5.974,8.189l1.199-12.93c0.076-0.825-0.531-1.556-1.355-1.632
			c-7.348-0.686-13.95,5.979-14.75,14.61C5.028,30.439,6.768,35.341,10.103,39c0.544,0.597,1.11,1.129,1.687,1.601
			c-0.018,0.308-0.036,0.615-0.036,0.929c0,5.89,3.309,11.274,8.719,14.339c-0.222,0.769-0.339,1.531-0.339,2.278
			c0,0.698,0.05,1.404,0.144,2.114c-1.94,0.271-3.927-0.215-5.662-1.41c-1.994-1.372-3.417-3.497-4.007-5.985
			c-0.19-0.807-1-1.31-1.806-1.114c-0.806,0.191-1.304,1-1.113,1.806c0.762,3.214,2.617,5.971,5.225,7.766
			c1.935,1.331,4.11,2.019,6.307,2.019c0.568,0,1.137-0.063,1.703-0.156c0.346,1.139,0.8,2.273,1.357,3.393
			c-1.943,1.246-4.164,1.861-6.363,1.729c-2.498-0.146-4.666-1.231-6.105-3.056c-0.64-0.81-1.106-1.729-1.387-2.729
			c-0.224-0.799-1.052-1.266-1.85-1.04c-0.797,0.224-1.263,1.052-1.039,1.849c0.388,1.386,1.034,2.657,1.921,3.78
			c1.978,2.506,4.92,3.994,8.285,4.191c0.252,0.015,0.504,0.021,0.756,0.021c2.536,0,5.052-0.753,7.276-2.147
			c0.923,1.402,2.004,2.757,3.227,4.035c-5.729,1.635-9.93,7.363-9.93,14.063c0,0.828,0.672,1.5,1.5,1.5s1.5-0.672,1.5-1.5
			c0-6.062,4.218-11.117,9.601-11.51c0.024-0.002,0.046-0.009,0.069-0.013c5.276,4.332,12.35,7.292,20.259,7.292
			s14.981-2.96,20.258-7.292c0.024,0.004,0.046,0.011,0.069,0.013c5.384,0.393,9.601,5.448,9.601,11.51c0,0.828,0.672,1.5,1.5,1.5
			c0.829,0,1.5-0.672,1.5-1.5c0-6.7-4.2-12.429-9.93-14.063c1.223-1.279,2.304-2.633,3.226-4.036
			c2.226,1.396,4.741,2.148,7.278,2.148c0.252,0,0.504-0.007,0.756-0.021c3.365-0.197,6.308-1.686,8.284-4.191
			c0.887-1.122,1.534-2.394,1.923-3.779C94.688,62.533,94.224,61.705,93.426,61.481z M72.69,34.588
			c-3.396-4.656-3.499-10.516-0.473-13.837l8.208,11.252c0.397,0.545,1.11,0.757,1.742,0.52c0.631-0.238,1.024-0.87,0.963-1.542
			L81.582,14.26c5.074,0.449,9.387,5.517,9.979,11.904c0.406,4.389-1.739,8.465-3.884,10.817c-1.671,1.833-3.619,2.972-5.082,2.972
			C78.844,39.954,75.141,37.948,72.69,34.588z M8.438,26.165c0.592-6.388,4.905-11.455,9.979-11.904l-1.55,16.721
			c-0.062,0.672,0.332,1.304,0.964,1.542c0.631,0.237,1.344,0.025,1.742-0.52l8.207-11.252c3.026,3.321,2.923,9.182-0.474,13.837
			c-2.448,3.359-6.15,5.365-9.903,5.365c-1.465,0-3.413-1.14-5.085-2.974C10.176,34.628,8.031,30.552,8.438,26.165z M56.875,39.96
			c0-1.246,1.015-2.26,2.262-2.26c1.246,0,2.261,1.014,2.261,2.26c0,0.592-0.233,1.127-0.606,1.53
			c-1.235-0.347-2.506-0.642-3.809-0.874C56.919,40.408,56.875,40.19,56.875,39.96z M38.603,39.96c0-1.246,1.014-2.26,2.261-2.26
			c1.246,0,2.262,1.014,2.262,2.26c0,0.23-0.045,0.447-0.108,0.656c-1.302,0.232-2.573,0.527-3.808,0.875
			C38.836,41.087,38.603,40.552,38.603,39.96z M23.133,58.147c0-5.963,11.537-15.158,26.867-15.158s26.866,9.195,26.866,15.158
			c0,10.126-11.735,21.896-26.866,21.896S23.133,68.273,23.133,58.147z"></Path>
        <Path
            fill="#052049"
            d="M53.131 50.408c-.578.593-.567 1.542.025 2.121 1.361 1.33 2.175 3.609 2.175 6.1 0 .849-.104 1.7-.308 2.532-.196.805.297 1.616 1.102 1.813.119.029.239.043.356.043.676 0 1.289-.459 1.457-1.144.261-1.065.393-2.157.393-3.245 0-3.281-1.151-6.363-3.079-8.246C54.659 49.803 53.71 49.815 53.131 50.408zM44.418 50.383c-1.928 1.884-3.078 4.966-3.078 8.245 0 1.084.132 2.175.392 3.243.167.687.781 1.146 1.457 1.146.117 0 .236-.014.355-.043.805-.195 1.299-1.007 1.103-1.812-.204-.837-.307-1.689-.307-2.534 0-2.488.813-4.769 2.175-6.101.593-.579.604-1.528.024-2.121S45.011 49.804 44.418 50.383z"></Path>
    </Svg>
)
