import { storage } from '../firebase'
import { getDownloadURL, listAll, ref } from 'firebase/storage'
import { ImgUrl } from '../types'

class ImgService {
    getItemImgUrlsFromId = async (clickedCatId: string, restoId: string) => {
        console.log('📡 fetching all imgs')

        const listRef = ref(storage, 'images/' + restoId + '/' + clickedCatId + '/thumbnails')
        const refRepo = await listAll(listRef)
        const mapIdUrl = await Promise.all(
            await refRepo.items.map(async (itemRef) => {
                const url = await getDownloadURL(itemRef)
                const imgName = itemRef.name
                const thumbnailId = imgName.replaceAll('_200x200', '')
                return { id: thumbnailId, url: url }
            })
        )
        return new Map(mapIdUrl.map((obj) => [obj.id, { imgUrl: obj.url, key: 0 } as ImgUrl]))
    }

    getItemHQImgUrlFromThumbnails = (thumbnailsUrl: string) => {
        return thumbnailsUrl ? thumbnailsUrl.replace('_200x200', '').replace('thumbnails%2F', '') : ''
    }
}

export default new ImgService()
