import { configureStore } from '@reduxjs/toolkit'
import restoReducer from '../features/resto/restoSlice'
import { composeWithDevTools } from 'redux-devtools-extension'

import { firestoreApi } from './firestoreApi'
import uiReducer from '../features/uiSlice/uiSlice'
import cartReducer from '../features/cart/cartSlice'

import counterReducer from '../features/test/counterReducer'

const composeEnhancers = composeWithDevTools({})

export const setupStore = () =>
    configureStore({
        devTools: true,
        reducer: {
            [firestoreApi.reducerPath]: firestoreApi.reducer,
            resto: restoReducer,
            ui: uiReducer,
            cart: cartReducer,
            counter: counterReducer
        },

        middleware: (getDefaultMiddleware) => {
            return getDefaultMiddleware().concat(firestoreApi.middleware)
        }
    })

// export const store = createStore(rootReducer, devToolsEnhancer({ realtime: true }))

export const store = setupStore()

export type AppStore = ReturnType<typeof setupStore>
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
