import { CartState } from '../types'
import { doc, getDoc } from 'firebase/firestore/lite'
import { db } from '../firebase'
class passedOrderService {
    fetchPendingOrderForLastest4Hours = async (restoId: string, orderId: string) => {
        try {
            const currentDate = new Date()
            const fourHoursAgo = new Date(currentDate.getTime() - 4 * 60 * 60 * 1000) // 4 hours in milliseconds

            const ref = doc(db, 'restaurants/' + restoId + '/orders/' + orderId)
            const docSnap = await getDoc(ref)
            if (docSnap.exists()) {
                const cartState = docSnap.data() as CartState
                return fourHoursAgo < new Date(cartState.createdAt.seconds * 1000 + cartState.createdAt.nanoseconds / 1000000) ? cartState : undefined // filter on latest 4 hours only
            } else {
                console.error('Error fetching cart state for passed order')
            }
        } catch (e) {
            console.error('Error fetching cart state for passed order', e)
        }
    }
}

export default new passedOrderService()
