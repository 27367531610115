import { Modifier } from '../types'
import { collection, getDocs, query } from 'firebase/firestore/lite'
import { db } from '../firebase'
import OptionService from './OptionService'

const MAX_DESCRIPTION_DISPLAY_LENGTH = 50

// TODO : to enhance performance lazy load the modifier on after screen loading with categories and items.

class ModifierService {
    fetchAllModifiers = async (restoId: string) => {
        const q = query(this.getModifiersReef(restoId))
        const catQuerySnapshot = await getDocs(q)
        return catQuerySnapshot.docs.map((doc) => {
            return { id: doc.id, ...doc.data() } as Modifier
        })
    }

    fetchAllModifiersWithOptions = async (restoId: string) => {
        console.log('📡 fetching all Modifiers and their options')
        const modifiers = await this.fetchAllModifiers(restoId)
        const optionsFetchPromises = modifiers.map(async (modifier) => {
            const options = await OptionService.fetchOrderedOptions(modifier.id, modifier.free, restoId)
            return { ...modifier, options } // adding the fetched optionList to every modifier
        })

        const fetchedModifiersWithOptions = await Promise.all(optionsFetchPromises)

        // fetching all modifiers's options filtered on undisplayed and empty options list
        return fetchedModifiersWithOptions.filter((modifier) => modifier.options.length > 0)
    }

    private getModifiersReef(restoId: string) {
        return collection(db, 'users/' + restoId + '/modifiers')
    }
    truncateDescription = (description: string) => {
        if (!description) {
            return ''
        }
        return description.length > MAX_DESCRIPTION_DISPLAY_LENGTH ? description.slice(0, MAX_DESCRIPTION_DISPLAY_LENGTH) + '...' : description
    }
}

export default new ModifierService()
