import { CelerySvg } from '../../assets/icons/svg-allergies/celery'
import { CrabSvg } from '../../assets/icons/svg-allergies/crab'
import { EggSvg } from '../../assets/icons/svg-allergies/egg'
import { FishSvg } from '../../assets/icons/svg-allergies/fish'
import { GlutenSvg } from '../../assets/icons/svg-allergies/gluten'
import { LupineSvg } from '../../assets/icons/svg-allergies/lupine'
import { MilkSvg } from '../../assets/icons/svg-allergies/milk'
import { MollusksSvg } from '../../assets/icons/svg-allergies/mollusks'
import { MustardSvg } from '../../assets/icons/svg-allergies/mustard'
import { NutSvg } from '../../assets/icons/svg-allergies/nut'
import { PeanutSvg } from '../../assets/icons/svg-allergies/peanut'
import { SesameSvg } from '../../assets/icons/svg-allergies/sesame'
import { SoyaSvg } from '../../assets/icons/svg-allergies/soya'
import { SoyabeanSvg } from '../../assets/icons/svg-allergies/soyabean'
import { SulfateSvg } from '../../assets/icons/svg-allergies/sulfate'

type Allergen = {
    id: string
    label: string
    icon: React.ReactNode
}
export const ALLERGENS: Allergen[] = [
    { id: '1', label: 'Céleri', icon: CelerySvg },
    { id: '2', label: 'Crustacés', icon: CrabSvg },
    { id: '3', label: 'Œufs', icon: EggSvg },
    { id: '4', label: 'Poisson', icon: FishSvg },
    { id: '5', label: 'Gluten', icon: GlutenSvg },
    { id: '6', label: 'Lupin', icon: LupineSvg },
    { id: '7', label: 'Lait', icon: MilkSvg },
    { id: '8', label: 'Mollusques', icon: MollusksSvg },
    { id: '9', label: 'Moutarde', icon: MustardSvg },
    { id: '10', label: 'Fruits à coque', icon: NutSvg },
    { id: '11', label: 'Arachides', icon: PeanutSvg },
    { id: '12', label: 'Sésame', icon: SesameSvg },
    { id: '13', label: 'Soja', icon: SoyaSvg },
    { id: '14', label: 'Haricots de soja', icon: SoyabeanSvg },
    { id: '15', label: 'Sulfites', icon: SulfateSvg }
]
