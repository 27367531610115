import { Text, StyleSheet, View, TouchableOpacity, Dimensions } from 'react-native'
import { useEffect, useRef, useState } from 'react'
import OrderService from '../services/OrderService'
import { cartActions } from '../features/cart/cartSlice'
import { useTheme } from '@react-navigation/native'

import { CartState, CartStatusEnum } from '../types'
import BillsScreen from '../components/stripe/BillsScreen'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'

import { Ionicons } from '@expo/vector-icons'
import { useAppDispatch } from '../app/hooks'

// Calculate the desired size of the LottieView based on the screen dimensions

const OrderScreen = ({ route, navigation: { goBack, navigate } }: any) => {
    const { passedCart, restoId } = route.params
    const { colors } = useTheme()

    const createdOrderId = localStorage.getItem('createdOrderId')
    const passedOrders = localStorage.getItem('passedOrders')

    const [stepPosition, setStepPosition] = useState(0)
    const [cart, setCart] = useState<CartState | null>(null)
    const dispatch = useAppDispatch()
    const inputRef = useRef(null)

    const [localStorageOrderId, setLocalStorageOrderId] = useState<string | null>(null)
    const isFirstRenderRef = useRef(true)
    const width = Dimensions.get('window').width // get screen width
    const height = Dimensions.get('window').height
    const cartStatusMapping = {
        PENDING: 0,
        IN_PROGRESS: 1,
        COMPLETED: 2,
        CANCELED: 3
    }

    const handleGeneratePDF = () => {
        const input: any = inputRef.current

        html2canvas(input, {
            width: width,
            height: height
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF('p', 'pt', 'a4', true)
            pdf.addImage(imgData, 'PNG', 200, 50, 200, 500, undefined, 'FAST')

            pdf.save(`facture-${cart?.invoiceNumber}-${createdOrderId}.pdf`)
        })
    }

    useEffect(() => {
        if (passedCart) {
            setCart(passedCart)
            setStepPosition(cartStatusMapping[passedCart.cartStatus])
        } else {
            const cartStateJSON = localStorage.getItem('cart')
            if (cartStateJSON) {
                const cartState: CartState = JSON.parse(cartStateJSON)
                console.log(cartState)
                setCart(cartState)
            } else {
                console.error('No cart data found in localStorage.')
            }
        }
    }, [])

    useEffect(() => {
        if (!passedCart) {
            if (createdOrderId) {
                setLocalStorageOrderId(createdOrderId)
                //passing the cart from redux to localStorage
            } else {
                const lsOrderId = localStorage.getItem('createdOrderId')
                lsOrderId && setLocalStorageOrderId(lsOrderId)
            }
        }
    }, [])

    useEffect(() => {
        if (isFirstRenderRef.current) {
            isFirstRenderRef.current = false
            return
        }

        const unsubscribe = OrderService.subscribeToCurrentOrder(restoId, localStorageOrderId, (cartNewStatus: CartStatusEnum) => {
            setStepPosition(cartStatusMapping[cartNewStatus])
            if (cartNewStatus === CartStatusEnum.COMPLETED) {
                emptyLocalStorageAndUpdatePassedOrders()
            }
        })

        // Clean up the listener
        return () => {
            unsubscribe()
        }
    }, [localStorageOrderId])

    const emptyLocalStorageAndUpdatePassedOrders = () => {
        let passedOrdersFound: string[] = []
        if (passedOrders) {
            passedOrdersFound = JSON.parse(passedOrders)
        }
        createdOrderId && passedOrdersFound.push(createdOrderId)

        const passedOrdersJSON = JSON.stringify(passedOrdersFound)

        localStorage.setItem('passedOrders', passedOrdersJSON)
        localStorage.removeItem('createdOrderId')
        localStorage.removeItem('cart')
    }
    const newOrder = () => {
        dispatch(cartActions.resetCart({}))
        emptyLocalStorageAndUpdatePassedOrders()
        window.location.reload()
    }

    return (
        <View style={styles.container}>
            <View
                style={{
                    paddingVertical: 15,
                    alignItems: 'center',
                    borderBottomWidth: 0.5,
                    borderColor: 'grey'
                }}>
                <Text style={{ fontSize: 20, lineHeight: 32, fontWeight: '600' }}>Commande passée 🎉 </Text>
            </View>
            {<BillsScreen cart={cart ? cart : passedCart} inputRef={inputRef} restoId={restoId} stepPosition={stepPosition} />}

            <View style={styles.footer}>
                <TouchableOpacity style={[styles.buttonRight, { backgroundColor: '#111' }]} onPress={() => newOrder()}>
                    <Ionicons name="sync-outline" size={25} color={'white'} />
                    <Text style={styles.addToCartButtonText}>&nbsp;&nbsp;Re-commandez&nbsp;&nbsp;</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.buttonLeft, { backgroundColor: '#111' }]}
                    onPress={() => {
                        handleGeneratePDF()
                    }}>
                    <Ionicons name="download" size={25} color={'white'} />
                    <Text style={styles.addToCartButtonText}>Télécharger la note </Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        fontFamily: 'Aeonik-regular',
        backgroundColor: 'white',
        flexDirection: 'column'
    },
    itemNames: {
        fontSize: 17,
        lineHeight: 22,
        paddingLeft: 16,
        paddingRight: 55,
        paddingVertical: 10,
        textAlignVertical: 'auto',
        fontWeight: '600'
    },
    itemsPrice: {
        paddingRight: 16,
        fontSize: 16,
        fontWeight: '600',
        minWidth: 59
    },
    cartItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    commandeTitle: {
        fontSize: 24,
        lineHeight: 32,
        paddingHorizontal: 15,
        paddingTop: 30,
        textAlignVertical: 'left',
        fontWeight: '700'
    },
    commandeTitleStatus: {
        fontSize: 24,
        lineHeight: 32,
        paddingHorizontal: 15,
        textAlignVertical: 'left',
        fontWeight: '700',
        color: '#8F5FF6'
    },
    closeIcon: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 30,
        right: 20,
        zIndex: 1,
        borderRadius: 20,
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
        backgroundColor: 'white'
    },
    addToCartButtonText: {
        color: 'white',
        fontFamily: 'Aeonik-regular',
        fontSize: 14,
        paddingTop: 5,
        paddingLeft: 5,
        fontWeight: '400',
        textAlign: 'center'
    },
    addToCartButton: {
        padding: 15,
        marginHorizontal: 14,
        fontFamily: 'Aeonik-regular',
        marginBottom: 10,
        borderRadius: 5,
        marginTop: 200
    },
    footer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        fontFamily: 'Aeonik-regular',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: -2
        },
        shadowOpacity: 0.1,
        shadowRadius: 4,
        paddingVertical: 10,
        elevation: 5
    },
    iconsContainer: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly', // Align items horizontally
        marginBottom: 20,
        alignContent: 'center'
    },
    count: {
        paddingVertical: 15,
        fontSize: 24,
        fontWeight: 'bold',
        alignItems: 'center',
        textAlign: 'center' // Center the count text horizontally
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 15
    },
    addToCartButton1: {
        borderRadius: 5
    },
    buttonLeft: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        maxWidth: 180,
        marginVertical: 10,
        borderRadius: 5
    },
    buttonRight: {
        flexDirection: 'row',
        justifyContent: 'center',
        padding: 10,
        maxWidth: 180,
        marginVertical: 10,
        borderRadius: 5
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    dotcontainer: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: 15
    },
    dottedLine: {
        width: '30%',
        height: 2.5,
        borderWidth: 2.5,
        borderStyle: 'solid',
        marginVertical: 10,
        borderRadius: 30,
        fontWeight: 'light'
    }
})

export default OrderScreen
