import ObligatoryLabel from '../ObligatoryLabel'
import React, { useEffect, useState } from 'react'
import { FlatList, View, Text, StyleSheet, Pressable } from 'react-native'
import { Divider, RadioButton } from 'react-native-paper'
import { ChoiceModifierProps, Option, SubscriptionType } from '../../types'

const OneChoiceModifier = ({ modifier, modifierIndex, isWarning, subscriptionType, addOptionToItem, removeOptionFromItem, updateAddToCartState }: ChoiceModifierProps) => {
    const [selectedOptionName, setSelectedOptionName] = useState<string | null>(null)
    const [selectedOption, setSelectedOption] = useState<Option | null>(null)

    const handleOptionSelect = (option: Option) => {
        selectedOption && removeOptionFromItem(selectedOption, modifier.id)
        addOptionToItem(option, modifier.id)
        setSelectedOptionName(option.name) // update this to use index instead of name
        setSelectedOption(option)
    }

    useEffect(() => {
        modifier.required && selectedOptionName && updateAddToCartState(modifierIndex, true)
    }, [selectedOptionName])

    return (
        <>
            <View style={styles.obligatoryLabel}>
                <Text style={styles.modifierName}>{modifier.name}</Text>
                {modifier.required ? <ObligatoryLabel isWarning={isWarning} /> : null}
            </View>

            {modifier.options.length > 1 && <Text style={styles.modifierNumChoice}>Choisissez-en 1</Text>}
            <FlatList
                data={modifier.options.filter((option) => option.enabled)}
                keyExtractor={(option) => option.name}
                renderItem={({ item: option, index }) => (
                    <>
                        <Pressable key={index} onPress={() => subscriptionType !== SubscriptionType.BASIC && handleOptionSelect(option)} style={styles.itemContainer}>
                            <View style={styles.optionName}>
                                <Text style={styles.modifierDetailsName}>{option.name}</Text>
                            </View>
                            <View style={styles.itemDetails}>
                                {option.numericPrice > 0 && <Text style={styles.priceOption}>{option.price} €</Text>}
                                {subscriptionType !== SubscriptionType.BASIC && <RadioButton value={option.name} status={selectedOptionName === option.name ? 'checked' : 'unchecked'} onPress={() => handleOptionSelect(option)} color="black" />}
                            </View>
                        </Pressable>

                        {index !== modifier.options.length - 1 && <Divider style={{ marginHorizontal: 15 }} />}
                    </>
                )}
            />
        </>
    )
}

const styles = StyleSheet.create({
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 15
    },
    itemDetails: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 9
    },
    optionName: {
        maxWidth: 190,
        paddingBottom: 15
    },
    priceOption: {
        fontSize: 14,
        paddingHorizontal: 22,
        color: 'black',
        fontWeight: '400'
    },
    obligatoryLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    itemModifier: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700'
    },
    modifierName: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700',
        textAlign: 'left'
    },
    modifierDetails: {
        fontSize: 14,
        paddingLeft: 15,
        fontWeight: '400'
    },
    modifierDetailsName: {
        fontFamily: 'circular-std',
        fontSize: 16,
        lineHeight: 20,
        paddingHorizontal: 15,
        paddingTop: 15,
        fontWeight: '400'
    },
    modifierNumChoice: {
        fontSize: 14,
        lineHeight: 28,
        paddingLeft: 15,
        fontWeight: '400',
        textAlign: 'left'
    }
})
export default OneChoiceModifier
