import ObligatoryLabel from '../ObligatoryLabel'
import React, { useEffect, useState } from 'react'
import { FlatList, View, Text, StyleSheet, Pressable } from 'react-native'
import { Divider, IconButton } from 'react-native-paper'
import { ChoiceModifierProps, Option, SubscriptionType } from '../../types'

const MultiRepititionModifier = ({ modifier, isWarning, subscriptionType, modifierIndex, addOptionToItem, removeOptionFromItem, updateAddToCartState }: ChoiceModifierProps) => {
    const [optionsCount, setOptionsCount] = useState<number[]>(Array(modifier.options.length).fill(0))

    const addOptionHandler = (index: number, option: Option) => {
        addOptionToItem(option, modifier.id)

        setOptionsCount((prevOptionsCount) => {
            const updatedCounts = [...prevOptionsCount]
            updatedCounts[index] += 1
            return updatedCounts
        })
    }

    const removeOptionHandler = (index: number, option: Option) => {
        optionsCount[index] != 0 &&
            setOptionsCount((prevOptionsCount) => {
                const updatedCounts = [...prevOptionsCount]
                updatedCounts[index] -= 1
                return updatedCounts
            })
        removeOptionFromItem(option, modifier.id)
    }

    useEffect(() => {
        if (modifier.required) {
            updateAddToCartState(modifierIndex, isMaxSelected)
        }
    }, [optionsCount])

    const isMaxSelected = optionsCount.reduce((sum, count) => sum + count, 0) >= modifier.maxSelect

    return (
        <>
            <View style={styles.obligatoryLabel}>
                <Text style={styles.modifierName}>{modifier.name}</Text>
                {modifier.required && <ObligatoryLabel isWarning={isWarning} />}
            </View>

            {modifier.options.length > 1 && <Text style={styles.modifierNumChoice}>Jusqu'à {modifier.maxSelect} choix</Text>}

            <FlatList
                data={modifier.options.filter((option) => option.enabled)}
                keyExtractor={(_, index) => index.toString()}
                renderItem={({ item: option, index }) => (
                    <View key={index}>
                        <Pressable
                            onPress={() => subscriptionType !== SubscriptionType.BASIC && !isMaxSelected && addOptionHandler(index, option)}
                            style={[styles.itemContainer, isMaxSelected && optionsCount[index] == 0 ? { opacity: 0.4 } : { opacity: 1 }]}>
                            <View style={styles.priceAndOption}>
                                <Text style={styles.optionName}>{option.name}</Text>
                            </View>

                            <View style={styles.itemDetails}>
                                {option.numericPrice > 0 && <Text style={styles.priceOption}>{option.price} €</Text>}
                                {optionsCount[index] > 0 && (
                                    <>
                                        {subscriptionType !== SubscriptionType.BASIC && <IconButton icon="minus-circle-outline" iconColor={'black'} rippleColor={'black'} onPress={() => removeOptionHandler(index, option)} />}
                                        <Text style={styles.count}>{optionsCount[index]}</Text>
                                    </>
                                )}
                                {subscriptionType !== SubscriptionType.BASIC && <IconButton icon="plus-circle-outline" iconColor={'black'} rippleColor={'black'} onPress={() => addOptionHandler(index, option)} disabled={isMaxSelected} />}
                            </View>
                        </Pressable>
                        {index !== modifier.options.length - 1 && <Divider style={{ marginHorizontal: 15 }} />}
                    </View>
                )}
            />
        </>
    )
}

const styles = StyleSheet.create({
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    priceOption: {
        fontSize: 14,
        paddingLeft: 10,
        color: 'black',
        fontWeight: '400'
    },
    priceAndOption: {
        paddingBottom: 15
    },
    obligatoryLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    modifierNumChoice: {
        fontSize: 14,
        lineHeight: 28,
        paddingLeft: 15,
        fontWeight: '400',
        textAlign: 'left'
    },
    itemDetails: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 15
    },
    modifierName: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700'
    },
    count: {
        fontWeight: '500',
        color: 'black'
    },
    modifierDetails: {
        fontSize: 14,
        paddingLeft: 15,
        fontWeight: '400'
    },
    optionName: {
        fontFamily: 'circular-std',
        fontSize: 16,
        lineHeight: 20,
        paddingHorizontal: 15,
        paddingTop: 15,
        fontWeight: 'normal',
        maxWidth: 190,
        textAlign: 'left'
    }
})
export default MultiRepititionModifier
