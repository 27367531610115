import { firestoreApi } from '../../app/firestoreApi'
import { Category } from '../../types'
import CategoryService from '../../services/CategoryService'

export const categoryApi = firestoreApi.injectEndpoints({
    endpoints: (build) => ({
        fetchCategories: build.query<Category[], string>({
            async queryFn(restoId: string) {
                try {
                    console.log('📭 FETCH CATEGORY >>>>>>>>> fetching all categories with Items from Redux Store', restoId)
                    const catsWItems = await CategoryService.fetchAllCategoriesWithItems(restoId)
                    const catListwItems: Category[] = []
                    for (const cat of catsWItems) {
                        catListwItems.push(cat)
                    }
                    return { data: catListwItems }
                } catch (error: any) {
                    console.error(error.message)
                    return { error: error.message }
                }
            },
            providesTags: ['CatwItems']
        })
    }),
    overrideExisting: false
})

export const { useFetchCategoriesQuery } = categoryApi
