import { Dimensions, View, StyleSheet, TouchableOpacity } from 'react-native'
import { useEffect, useState } from 'react'
import { CartState } from 'types'
import PassedOrderService from '../services/PassedOrderService'
import { useTheme } from '@react-navigation/native'
import { Button, Card, IconButton, Paragraph, Title } from 'react-native-paper'

const PassedOrderScreen = ({ route, navigation: { goBack, navigate } }: any) => {
    const restoId: any = route.params.restoId

    const [carts, setCarts] = useState<CartState[]>([])
    const passedOrders = localStorage.getItem('passedOrders')
    const { colors } = useTheme()

    useEffect(() => {
        const fetchPassedOrder = async () => {
            if (passedOrders) {
                const passedOrdersFound = JSON.parse()
                const cart: CartState | undefined = await PassedOrderService.fetchPendingOrderForLastest4Hours(restoId, passedOrdersFound[0])
                cart && setCarts((prev) => [...prev, cart])
                console.log(passedOrdersFound, restoId)
            }
        }
        fetchPassedOrder()
    }, [])

    return (
        <View style={styles.container}>
            <TouchableOpacity style={styles.closeIcon} onPress={() => goBack()}>
                <IconButton icon="close" iconColor="black" size={25} />
            </TouchableOpacity>
            {/* <Text style={styles.commandeTitle}>Votre commande</Text> */}
            <View style={{ paddingTop: 40 }}></View>

            <Card style={{ padding: 15 }}>
                <Card.Content>
                    <Title>Card title</Title>
                    <Paragraph>Card content</Paragraph>
                </Card.Content>
                <Card.Cover source={require('../assets/images/bggrad1.png')} />
                <Card.Actions>
                    <Button>Cancel</Button>
                    <Button>Ok</Button>
                </Card.Actions>
            </Card>

            {/* {carts.length > 0 && (
                <View style={styles.backgroundContainer}>
                    <LinearGradient colors={['#FFA5EE', '#4f46e5']} start={[0, 1]} end={[1, 33]} style={styles.background} />
                    <View>
                        <View style={styles.cardContainer}>
                            <View>
                                {carts[0].items.map((item: CartItem, index: number) => (
                                    <View key={index}>
                                        <View style={[styles.billsInfo, { backgroundColor: '#F8F8FA' }]}>
                                            <Text style={styles.itemNames}>
                                                {item.quantity ? ` ${item.quantity}x ` : ''} {item.name}
                                            </Text>
                                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <Text style={styles.itemsPrice}>{(item.itemTotalPrice * item.quantity).toFixed(2)} €</Text>
                                            </View>
                                        </View>

                                        {item.modifiers?.map((modifier) => (
                                            <View key={modifier.id}>
                                                {modifier.options.map((option) => (
                                                    <View style={styles.billsInfo}>
                                                        <View style={styles.optionLine}>
                                                            <View style={{ flexDirection: 'row' }}>
                                                                <Text style={{ fontSize: 11, paddingLeft: 15, fontWeight: '300', color: colors.primary }} key={option.id}>
                                                                    {option.quantity ? `${option.quantity}x` : ''}
                                                                </Text>
                                                                <Text style={{ fontSize: 11, paddingLeft: 15, fontWeight: '300' }} key={option.id}>
                                                                    {option.name}
                                                                </Text>
                                                            </View>
                                                        </View>
                                                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                            <Text style={styles.itemsPrice}> {option.price ? `  ${option.price.toFixed(2)} €` : ''}</Text>
                                                        </View>
                                                    </View>
                                                ))}
                                            </View>
                                        ))}
                                    </View>
                                ))}
                            </View>
                            <Divider style={{ marginHorizontal: 30, marginTop: 20 }} />
                            <View style={{ marginTop: 20 }}>
                                <View style={[styles.billsInfo, { backgroundColor: '#F8F8FA' }]}>
                                    <Text style={styles.itemNames}>TVA 10% </Text>
                                    <Text style={styles.itemsPrice}>{getTvaFromTTCPrice(carts[0].cartTotalPrice - carts[0].tipTotalPrice)}€</Text>
                                </View>
                                <View style={styles.billsInfo}>
                                    <Text style={styles.itemNames}>Total HT </Text>
                                    <Text style={styles.itemsPrice}>{getTotalHTFromTTCPrice(carts[0].cartTotalPrice - carts[0].tipTotalPrice)}€</Text>
                                </View>
                                {carts[0].tipTotalPrice == 0.0 ? null : (
                                    <View style={[styles.billsInfo, { backgroundColor: '#F8F8FA' }]}>
                                        <Text style={styles.itemNames}>Pourboire</Text>
                                        <Text style={styles.itemsPrice}>{carts[0].tipTotalPrice.toFixed(2)}€</Text>
                                    </View>
                                )}
                            </View>
                            <View style={styles.dotcontainer}>
                                <View style={styles.dottedLine} />
                            </View>
                            <View style={styles.informationsContainer}>
                                <View style={styles.column1}>
                                    <Text style={[styles.label, styles.total]}>TOTAL :</Text>
                                </View>
                                <View>
                                    <Text style={styles.totalPrice}>{carts[0].cartTotalPrice.toFixed(2)}€</Text>
                                </View>
                            </View>

                            {/* <View>
                    {user && (
                        <>
                            <Text style={[styles.title, { fontWeight: 'normal', marginBottom: 20, marginTop: 0 }]}>Visitez nos dernieres publications en cliquant ici :</Text>
                            <View style={[styles.billsInfo, { marginRight: 50, marginLeft: 50, marginBottom: 25 }]}>
                                {user.facebook && <Entypo name="facebook" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.facebook}`, '_blank')} />}
                                {user.instagram && <Entypo name="instagram-with-circle" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.instagram}`, '_blank')} />}
                                {user.twitter && <Entypo name="twitter" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.twitter}`, '_blank')} />}
                                {user.email && <MaterialCommunityIcons name="gmail" size={30} color={colors.secondaryText} onPress={() => window.open(`https://${user.email}`, '_blank')} />}
                            </View>
                        </>
                    )}
                </View> 
                            <View style={styles.cardContent}></View>
                            <View style={{ flexDirection: 'column' }}>
                                <Text style={styles.itemNames}>Merci pour votre visite</Text>
                            </View>
                        </View>
                    </View>
                </View>
            )} */}
        </View>
    )
}

const windowWidth = Dimensions.get('window').width
const windowHeight = Dimensions.get('window').height

const styles = StyleSheet.create({
    container: {
        flex: 1
    },
    closeIcon: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 30,
        right: 20,
        zIndex: 1,
        borderRadius: 20,
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
        backgroundColor: 'white'
    },
    commandeTitle: {
        fontSize: 32,
        lineHeight: 32,
        paddingHorizontal: 15,
        paddingTop: 30,
        textAlignVertical: 'auto',
        fontWeight: '700'
    },
    backgroundContainer: {
        overflowX: 'hidden', // Prevent horizontal scrolling
        position: 'absolute',
        left: 0,
        backgroundColor: '#EDF0F1',
        right: 0,
        top: 0,
        bottom: 0
    },
    background: {
        position: 'absolute',
        left: -(windowWidth + windowHeight) / 1.5,
        right: -(windowWidth + windowHeight) / 2,
        top: -(windowWidth + windowHeight) / 2,
        bottom: -(windowWidth + windowHeight) / 2,
        width: windowWidth + windowHeight,
        height: windowWidth + windowHeight,
        transform: [{ rotate: '60deg' }]
    },
    cardContainer: {
        position: 'relative',
        margin: 30,
        minHeight: 600,
        backgroundColor: 'white',
        borderRadius: 8,
        overflow: 'hidden',
        elevation: 2,
        shadowColor: 'rgba(0, 0, 0, 0.1)',
        shadowOffset: { width: 10, height: 2 },
        shadowOpacity: 1,
        shadowRadius: 4
    },
    cardBackground: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    },
    cardContent: {
        padding: 0
    },
    trianglesContainer: {
        padding: 0,
        flexDirection: 'row',
        justifyContent: 'center'
    },
    billsInfo: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    triangle: {
        height: 10,
        width: 0,
        borderStyle: 'solid',
        borderLeftWidth: 15,
        borderRightWidth: 15,
        borderBottomWidth: 10,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomColor: 'hsl(240,100%,10%)',
        transform: [{ rotate: '360deg' }],
        marginHorizontal: 2
    },
    triangleGradient: {
        flex: 1,
        marginHorizontal: -40,
        justifyContent: 'flex-end'
    },

    dotcontainer: {
        width: '100%',
        alignItems: 'center'
    },
    dottedLine: {
        width: '100%',
        height: 1,
        borderWidth: 0.5,
        borderColor: '#EEEEEE',
        borderStyle: 'solid',
        marginVertical: 10,
        fontWeight: 'light'
    },

    informationsContainer: {
        flexDirection: 'row',
        paddingHorizontal: 13,
        marginBottom: 10,
        marginTop: 15
    },
    column1: {
        flex: 1,
        justifyContent: 'flex-start'
    },
    column2: {
        justifyContent: 'flex-end'
    },
    label: {
        fontSize: 22,
        fontWeight: 'bold',
        marginBottom: 5
    },
    value: {
        fontSize: 16,
        marginBottom: 5
    },
    total: {
        justifyContent: 'flex-end',
        fontSize: 18,
        paddingLeft: 20,
        marginBottom: 15,
        fontWeight: '400'
    },
    itemNames: {
        fontSize: 12,
        lineHeight: 22,
        color: '#333333',
        paddingVertical: 10,
        paddingHorizontal: 30,
        textAlignVertical: 'auto',
        fontWeight: '400'
    },
    optionLine: {
        fontSize: 10,
        color: '#333333',
        paddingHorizontal: 30,
        fontWeight: '400'
    },
    itemsPrice: {
        paddingRight: 30,
        fontSize: 12,
        fontWeight: '600'
    },
    totalPrice: {
        justifyContent: 'flex-end',
        paddingLeft: 20,
        paddingBottom: 20,
        fontSize: 20,
        fontWeight: '600'
    }
})

export default PassedOrderScreen
