import { Option } from '../types'
import { collection, getDocs, query } from 'firebase/firestore/lite'
import { db } from '../firebase'

const MAX_DESCRIPTION_DISPLAY_LENGTH = 50

class OptionService {
    fetchOptionsOfModifier = async (modifierId: string, modifierIsFree: boolean, restoId: string) => {
        try {
            const querySnapshot = await getDocs(query(this.getOptionsRef(modifierId, restoId)))

            return querySnapshot.docs.map((optionDoc) => {
                const option = optionDoc.data()
                return {
                    ...option,
                    price: this.getOptionNumericPrice(option.price).toFixed(2), // todo : check this numeric handling and float management
                    numericPrice: this.getOptionNumericPrice(option.price), // Set price to '0.00' if modifier is free
                    id: optionDoc.id
                } as Option
            })
        } catch (e) {
            console.error('Error fetching options', e)
            return []
        }
    }

    getOptionNumericPrice = (stringPrice: string) => {
        return stringPrice ? parseFloat(stringPrice) : parseFloat('0.00')
    }

    fetchOrderedOptions = async (modifierId: string | undefined, modifierIsFree: boolean, restoId: string) => {
        return modifierId ? await this.fetchOptionsOfModifier(modifierId, modifierIsFree, restoId) : []
    }

    private getOptionsRef(clickedModifierId: string, restoId: string) {
        return collection(db, this.getOptionPath(clickedModifierId, restoId))
    }

    private getOptionPath(clickedModifierId: string, restoId: string): string {
        if (!restoId) {
            throw new Error('Not logged in!')
        }
        if (!clickedModifierId) {
            throw new Error('No clickedModifierId selected!')
        }
        return 'users/' + restoId + '/modifiers/' + clickedModifierId + '/options'
    }

    truncateDescription = (description: string) => {
        if (!description) {
            return ''
        }
        return description.length > MAX_DESCRIPTION_DISPLAY_LENGTH ? description.slice(0, MAX_DESCRIPTION_DISPLAY_LENGTH) + '...' : description
    }
}

export default new OptionService()
