import { Text, View, Image, useWindowDimensions, TouchableOpacity, StyleSheet, ScrollView, NativeSyntheticEvent, NativeScrollEvent, Animated, Easing } from 'react-native'
import { useEffect, useRef, useState } from 'react'
import { Item, Modifier, Option, SubscriptionType } from '../types'
import { Ionicons } from '@expo/vector-icons'
import { Divider, IconButton } from 'react-native-paper'
import { useAppDispatch } from '../app/hooks'
import { cartActions } from '../features/cart/cartSlice'
import MultiChoiceModifier from '../components/modifiers/MultiChoiceModifier'
import OneChoiceModifier from '../components/modifiers/OneChoiceModifier'
import MultiRepititionModifier from '../components/modifiers/MultiRepititionModifier'
import ImgService from '../services/ImgService'
import CustomItemHeader from '../components/modifiers/CustomItemHeader'
import { useSelector } from 'react-redux'
import { RootState } from '../app/store'
import { ALLERGENS } from '../components/constants/Allergen'
import Labels from '../components/Labels'
import { isMobile } from 'react-device-detect'

type ItemScreenProps = {
    item: Item
    uri: string
    modifiers: Modifier[]
    closedRestaurant: boolean
    subscriptionType: SubscriptionType
}

const ItemScreen = ({ route, navigation: { goBack } }: any) => {
    const cart = useSelector((state: RootState) => state.cart)

    const { item, uri, modifiers, closedRestaurant, subscriptionType }: ItemScreenProps = route.params
    const { height, width } = useWindowDimensions()

    const imageHeight = Math.round(height / 2)
    const [itemQuantity, setItemQuantity] = useState(1)
    const [itemTotalPrice, setItemTotalPrice] = useState('0.00') // State to store the item total price

    const [showHeader, setShowHeader] = useState(false)

    // isAddToCartValid represent a list of boolean each one is set to true if the modifier is not required
    // if at least one modifier is required => the list will contains a false element which means that the cart is not valid
    const [isAddToCartValid, setIsAddToCartValid] = useState<boolean[]>(modifiers.map((modifier) => !modifier.required))

    const [isObligatorySelected, setIsObligatorySelected] = useState(true)

    useEffect(() => {
        const itemFromStore = cart.items[cart.items.length - 1]
        const totalItemPrice = itemFromStore ? (itemFromStore.itemTotalPrice * itemFromStore.quantity).toFixed(2) : '0.00'
        setItemTotalPrice(totalItemPrice)
        setItemQuantity(itemFromStore.quantity)
    }, [cart]) // Re-run the effect whenever the cart state changes

    const dispatch = useAppDispatch()

    const incrementItemNbr = () => {
        dispatch(cartActions.incrementLastItemInCart(item))
    }
    const DecrementItemNbr = () => {
        dispatch(cartActions.removeLastItemFromCart(item))
    }

    const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
        const scrollPosition = event.nativeEvent.contentOffset.y
        const headerThreshold = imageHeight - 100

        setShowHeader(scrollPosition > headerThreshold)
    }

    const updateAddToCartState = (modifierIndex: number, modifierIsValid: boolean) => {
        setIsAddToCartValid((prevAddToCartState: boolean[]) => {
            const updatedArray = [...prevAddToCartState]
            updatedArray[modifierIndex] = modifierIsValid
            return updatedArray
        })
    }

    const addOptionToItem = (option: Option, modifierId: string) => {
        dispatch(cartActions.addOptionToItemAtLastIndex({ itemId: item.id, modifierId, option }))
    }
    const removeOptionFromItem = (option: Option, modifierId: string) => {
        dispatch(cartActions.removeOptionFromItemAtLastIndex({ itemId: item.id, modifierId, option }))
    }

    const closeItemScreen = () => {
        goBack()
        dispatch(cartActions.removeLastItemAllOccurrences(item))
    }

    const addItem = () => {
        if (isAddToCartValid.some((value) => value === false)) {
            setIsObligatorySelected(false)
        } else {
            console.log('cart with no payment', cart)
            const cartStateJSON = JSON.stringify(cart)
            localStorage.setItem('cart', cartStateJSON)
            goBack()
        }
    }

    const opacityValue = useRef(new Animated.Value(0)).current

    useEffect(() => {
        Animated.timing(opacityValue, {
            toValue: showHeader ? 1 : 0,
            duration: 600,
            easing: Easing.linear,
            useNativeDriver: true
        }).start()
    }, [showHeader])

    const allergenIds = item.allergens // Liste d'ID des allergènes de l'item

    const allergenLabels = allergenIds ? ALLERGENS.filter((allergen) => allergenIds.includes(allergen.id)).map((allergen) => allergen.label) : []
    return (
        <View style={styles.container}>
            {showHeader && (
                <Animated.View style={{ opacity: opacityValue }}>
                    <CustomItemHeader itemTitle={item.name} closeItemScreen={closeItemScreen} />
                </Animated.View>
            )}
            <ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 10 }} onScroll={handleScroll} scrollEventThrottle={16}>
                <TouchableOpacity onPress={closeItemScreen} style={styles.closeIcon}>
                    <IconButton icon="close" iconColor="black" size={25} />
                </TouchableOpacity>
                <Image style={isMobile ? { width: width, height: imageHeight, marginBottom: 15 } : { width: 364, height: Math.round(805 / 2 - 100), marginBottom: 15 }} source={{ uri: ImgService.getItemHQImgUrlFromThumbnails(uri) }} />
                <Text style={styles.itemTitle}>{item.name}</Text>

                <Text style={styles.itemTitlePrice}>{item.price} €</Text>
                <Text style={styles.itemDescription}>{item.description}</Text>
                <View style={{ flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'left' }}>
                    {allergenLabels.map((label, index) => (
                        <View style={styles.allergens} key={index}>
                            <Labels title={label} svgIcon={ALLERGENS[parseInt(allergenIds[index]) - 1].icon} />
                        </View>
                    ))}
                </View>
                <View style={{ paddingTop: 10 }}>
                    <Divider style={{ height: 2, backgroundColor: '#F3F4F6' }} />
                </View>

                {modifiers.map((modifier: Modifier, index: number) => {
                    if (modifier.maxSelect === 1 && modifier.options.length > 1) {
                        return (
                            <View key={modifier.id}>
                                <OneChoiceModifier
                                    key={modifier.id}
                                    modifier={modifier}
                                    isWarning={!isObligatorySelected}
                                    subscriptionType={subscriptionType}
                                    modifierIndex={index}
                                    addOptionToItem={addOptionToItem}
                                    removeOptionFromItem={removeOptionFromItem}
                                    updateAddToCartState={updateAddToCartState}
                                />
                                <Divider style={{ height: 2, backgroundColor: '#F3F4F6' }} />
                            </View>
                        )
                    } else if ((modifier.maxSelect > 1 && !modifier.multipleSelection) || modifier.options.length === 1) {
                        return (
                            <View key={modifier.id}>
                                <MultiChoiceModifier
                                    key={modifier.id}
                                    modifier={modifier}
                                    subscriptionType={subscriptionType}
                                    modifierIndex={index}
                                    isWarning={!isObligatorySelected}
                                    addOptionToItem={addOptionToItem}
                                    removeOptionFromItem={removeOptionFromItem}
                                    updateAddToCartState={updateAddToCartState}
                                />
                                <Divider style={{ height: 2, backgroundColor: '#F3F4F6' }} />
                            </View>
                        )
                    } else if (modifier.maxSelect > 1 && modifier.multipleSelection) {
                        return (
                            <View key={modifier.id}>
                                <MultiRepititionModifier
                                    key={modifier.id}
                                    modifier={modifier}
                                    isWarning={!isObligatorySelected}
                                    modifierIndex={index}
                                    subscriptionType={subscriptionType}
                                    addOptionToItem={addOptionToItem}
                                    removeOptionFromItem={removeOptionFromItem}
                                    updateAddToCartState={updateAddToCartState}
                                />
                                <Divider style={{ height: 2, backgroundColor: '#F3F4F6' }} />
                            </View>
                        )
                    }
                })}
            </ScrollView>

            {/* Card UI code */}
            {subscriptionType !== SubscriptionType.BASIC && (
                <View style={styles.cardContainer}>
                    <View style={styles.buttonContainer}>
                        <View style={styles.addToCartButton1}>
                            <TouchableOpacity onPress={closedRestaurant ? null : DecrementItemNbr} disabled={itemQuantity === 1}>
                                <Ionicons name="remove-circle-outline" size={25} color={itemQuantity === 1 ? '#D5D6D6' : '#8F5FF6'} />
                            </TouchableOpacity>

                            {closedRestaurant ? <Text style={styles.count}>0</Text> : <Text style={styles.count}>{itemQuantity}</Text>}

                            <TouchableOpacity onPress={closedRestaurant ? null : incrementItemNbr} disabled={itemQuantity === 8}>
                                <Ionicons name="add-circle-outline" size={25} color={itemQuantity === 8 || closedRestaurant ? '#D5D6D6' : '#8F5FF6'} />
                            </TouchableOpacity>
                        </View>
                        {closedRestaurant ? (
                            <TouchableOpacity style={[styles.addToCartButton2, { backgroundColor: '#D5D6D6' }]}>
                                <Text style={styles.addToCartButtonText}>Ajouter pour {itemTotalPrice} €</Text>
                            </TouchableOpacity>
                        ) : (
                            <TouchableOpacity style={[styles.addToCartButton2, isAddToCartValid.some((value) => value === false) ? { backgroundColor: '#D5D6D6' } : { backgroundColor: '#111' }]} onPress={addItem}>
                                <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center', alignItems: 'center' }}>
                                    <View>
                                        <Text style={styles.addToCartButtonText}>Ajouter pour </Text>
                                    </View>
                                    <View>
                                        <Text style={styles.addToCartButtonText}>{itemTotalPrice} €</Text>
                                    </View>
                                </View>
                            </TouchableOpacity>
                        )}
                    </View>
                </View>
            )}
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white'
    },
    closeIcon: {
        position: 'absolute',
        width: 40,
        height: 40,
        top: 20,
        right: 20,
        zIndex: 1,
        borderRadius: 20,
        justifyContent: 'center', // center horizontally
        alignItems: 'center', // center vertically
        backgroundColor: 'white',
        boxShadow: '0 1px 4px #00000014'
    },
    cardContainer: {
        paddingVertical: 3,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: -1
        },
        shadowOpacity: 0.08,
        shadowRadius: 4,
        elevation: 2
    },
    iconsContainer: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly', // Align items horizontally
        marginBottom: 20,
        alignContent: 'center'
    },
    count: {
        marginHorizontal: 10,
        fontSize: 24,
        fontWeight: 'bold',
        alignItems: 'center',
        textAlign: 'center' // Center the count text horizontally
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 10
    },
    addToCartButton1: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: 3,
        padding: 5,
        borderRadius: 5
    },
    addToCartButton2: {
        flex: 2,
        padding: 15,
        marginHorizontal: 15,
        marginBottom: 5,
        borderRadius: 5
    },
    addToCartButtonText: {
        color: 'white',
        fontSize: 17,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    itemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 10,
        paddingVertical: 5
    },
    itemName: {
        padding: 15,
        flex: 1,
        fontFamily: 'Aeonik-regular',
        fontSize: 13,
        color: '#111827'
    },
    itemDetails: {
        flexDirection: 'row',
        alignItems: 'center'
    },

    itemTitle: {
        lineHeight: 32,
        paddingLeft: 15,
        fontFamily: 'Aeonik-bold',
        color: '#1a1a1a',
        fontWeight: '500',
        fontSize: 19
    },
    allergens: {
        fontSize: 12,
        lineHeight: 32,
        paddingLeft: 15,
        paddingTop: 10,
        textAlignVertical: 'auto',
        fontWeight: '200'
    },
    itemDescription: {
        fontFamily: 'Aeonik-regular',
        fontSize: 16,
        textAlign: 'left',
        lineHeight: 20,
        color: '#6b7280',
        textAlignVertical: 'auto',
        fontWeight: '400',
        padding: 15
    },
    modifierName: {
        fontSize: 20,
        lineHeight: 28,
        paddingTop: 15,
        paddingLeft: 15,
        fontWeight: '700',
        textAlign: 'left'
    },
    itemTitlePrice: {
        fontFamily: 'Aeonik-bold',
        marginLeft: 16,
        paddingTop: 10,
        paddingBottom: 30,
        marginRight: 5,
        color: '#111',
        fontSize: 20
    },
    modifierNumChoice: {
        fontSize: 14,
        lineHeight: 28,
        color: '#545454',
        paddingLeft: 15,
        fontWeight: '400'
    }
})

export default ItemScreen
